<template>
  <form id="futuro-customer-health" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-health.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Plano de saúde</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-6 col-12">
          <label for="haveLifeInsurance" class="form-label">
            Você possui plano de saúde?
          </label>
          <select
            id="haveLifeInsurance"
            v-model="hasPlan"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
        <template v-if="isInterview">
          <div v-if="hasPlan === 'yes'" class="col-md-6 col-12">
            <label for="haveInsurance" class="form-label">
              É de benefício da sua empresa?
            </label>
            <select
              id="haveInsurance"
              v-model="isCompanyHealthPlan"
              class="form-select"
              :disabled="isDetail"
              :class="{ 'select-disabled': isDetail }"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </template>
      </div>

      <template v-if="isInterview">
        <template v-if="hasPlan === 'yes' && isCompanyHealthPlan === 'no'">
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="company" class="form-label"> Nome do plano </label>
              <input
                id="company"
                v-model="company"
                type="text"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-6 col-12">
              <label for="product" class="form-label"> Categoria </label>
              <input
                id="product"
                v-model="product"
                type="text"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="initialDate" class="form-label"> Início </label>
              <input
                id="initialDate"
                v-model="initialDate"
                v-mask="'##/##/####'"
                :disabled="isDetail"
                type="tel"
                class="form-control"
                :class="{ 'is-invalid': v$.initialDate.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.initialDate.minLength.$invalid">
                  A data informada é inválida
                </span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label for="currentValue" class="form-label"> Valor </label>
              <input
                id="currentValue"
                v-model.lazy="currentValue"
                v-money="moneyFormat"
                type="text"
                class="form-control"
                :disabled="isDetail"
                :class="{ 'is-invalid': v$.currentValue.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.currentValue.minLength.$invalid">
                  O valor é inválido
                </span>
              </div>
            </div>
          </div>
        </template>
        <div class="row mb-3 gy-3 align-items-end">
          <div class="col-md-6 col-12">
            <label for="reducedCost" class="form-label">
              Há interesse de contratar um plano particular complementar próprio
              ou para terceiros?
            </label>
            <select
              id="reducedCost"
              v-model="reducedCost"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <template v-if="reducedCost === 'yes'">
          <div class="row mb-3 gy-3">
            <div class="col-12">
              <label for="fundamental" class="form-label">
                O que considera fundamental na escolha do plano de saúde?
              </label>
              <input
                id="fundamental"
                v-model="fundamental"
                type="text"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="lifes" class="form-label">
                Quantas vidas gostaria de incluir no plano?
              </label>
              <input
                id="lifes"
                v-model="lifes"
                type="number"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-6 col-12">
              <label for="unionOrClass" class="form-label">
                Possui diploma de ensino superior e/ou carteirinha de conselho da profissão?
              </label>
              <select
                id="unionOrClass"
                v-model="unionOrClass"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                :disabled="isDetail"
                @blur="preSave()"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </select>
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="preexistence" class="form-label">
                Você teve alguma doença pré existente?
              </label>
              <textarea
                id="preexistence"
                v-model="preexistence"
                type="text"
                class="form-control"
                :disabled="isDetail"
                rows="4"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-6 col-12">
              <label for="preference" class="form-label">
                Tem preferência por algum hospital / clínica?
              </label>
              <textarea
                id="preference"
                v-model="preference"
                type="text"
                class="form-control"
                :disabled="isDetail"
                rows="4"
                @blur="preSave()"
              />
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="hasCnpj" class="form-label">
                Possui MEI ou CNPJ?
              </label>
              <select
                id="hasCnpj"
                v-model="hasCnpj"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                :disabled="isDetail"
                @blur="preSave()"
              >
                <option value="yes">Sim</option>
                <option value="no">Não</option>
              </select>
            </div>
            <div class="col-md-6 col-12">
              <label for="value" class="form-label">
                Até qual valor estaria disposto a pagar mensalmente?
              </label>
              <input
                id="value"
                v-model.lazy="value"
                v-money="moneyFormat"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{ 'is-invalid': v$.value.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.value.minLength.$invalid">
                  O valor é inválido
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>

      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getBRLFormat } from "../../../helpers/formatting";
import {
  floatToMoney,
  booleanToString,
  integerToString,
  dateToString,
} from "../../../helpers/converters";
import { getHealthPlanPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      hasPlan: "",
      isCompanyHealthPlan: "",
      company: "",
      product: "",
      initialDate: "",
      currentValue: "",
      reducedCost: "",
      fundamental: "",
      lifes: "",
      unionOrClass: "",
      preexistence: "",
      preference: "",
      hasCnpj: "",
      value: "",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      hasPlan: {},
      isCompanyHealthPlan: {},
      company: {},
      product: {},
      initialDate: {
        minLength: minLength(10),
      },
      currentValue: {
        minLength: minLength(7),
      },
      reducedCost: {},
      fundamental: {},
      lifes: {},
      unionOrClass: {},
      preexistence: {},
      preference: {},
      hasCnpj: {},
      value: {
        minLength: minLength(7),
      },
    };
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getHealthPlan: types.GET_HEALTH_PLAN,
      autoSaveHealthPlan: types.AUTO_SAVE_HEALTH_PLAN,
    }),
    ...mapMutations({
      mutateHealthPlan: types.MUTATE_HEALTH_PLAN,
    }),
    async fetchHealthPlan() {
      this.loading = true;
      try {
        const response = await this.getHealthPlan(
          this.$route.params.customerId
        );
        const {
          has_plan,
          is_company_health_plan,
          company,
          product,
          initial_date,
          current_value,
          reduced_cost,
          fundamental,
          lifes,
          preexistence,
          preference,
          has_cnpj,
          value,
          union_or_class,
        } = response.data;
        this.hasPlan = booleanToString(has_plan);
        this.isCompanyHealthPlan = booleanToString(is_company_health_plan);
        this.company = company;
        this.product = product;
        this.initialDate = dateToString(initial_date);
        this.currentValue = floatToMoney(current_value);
        this.reducedCost = booleanToString(reduced_cost);
        this.fundamental = fundamental;
        this.lifes = integerToString(lifes);
        this.unionOrClass = booleanToString(union_or_class);
        this.preexistence = preexistence;
        this.preference = preference;
        this.hasCnpj = booleanToString(has_cnpj);
        this.value = floatToMoney(value);
        const payload = getHealthPlanPayload(this);
        this.mutateHealthPlan(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações de plano de saúde do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchHealthPlan()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    preSave() {
      const payload = getHealthPlanPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateHealthPlan(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveHealthPlan(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados de plano de saúde do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
