<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="modal__container"
    content-class="modal__content"
    :resize="true"
    :max-width="400"
  >
    <button
      class="close"
      @click="close"
    >
      <img
        src="~@/assets/img/close.svg"
        height="30"
      >
    </button>
    <img
      src="~@/assets/img/question.png"
    >
    <span class="title mt-3">
      Confirmar operação
    </span>
    <div class="content mt-3 px-3">
      <p
        v-for="(message, index) in params.messages"
        :key="`message${index}`"
      >
        {{ message }}
      </p>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-around mt-3">
      <button
        class="btn btn-red"
        @click="$emit('cancel', close)"
      >
        Não
      </button>
      <button
        class="btn btn-blue"
        @click="confirm(params, close)"
      >
        Sim
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
import { mapActions } from 'vuex'
import * as types from '../store/types'
export default {
  name: 'MessageModal',
  inheritAttrs: false,
  emits: ['cancel'],
  methods: {
    ...mapActions({
      deleteSectionComment: types.DELETE_SECTION_COMMENT
    }),
    confirm(params, close) {
      if (
        params.id &&
        params.token &&
        params.interviewSection
      ) {
        this.deleteAction(
          params.id,
          params.token,
          params.interviewSection
        )
        close()
      }
    },
    deleteAction(id, token, interview_session) {
      this.deleteSectionComment({ id, token, interview_session})
        .catch(error => {
          this.$vfm.show('message', {
            type: 'error',
            messages: [
              'Não foi possível remover o comentário.',
              'Entre em contato com o time de TI antes de prosseguir com a operação.'
            ]
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  border-radius: 20px;
  background-color: var(--grey);
  color: var(--black-txt);
}
.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--black-txt);
}
.content {
  flex-grow: 1;
  overflow-y: auto;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: 300;
  text-align: center;
}
.close {
  position: absolute;
  background: transparent;
  top: 0.7rem;
  right: 0.7rem;
}
.btn {
  width: 130px;
}
</style>
