import _ from 'lodash'

export const setCredentials = (name, value) => {
  localStorage.setItem(`${process.env.VUE_APP_ENV_NAME}_${name}`, JSON.stringify(value))
}

export const getCredentials = (name) => {
  const cookie = localStorage.getItem(`${process.env.VUE_APP_ENV_NAME}_${name}`)
  return cookie !== 'undefined' ? JSON.parse(cookie) : undefined
}

export const deleteCredentials = (name) => {
  localStorage.removeItem(`${process.env.VUE_APP_ENV_NAME}_${name}`)
}
