<template>
  <form id="futuro-customer-additional" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/plus-circle.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Informações adicionais</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3 align-items-end">
        <div class="col-md-4 col-12">
          <label for="incomeTaxCategory" class="form-label">
            Imposto de renda
          </label>
          <select
            id="incomeTaxCategory"
            v-model="incomeTaxCategory"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option
              v-for="key in Object.keys(incomeTaxCategoryTypes)"
              :key="key"
              :value="key"
            >
              {{ incomeTaxCategoryTypes[key] }}
            </option>
          </select>
        </div>
        <div
          v-if="incomeTaxCategory && incomeTaxCategory !== 'isento'"
          class="col-md-4 col-12"
        >
          <label for="incomeTaxPaid" class="form-label">
            Você pagou ou teve o imposto restituído?
          </label>
          <select
            id="incomeTaxPaid"
            v-model="incomeTaxPaid"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Pago</option>
            <option value="no">Restituído</option>
          </select>
        </div>
        <div
          v-if="
            incomeTaxCategory &&
            incomeTaxCategory !== 'isento' &&
            incomeTaxPaid === 'yes'
          "
          class="col-md-4 col-12"
        >
          <label for="incomeTaxPaidAmount" class="form-label">
            Qual valor você pagou de imposto de renda no último ano?
          </label>
          <input
            id="incomeTaxPaidAmount"
            v-model.lazy="incomeTaxPaidAmount"
            v-money="moneyFormat"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.incomeTaxPaidAmount.minLength.$invalid }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.incomeTaxPaidAmount.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
        <div
          v-if="
            incomeTaxCategory &&
            incomeTaxCategory !== 'isento' &&
            incomeTaxPaid === 'no'
          "
          class="col-md-4 col-12"
        >
          <label for="incomeTaxRefundedAmount" class="form-label">
            Qual valor foi restituído de imposto de renda no último ano?
          </label>
          <input
            id="incomeTaxRefundedAmount"
            v-model.lazy="incomeTaxRefundedAmount"
            v-money="moneyFormat"
            type="text"
            :disabled="isDetail"
            class="form-control"
            :class="{
              'is-invalid': v$.incomeTaxRefundedAmount.minLength.$invalid,
            }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.incomeTaxRefundedAmount.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3 px-3">
        <div class="col-12 totalizer debts-value-container">
          <span>Custos bancários:</span>
          <span>{{ $filters.toCurrency(costsTotal) }}</span>
        </div>
      </div>
      <div class="row align-items-center mb-3 mt-2 px-3 g-3">
        <template v-if="!isDetail">
          <button class="btn btn-orange col-auto mx-1" @click.prevent="addCard">
            Add cartão
          </button>
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="addBankAccount"
          >
            Add conta-corrente
          </button>
        </template>
      </div>

      <!-- Cartões -->
      <div v-if="cards.length > 0" class="card-title mt-3 mb-3">
        <h2>Cartões</h2>
      </div>
      <div
        v-for="(card, index) in cards"
        :key="`card-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-12">
            <label :for="`cardName-${index}`" class="form-label"> Nome </label>
            <input
              :id="`cardName-${index}`"
              v-model="card.name"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`cardMonthlyFee-${index}`" class="form-label">
              Anuidade mensal
            </label>
            <input
              :id="`cardMonthlyFee-${index}`"
              v-model.lazy="card.monthlyFee"
              v-money="moneyFormat"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].monthlyFee.length,
              }"
              @blur="
                calculateAnnualFee(cards, index);
                preSave();
              "
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].monthlyFee.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label :for="`cardAnnualFee-${index}`" class="form-label">
              Anuidade total
            </label>
            <input
              :id="`cardMonthlyFee-${index}`"
              v-model.lazy="card.annualFee"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].annualFee.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].annualFee.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`cardLimit-${index}`" class="form-label">
              Limite
            </label>
            <input
              :id="`cardLimit-${index}`"
              v-model.lazy="card.limit"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].limit.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].limit.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label :for="`cardAverageExpense-${index}`" class="form-label">
              Gasto médio
            </label>
            <input
              :id="`cardAverageExpense-${index}`"
              v-model.lazy="card.averageExpense"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].averageExpense.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].averageExpense.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`cardPoints-${index}`" class="form-label">
              Pontos
            </label>
            <input
              :id="`cardPoints-${index}`"
              v-model="card.points"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-6 col-12">
            <label :for="`cardCashback-${index}`" class="form-label">
              Cashback
            </label>
            <input
              :id="`cardCashback-${index}`"
              v-model.lazy="card.cashback"
              v-money="percentageFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].cashback.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.cards.$dirty &&
                  v$.cards.$each.$response.$errors[index].cashback.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button class="btn btn-red" @click.prevent="remove(cards, index)">
                Excluir cartão
              </button>
            </template>
          </div>
        </div>
      </div>
      <!-- Contas corrente -->
      <div v-if="bankAccounts.length > 0" class="card-title mt-3 mb-3">
        <h2>Contas-correntes</h2>
      </div>
      <div
        v-for="(bankAccount, index) in bankAccounts"
        :key="`bankAccount-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`bankAccountName-${index}`" class="form-label">
              Nome
            </label>
            <input
              :id="`bankAccountName-${index}`"
              v-model="bankAccount.name"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-4 col-12">
            <label :for="`bankAccountMonthlyFee-${index}`" class="form-label">
              Tarifa mensal
            </label>
            <input
              :id="`bankAccountMonthlyFee-${index}`"
              v-model.lazy="bankAccount.monthlyFee"
              v-money="moneyFormat"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{
                'is-invalid':
                  v$.bankAccounts.$dirty &&
                  v$.bankAccounts.$each.$response.$errors[index].monthlyFee
                    .length,
              }"
              @blur="
                calculateAnnualFee(bankAccounts, index);
                preSave();
              "
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.bankAccounts.$dirty &&
                  v$.bankAccounts.$each.$response.$errors[index].monthlyFee
                    .length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`bankAccountAnnualFee-${index}`" class="form-label">
              Tarifa total
            </label>
            <input
              :id="`bankAccountMonthlyFee-${index}`"
              v-model.lazy="bankAccount.annualFee"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.bankAccounts.$dirty &&
                  v$.bankAccounts.$each.$response.$errors[index].annualFee
                    .length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.bankAccounts.$dirty &&
                  v$.bankAccounts.$each.$response.$errors[index].annualFee
                    .length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button
                class="btn btn-red"
                @click.prevent="remove(bankAccounts, index)"
              >
                Excluir conta corrente
              </button>
            </template>
          </div>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { VMoney } from "v-money";
import {
  getBRLFormat,
  getPercentageFormat,
  getFloatFormat,
} from "../../../helpers/formatting";
import { isYear } from "../../../validators/string";
import {
  floatToMoney,
  moneyToFloat,
  integerToString,
  floatToPercentage,
  booleanToString,
  stringToFloat,
} from "../../../helpers/converters";
import { getAdditionalInformationPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "../../shared/store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      incomeTaxCategory: "",
      incomeTaxPaid: "",
      incomeTaxPaidAmount: "",
      incomeTaxRefundedAmount: "",
      cards: [],
      bankAccounts: [],
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      incomeTaxCategory: {},
      incomeTaxPaid: {},
      incomeTaxPaidAmount: {
        minLength: minLength(7),
      },
      incomeTaxRefundedAmount: {
        minLength: minLength(7),
      },
      cards: {
        $each: helpers.forEach({
          name: {},
          monthlyFee: {
            minLength: minLength(7),
          },
          annualFee: {
            minLength: minLength(7),
          },
          limit: {
            minLength: minLength(7),
          },
          averageExpense: {
            minLength: minLength(7),
          },
          cashback: {
            minLength: minLength(7),
          },
        }),
      },
      bankAccounts: {
        $each: helpers.forEach({
          name: {},
          monthlyFee: {
            minLength: minLength(7),
          },
          annualFee: {
            minLength: minLength(7),
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      incomeTaxCategoryTypes: sharedTypes.INCOME_TAX_CATEGORY_TYPES,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
    floatFormat: function () {
      return getFloatFormat();
    },
    costsTotal: function () {
      return _.sumBy([...this.cards, ...this.bankAccounts], (item) => {
        return moneyToFloat(item.annualFee);
      });
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getAdditionalInformation: types.GET_ADDITIONAL_INFORMATION,
      autoSaveAdditionalInformation: types.AUTO_SAVE_ADDITIONAL_INFORMATION,
      getIncomeTaxCategoryTypes: sharedTypes.GET_INCOME_TAX_CATEGORY_TYPES,
    }),
    ...mapMutations({
      mutateAdditionalInformation: types.MUTATE_ADDITIONAL_INFORMATION,
    }),
    async fetchAdditionalInformation() {
      this.loading = true;
      try {
        const response = await this.getAdditionalInformation(
          this.$route.params.customerId
        );
        const {
          income_tax_category,
          income_tax_paid,
          income_tax_paid_amount,
          income_tax_refunded_amount,
          cards,
          bank_accounts,
        } = response.data;
        this.incomeTaxCategory = income_tax_category;
        this.incomeTaxPaid = booleanToString(income_tax_paid);
        this.incomeTaxPaidAmount = floatToMoney(income_tax_paid_amount);
        this.incomeTaxRefundedAmount = floatToMoney(income_tax_refunded_amount);
        this.cards = cards.map((card) => {
          const {
            name,
            monthly_fee,
            annual_fee,
            limit,
            average_expense,
            points,
            cashback,
          } = card;
          return {
            name,
            monthlyFee: floatToMoney(monthly_fee),
            annualFee: floatToMoney(annual_fee),
            limit: floatToMoney(limit),
            averageExpense: floatToMoney(average_expense),
            points: points,
            cashback: floatToPercentage(cashback),
          };
        });
        this.bankAccounts = bank_accounts.map((bank_account) => {
          const { name, monthly_fee, annual_fee } = bank_account;
          return {
            name,
            monthlyFee: floatToMoney(monthly_fee),
            annualFee: floatToMoney(annual_fee),
          };
        });
        const payload = getAdditionalInformationPayload(this);
        this.mutateAdditionalInformation(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações adicionais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchAdditionalInformation(),
          this.getIncomeTaxCategoryTypes(),
        ]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    addCard() {
      this.cards.push({
        name: "",
        monthlyFee: "",
        annualFee: "",
        limit: "",
        averageExpense: "",
        points: "",
        cashback: "",
      });
    },
    addBankAccount() {
      this.bankAccounts.push({
        name: "",
        monthlyFee: "",
        annualFee: "",
      });
    },
    remove(array, i) {
      array.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload = getAdditionalInformationPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateAdditionalInformation(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveAdditionalInformation(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as informações adicionais do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateAnnualFee(array, index) {
      const item = array[index];
      item.annualFee = floatToMoney(moneyToFloat(item.monthlyFee) * 12);
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  &.debts-value-container {
    background-image: var(--red-gradient);
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
</style>
