<template>
  <div>
    <message-modal />
    <app-header />
    <router-view :key="$route.fullPath" />
    <app-footer />
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader'
import AppFooter from '../components/AppFooter'
import MessageModal from '../../shared/components/MessageModal'
export default {
  components: {
    AppHeader,
    AppFooter,
    MessageModal
  }
}
</script>
