export const commecialProposalWppMessage = (link, days) => {
    var message = "Sua entrevista foi um sucesso e mal podemos esperar para começar seu Planejamento Financeiro!%0a%0a";
    message += "Como combinamos, enviamos a Proposta Comercial personalizada para você. Nela, você encontrará:%0a%0a";
    message += "✅ Seus objetivos: Um resumo detalhado dos seus sonhos e aspirações para o futuro, que guiarão todo nosso trabalho.%0a";
    message += "✅ Nossa abordagem: Uma visão geral do processo que usaremos para ajudar você a alcançar seus objetivos, com total transparência e segurança.%0a";
    message += "✅ Próximos passos: O roteiro completo para darmos início à sua jornada com a Futuro, desde a assinatura do contrato até a primeira reunião de planejamento.%0a%0a";
    message += "🔗 Para acessar a proposta, clique no link abaixo:%0a%0a";
    message += encodeURI(link);
    message += "%0a%0aLembre-se que a proposta é válida por " + days + " dias.%0a%0a";
    message += "Estamos à disposição para esclarecer qualquer dúvida que surgir. Entre em contato com a gente por e-mail ou telefone.";
    return message;
};