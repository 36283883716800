<template>
  <div class="chart-container">
    <div class="row justify-content-center mt-2 mb-3">
      <div class="col-auto">
        <span class="title">
          {{ title }}
        </span>
      </div>
    </div>
    <line-chart
      v-if="data"
      :chart-data="data"
      :options="options"
    />
  </div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
export default {
  components: {
    LineChart
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  padding: 15px;
  background-color: #284596;
  border-radius: 14px;
}
.title {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
</style>
