<template>
  <div :id="chartId">
    <apexchart
      type="radialBar"
      width="100%"
      height="260"
      :options="chartOptions"
      :series="computedSeries"
      :id="chartId + '-chart'"
    ></apexchart>
  </div>
</template>
<script>
import { ref, computed } from "vue";

export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    gastoFixo: {
      type: Number,
      default: 0,
    },
    gastoVariavel: {
      type: Number,
      default: 0,
    },
    investimentos: {
      type: Number,
      default: 0,
    },
    totais: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const computedSeries = computed(() => [
      props.gastoFixo,
      props.gastoVariavel,
      props.investimentos,
      props.totais,
    ]);
    const chartOptions = ref({
      chart: {
        height: 260,
        type: "radialBar",
      },
      colors: ["#B10CF9", "#FF64BE", "#43C5FF", "#274693"],
      plotOptions: {
        radialBar: {
          track: {
            background: "#193A8E",
            margin: 3,
          },
          dataLabels: {
            name: {
              fontSize: "12px",
              label: "TOTAL",
              offsetY: -8,
            },
            value: {
              show: true,
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff",
              offsetY: -3,
            },
            total: {
              show: true,
              label: "TOTAL",
              fontSize: "12px",
              fontWeight: "lighter",
              color: "#E0E0E0",
              formatter: function () {
                const value = props.totais;
                const formattedValue = new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(value);
                return formattedValue;
              },
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
        width: 0,
      },
      labels: ["GASTO FIXO", "GASTO VARIÁVEL", "INVESTIMENTOS"],
    });

    return {
      computedSeries,
      chartOptions,
    };
  },
};
</script>

<style lang="scss">
g [rel="4"] {
  display: none !important;
}
</style>
