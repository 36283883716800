<template>
  <iframe
    v-if="isGooglePresentation"
    :src="presentation"
    class="frame"
    frameborder="0"
    width="100%"
    height="600"
    allowfullscreen="true"
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
  />
  <div
    v-else
    class="frame"
    style="position: relative; width: 100%; height: 0; padding-top: 56.2500%; padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; will-change: transform;"
  >
    <iframe
      loading="lazy"
      style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
      :src="`https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;${presentation}&#x2F;view?embed`"
      allowfullscreen="true"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    />
  </div>
</template>

<script>
export default {
  props: {
    presentation: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      presentationVisible: false
    }
  },
  computed: {
    isGooglePresentation: function () {
      return this.presentation.includes('docs.google.com') ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    border-radius: 30px;
  }
</style>
