import * as types from "./types";

export default {
  [types.SAVING_INTERVIEW]: (state) => {
    return state.savingInterview;
  },
  [types.COMMENTS]: (state) => {
    return state.comments;
  },
  [types.CUSTOMER_INFO]: (state) => {
    return state.customerInfo;
  },
  [types.GOALS]: (state) => {
    return state.goals;
  },
  [types.SELECTED_GOALS]: (state) => {
    return state.selectedGoals;
  },
  [types.LIFE_INSURANCE]: (state) => {
    return state.lifeInsurance;
  },
  [types.ACQUISITIONS]: (state) => {
    return state.acquisitions;
  },
  [types.PATRIMONY]: (state) => {
    return state.patrimony;
  },
  [types.HEALTH_PLAN]: (state) => {
    return state.healthPlan;
  },
  [types.RETIREMENT]: (state) => {
    return state.retirement;
  },
  [types.MONTHLY_INVESTMENT]: (state) => {
    return state.monthlyInvestment;
  },
  [types.MONTHLY_INVESTMENT_CALCULATION]: (state) => {
    return state.monthlyInvestmentCalculation;
  },
  [types.DEBTS]: (state) => {
    return state.debts;
  },
  [types.CONTRACT_SERVICE_TYPES]: (state) => {
    return state.contractServiceTypes;
  },
  [types.PAYMENT_METHOD_TYPES]: (state) => {
    return state.paymentMethodTypes;
  },
  [types.CLOSER_CONSULTANTS]: (state) => {
    return state.closerConsultants;
  },
  [types.PROPOSAL]: (state) => {
    return state.proposal;
  },
  [types.PROPOSAL_CALCULATION]: (state) => {
    return state.proposalCalculation;
  },
  [types.CASH_FLOW_CATEGORIES]: (state) => {
    return state.cashFlowCategories;
  },
  [types.CASH_FLOW]: (state) => {
    return state.cashFlowCategories;
  },
  [types.CASH_FLOW_CALCULATION]: (state) => {
    return state.cashFlowCalculation;
  },
  [types.ADDITIONAL_INFORMATION]: (state) => {
    return state.additionalInformation;
  },
  [types.INVESTMENTS]: (state) => {
    return state.investments;
  },
  [types.INTERVIEW_FORM]: (state) => {
    return state.interviewForm;
  },
  [types.CUSTOMER_CONTRACTS]: (state) => {
    return state.customerContracts;
  },
  [types.INTERVIEW_CASH_FLOW_LOADING]: (state) => {
    return state.interviewCashFlowLoading;
  },
  [types.INTERVIEW_PROPOSAL_LOADING]: (state) => {
    return state.interviewProposalLoading;
  },
  [types.SUBSCRIPTION_PLANS]: (state) => {
    return state.subscriptionPlans;
  },
  [types.CUSTOMER_SUBSCRIPTION_PLANS]: (state) => {
    return state.customerSubscriptionPlans;
  },
  [types.SEARCH_INTERVIEW]: (state) => {
    return state.searchInterview;
  },
};
