// Getters
export const HEADER_TYPE = 'shared/HEADER_TYPE'
export const LEGAL_TYPES = 'shared/LEGAL_TYPES'
export const MARITAL_STATUSES = 'shared/MARITAL_STATUSES'
export const ADDRESS_STATES = 'shared/ADDRESS_STATES'
export const COUNTRIES_LIST = 'shared/COUNTRIES_LIST'

export const GENDERS = 'shared/GENDERS'
export const PRIORITIES = 'shared/PRIORITIES'
export const INCOME_TAX_CATEGORY_TYPES = 'shared/INCOME_TAX_CATEGORY_TYPES'
export const INVESTOR_PROFILE = 'shared/INVESTOR_PROFILE'

// Mutations
export const MUTATE_HEADER_TYPE = 'shared/MUTATE_HEADER_TYPE'
export const MUTATE_LEGAL_TYPES = 'shared/MUTATE_LEGAL_TYPES'
export const MUTATE_MARITAL_STATUSES = 'shared/MUTATE_MARITAL_STATUSES'
export const MUTATE_ADDRESS_STATES = 'shared/MUTATE_ADDRESS_STATES'
export const MUTATE_COUNTRIES_LIST = 'shared/MUTATE_COUNTRIES_LIST'
export const MUTATE_GENDERS = 'shared/MUTATE_GENDERS'
export const MUTATE_PRIORITIES = 'shared/MUTATE_PRIORITIES'
export const MUTATE_INCOME_TAX_CATEGORY_TYPES = 'shared/MUTATE_INCOME_TAX_CATEGORY_TYPES'
export const MUTATE_INVESTOR_PROFILE = 'shared/MUTATE_INVESTOR_PROFILE'

// Actions
export const SET_INTERVIEW_HEADER_TYPE = 'shared/SET_INTERVIEW_HEADER_TYPE'
export const SET_NO_OPTIONS_HEADER_TYPE = 'shared/SET_NO_OPTIONS_HEADER_TYPE'
export const GET_LEGAL_TYPES = 'shared/GET_LEGAL_TYPES'
export const GET_MARITAL_STATUSES = 'shared/GET_MARITAL_STATUSES'
export const GET_ADDRESS_STATES = 'shared/GET_ADDRESS_STATES'
export const GET_COUNTRIES_LIST = 'shared/GET_COUNTRIES_LIST'
export const GET_GENDERS = 'shared/GET_GENDERS'
export const GET_PRIORITIES = 'shared/GET_PRIORITIES'
export const GET_INCOME_TAX_CATEGORY_TYPES = 'shared/GET_INCOME_TAX_CATEGORY_TYPES'
export const GET_INVESTOR_PROFILE = 'shared/GET_INVESTOR_PROFILE'
export const GET_CEP = "shared/GET_CEP";
export const CHECK_DUPLICATE_CUSTOMER_PHONE = "shared/CHECK_DUPLICATE_CUSTOMER_PHONE";
export const CHECK_DUPLICATE_CONSULTANT_PHONE = "shared/CHECK_DUPLICATE_CONSULTANT_PHONE";
export const CHECK_DUPLICATE_EMAIL = "shared/CHECK_DUPLICATE_EMAIL";