<template>
  <div class="main-container dark bg-with-image h-100">
    <app-header />
    <div class="container h-100">
      <div class="card mx-5 h-100 d-flex flex-column align-items-center justify-content-center">
        <h1 class="page-title mb-2">
          Pré-entrevista concluída
        </h1>
        <span class="text-center">Você já realizou o preenchimento da pré-entrevista. Agora, seu consultor irá prosseguir com a consultoria e seu Futuro está a poucos passos de ser ainda melhor!</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as sharedTypes from '../../shared/store/types'
import AppHeader from '../../main/components/AppHeader'
export default {
  components: {
    AppHeader
  },
  mounted() {
    this.setNoOptionsHeaderType();
  },
  methods: {
    ...mapActions({
      setNoOptionsHeaderType: sharedTypes.SET_NO_OPTIONS_HEADER_TYPE,
    })
  },
}
</script>
