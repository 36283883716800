<template>
  <form id="futuro-customer-goals" class="my-5" @submit.prevent>
    <div class="card" :class="{ 'error-bg': v$.selectedGoals.$error }">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-goals.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <template v-if="isInterview">
            <h2>Quais seus principais objetivos?</h2>
          </template>
          <template v-if="!isInterview">
            <h2>
              Conta para a gente... quais os seus 3 principais objetivos
              financeiros?
            </h2>
          </template>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row">
        <div v-if="goals && Object.keys(goals).length > 0" class="col-12">
          <template v-if="!isDetail">
            <carousel
              v-model="initialSlideGoalsOptions"
              :items-to-show="isMobileTarget ? 1 : 4"
              :mouse-drag="false"
              :items-to-scroll="isMobileTarget ? 1 : 2"
            >
              <slide v-for="goal_key in Object.keys(goals)" :key="goal_key">
                <div class="goal-card">
                  <img
                    :src="require(`../../../assets/img/goal-${goal_key}.jpeg`)"
                    alt="Objetivo"
                  />
                  <div class="h-100 goal-card-content">
                    <div class="height-items">
                      <span class="title">
                        {{ goals[goal_key] }}
                      </span>
                    </div>
                    <div class="height-items">
                      <select
                        v-model="priorityValues[goal_key]"
                        class="form-select"
                        :disabled="selectedGoals.length >= 3 || isDetail"
                        :class="{
                          'select-disabled':
                            selectedGoals.length >= 3 || isDetail,
                        }"
                        @change="onChangeGoal($event, goal_key)"
                      >
                        <option value="" disabled selected>Selecione</option>
                        <option
                          v-for="priority_key in Object.keys(priorities)"
                          :key="priority_key"
                          :value="priority_key"
                        >
                          {{ priorities[priority_key] }}
                        </option>
                      </select>
                    </div>
                    <div class="row height-items">
                      <div class="col-12">
                        <button
                          v-if="priorityValues[goal_key]"
                          class="btn btn-red"
                          @click.prevent="openConfimationModal(goal_key)"
                        >
                          Excluir
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
              <template #addons>
                <pagination />
                <navigation />
              </template>
            </carousel>
          </template>
        </div>
        <div v-if="selectedGoals.length > 0" class="col-12">
          <carousel
            v-model="initialSlideGoalsValues"
            :items-to-show="isMobileTarget ? 1 : 2"
            :mouse-drag="false"
            :items-to-scroll="isMobileTarget ? 1 : 2"
          >
            <slide
              v-for="(selectedGoal, index) in selectedGoals"
              :key="selectedGoal.category"
            >
              <div class="selected-goal-card">
                <div class="row align-items-center">
                  <div class="col-md-5 col-12">
                    <img
                      :src="
                        require(`../../../assets/img/goal-${selectedGoal.category}.jpeg`)
                      "
                      alt="Objetivo"
                    />
                  </div>
                  <div class="col-md-7 col-12 d-flex flex-column">
                    <span class="title"> {{ selectedGoal.title }}: </span>
                    <select
                      v-model="selectedGoal.priority"
                      class="form-select"
                      :disabled="isDetail"
                      :class="{ 'select-disabled': isDetail }"
                      @change="
                        onChangeSelectedGoal($event, selectedGoal.category)
                      "
                    >
                      <option
                        v-for="priority_key in Object.keys(priorities)"
                        :key="priority_key"
                        :value="priority_key"
                      >
                        {{ priorities[priority_key] }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label :for="`why-${index}`" class="form-label">
                      Por quê?
                    </label>
                    <textarea
                      :id="`why-${index}`"
                      v-model="selectedGoal.why"
                      type="text"
                      class="form-control"
                      :disabled="isDetail"
                      :class="{
                        'is-invalid':
                          v$.selectedGoals.$dirty &&
                          v$.selectedGoals.$each.$response.$errors[index].why
                            .length,
                      }"
                      rows="4"
                      @blur="preSave()"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <label :for="`when-${index}`" class="form-label">
                      Quando?
                    </label>
                    <input
                      :id="`when-${index}`"
                      v-model="selectedGoal.when"
                      v-mask="'##/##/####'"
                      type="tel"
                      :disabled="isDetail"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          v$.selectedGoals.$dirty &&
                          v$.selectedGoals.$each.$response.$errors[index].when
                            .length,
                      }"
                      @blur="preSave()"
                    />
                    <div class="invalid-feedback">
                      <span
                        v-if="
                          v$.selectedGoals.$dirty &&
                          v$.selectedGoals.$each.$response.$errors[index].when
                            .length
                        "
                      >
                        Você deve informar uma data válida
                      </span>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <label :for="`how-much-${index}`" class="form-label">
                      Quanto?
                    </label>
                    <input
                      :id="`how-much-${index}`"
                      v-model.lazy="selectedGoal.howMuchToInvest"
                      v-money="moneyFormat"
                      :disabled="isDetail"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          v$.selectedGoals.$dirty &&
                          v$.selectedGoals.$each.$response.$errors[index]
                            .howMuchToInvest.length,
                      }"
                      @blur="preSave()"
                    />
                    <div class="invalid-feedback">
                      <span
                        v-if="
                          v$.selectedGoals.$dirty &&
                          v$.selectedGoals.$each.$response.$errors[index]
                            .howMuchToInvest.length
                        "
                      >
                        Você deve informar um valor válido
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label :for="`resume-${index}`" class="form-label">
                      Resumo:
                    </label>
                    <textarea
                      :id="`resume-${index}`"
                      v-model="selectedGoal.resume"
                      :disabled="isDetail"
                      type="text"
                      class="form-control"
                      rows="4"
                      @blur="preSave()"
                    />
                  </div>
                </div>
                <template v-if="!isDetail">
                  <div class="row">
                    <div class="col-auto">
                      <button
                        class="btn btn-red"
                        @click.prevent="
                          openConfimationModal(selectedGoal.category)
                        "
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </slide>
            <template #addons>
              <pagination />
              <navigation />
            </template>
          </carousel>
        </div>
      </div>

      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
      <div class="text-white" v-if="v$.selectedGoals.$error">
        <h6>É necessário selecionar ao menos um objetivo.</h6>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  helpers,
  requiredIf,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { VMoney } from "v-money";
import { getBRLFormat } from "../../../helpers/formatting";
import { dateToString, floatToMoney } from "../../../helpers/converters";
import { isValidDate } from "../../../validators/date";
import { isMobile } from "../../../helpers/resolution";
import { getSelectedGoalsPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "../../shared/store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
import ConfirmModal from "../../shared/components/ConfirmModal";
export default {
  components: {
    Spinner,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    CommentsSection,
    ConfirmModal,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const initialSlideGoalsOptions = ref(undefined);
    const initialSlideGoalsValues = ref(undefined);
    onMounted(() => {
      initialSlideGoalsOptions.value = 0;
      initialSlideGoalsValues.value = 0;
    });
    return {
      v$: useVuelidate({ $registerAs: props.interviewSection }),
      initialSlideGoalsOptions,
      initialSlideGoalsValues,
    };
  },
  data() {
    return {
      selectedGoals: [],
      priorityValues: {},
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      selectedGoals: {
        required: requiredIf(() => {
          return this.isInterview;
        }),
        $each: helpers.forEach({
          id: {},
          category: { required },
          title: { required },
          priority: { required },
          why: {},
          when: {
            minLength: minLength(10),
            isValidDate(value) {
              return isValidDate(value);
            },
          },
          howMuchToInvest: {
            minLength: minLength(7),
          },
          resume: {},
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      goals: types.GOALS,
      priorities: sharedTypes.PRIORITIES,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    isMobileTarget: function () {
      return isMobile();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getGoals: types.GET_GOALS,
      getPriorities: sharedTypes.GET_PRIORITIES,
      getSelectedGoals: types.GET_SELECTED_GOALS,
      autoSaveSelectedGoals: types.AUTO_SAVE_SELECTED_GOALS,
      deleteSelectedGoal: types.DELETE_SELECTED_GOAL,
    }),
    ...mapMutations({
      mutateSelectedGoals: types.MUTATE_SELECTED_GOALS,
    }),

    async fetchGoals() {
      this.loading = true;
      try {
        const response = await this.getGoals();
        Object.keys(this.goals).forEach((category) => {
          this.priorityValues[category] = "";
        });
      } catch (error) {}
    },

    async fetchSelectedGoals() {
      this.loading = true;
      try {
        const response = await this.getSelectedGoals(
          this.$route.params.customerId
        );
        this.selectedGoals = response.data?.goals.map((goal) => {
          const {
            category,
            title,
            priority,
            why,
            when,
            how_much_to_invest,
            resume,
          } = goal;

          this.priorityValues[category] = priority;

          return {
            category,
            title,
            priority,
            why,
            when: dateToString(when),
            howMuchToInvest: floatToMoney(how_much_to_invest),
            resume,
          };
        });

        const payload = getSelectedGoalsPayload({
          token: this.token,
          selectedGoals: this.selectedGoals,
        });
        this.mutateSelectedGoals(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter os objetivos do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchGoals(),
          this.fetchSelectedGoals(),
          this.getPriorities(),
        ]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    onChangeGoal(event, category) {
      const priority = event.target.value;
      const index = _.findIndex(this.selectedGoals, { category });
      if (priority) {
        if (index < 0) {
          this.selectedGoals.push({
            category,
            title: this.goals[category],
            priority,
            why: "",
            when: "",
            howMuchToInvest: "",
            resume: "",
          });
        } else {
          this.selectedGoals[index].priority = priority;
        }
      } else {
        this.selectedGoals.splice(index, 1);
      }
      this.preSave();
    },
    onChangeSelectedGoal(event, category) {
      const priority = event.target.value;
      this.priorityValues[category] = priority;
      if (!priority) {
        const index = _.findIndex(this.selectedGoals, { category });

        this.removeSelectedGoal(category);
      } else {
        this.preSave();
      }
    },
    removeSelectedGoal(category) {
      const indexToRemove = this.selectedGoals.findIndex(
        (selectedGoal) => selectedGoal.category === category
      );

      if (indexToRemove !== -1) {
        this.priorityValues[category] = "";

        this.selectedGoals.splice(indexToRemove, 1);

        this.preSave();
      }
    },
    preSave() {
      const { token, selectedGoals } = this;
      const payload = getSelectedGoalsPayload({ token, selectedGoals });
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateSelectedGoals(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveSelectedGoals(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os objetivos do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    categoryGoal(category) {
      const categoryDescriptions = {
        home: "Imóvel próprio ou investimento imobiliário",
        travel: "Viagens",
        retirement: "Aposentadoria",
        financial: "Segurança financeira da família",
        children: "Ajudar filhos nos estudos e moradia",
        planning: "Planejamento organização financeira",
        investment: "Investimento",
        car: "Compra ou troca do veículo",
        others: "Outros objetivos: casamento, intercâmbio, etc.",
      };

      return categoryDescriptions[category];
    },
    openConfimationModal(category) {
      let goal = this.categoryGoal(category);

      this.$vfm.show(
        {
          component: ConfirmModal,
        },
        {
          messages: [`Deseja excluir <b>${goal}</b> de seus objetivos?`],
          handleConfirm: () => this.removeSelectedGoal(category),
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.goal-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #284596;
  border-radius: 25px;
  color: white;
  width: 250px;
  height: 340px;
  // padding-bottom: 15px;
  img {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
}
.goal-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}
.selected-goal-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #284596;
  border-radius: 25px;
  color: white;
  padding: 15px;
  text-align: left;
  height: 580px;
  width: 520px;
  img {
    border-radius: 15px;
    width: 190px;
    height: 96px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .carousel {
    ol,
    ul {
      margin-left: 0;
      padding-left: 0;
    }
  }
  .selected-goal-card {
    height: 760px;
    .title {
      margin-top: 15px;
    }
  }
}

.height-items {
  height: 33.333%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-bg {
  background-color: rgba(220, 53, 69, 0.8);
}
</style>
