<template>
  <div
    id="interview-title"
    class="row top-row"
  >
    <div class="col-md-4 col-12">
      <h1>
        Transforme o seu Futuro
      </h1>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4 col-12">
      <p>
        Estamos a apenas um passo de criar o futuro dos seus sonhos ao seu lado.
        O que você precisa para transformar o seu futuro começa aqui.
      </p>
       <p>
        Agora, com a sua colaboração, vamos buscar o diagnóstico da sua vida financeira abaixo. Venha com a gente!
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4 col-12">
      <button
        class="btn btn-orange col-auto mx-1"
        @click.prevent="goToPresentation()"
      >
        Vamos lá!
      </button>
    </div>
  </div>
  <div class="row mkt-cards align-items-center mt-5 mb-5">
    <div class="image-card purple me-md-3 my-2">
      <div class="row p-4 h-100 align-items-center">
        <p class="col-7 m-0">
          Quer adquirir um bem, planejar sua aposentadoria ou sua próxima viagem? Vem com a gente!
        </p>
      </div>
    </div>
    <div class="image-card mountain my-2">
      <div class="row p-4 h-100 align-items-center">
        <p class="col-11 m-0">
          Alcance sua independência financeira, realize seus sonhos e tenha um planejador ao seu lado para orientar suas decisões, com todo o suporte necessário.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToPresentation() {
      const presentationDiv = document.getElementById('futuro-presentation')
      if (presentationDiv) {
        presentationDiv.scrollIntoView({ behavior: 'smooth', block: 'end' })
      } else {
        const presentationDiv = document.getElementById('futuro-customer-info')
        presentationDiv.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.top-row {
  margin-top: 100px;
}
h1 {
  font-size: 45px;
  font-weight: 600;
}
button {
  width: 180px;
}
.mkt-cards {
  justify-content: end;
}
.image-card {
  &.purple {
    background-image: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/bg-purple.png");
    width: 437px;
    height: 200px;
  }
  &.mountain {
    background-image: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/bg-mountain.png");
    width: 302px;
    height: 200px;
  }
  p {
    font-size: 20px;
    line-height: 1;
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .mkt-cards {
    justify-content: center;
  }
  .image-card {
    &.purple {
      background-image: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/bg-purple-small.png");
      width: 302px;
      height: 138px;
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
