export const CREDENTIALS_STORAGE_KEY = 'token_interview'

export const CONSULTANT_LEVEL_PNH = 'PNH'
export const CONSULTANT_LEVEL_PNH_VALUE = 9

export const APP_BUSINESS_PARTNER_ROLE_PNH = '6'
export const APP_BUSINESS_PARTNER_ROLE_PNC = '24'
export const APP_BUSINESS_PARTNER_ROLE_PNP = '7'
export const APP_BUSINESS_PARTNER_ROLE_PNF = '8'
export const APP_BUSINESS_PARTNER_ROLE_PNM1 = '9'
export const APP_BUSINESS_PARTNER_ROLE_PNM2 = '10'
export const APP_BUSINESS_PARTNER_ROLE_PNM3 = '11'

export const APP_BUSINESS_PARTNER_ROLE_PNP1 = '25'
export const APP_BUSINESS_PARTNER_ROLE_PNP2 = '26'
export const APP_BUSINESS_PARTNER_ROLE_PNP3 = '27'

export const APP_BUSINESS_PARTNER_ROLE_PNS = '12'
export const APP_BUSINESS_PARTNER_ROLE_FF = '13'
