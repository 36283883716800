<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 py-2">
          <p class="p-0 m-0">
            ®
            <b> Futuro Consultoria Financeira </b>
            - Todos os direitos reservados
          </p>
        </div>
      </div>
    </div>
    <!--
    <div class="floating">
      <a
        href="https://api.whatsapp.com/send?phone=%205511972691879&amp;text=Ol%C3%A1%2C%20tudo%20bem%3F%20Estou%20no%20site%20e%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20Finan%C3%A7as%20Pessoais.%20"
        target="_blank"
      >
        <img
          src="~@/assets/img//whatsapp-floating.svg"
          alt="Contate-nos via Whatsapp"
          class="animated bounce"
        />
      </a>
    </div>
    -->
  </footer>
</template>

<style lang="scss" scoped>
footer p {
  font-size: 0.6rem !important;
  line-height: 0.8rem !important;
  font-weight: 300 !important;
}
</style>
