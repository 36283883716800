// Getters
export const NPS_SURVEY = "nps_survey/NPS_SURVEY";

// Mutations
export const MUTATE_NPS_SURVEY = "nps_survey/MUTATE_NPS_SURVEY";
export const MUTATE_SET_NPS_SURVEY = "nps_survey/SET_MUTATE_NPS_SURVEY";

// Actions
export const GET_NPS_SURVEY = "nps_survey/GET_NPS_SURVEY";
export const SET_NPS_SURVEY = "nps_survey/SET_NPS_SURVEY";
