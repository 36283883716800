<template>
  <form id="futuro-customer-info" class="my-5" @submit.prevent>
    <div class="card" id="dados_pessoais">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-user.svg"
            alt="Futuro | Consultoria Financeira"
          />

          <h2>Dados pessoais</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <template v-if="isInterview">
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label for="name" class="form-label"> Nome </label>
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.name.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label for="email" class="form-label"> E-mail </label>
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': v$.email.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.email.required.$invalid">
                Você deve informar um e-mail
              </span>
              <span v-if="v$.email.email.$invalid">
                O e-mail informado é inválido
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-3 col-12">
            <label for="legalType" class="form-label"> Pessoa </label>
            <select
              id="legalType"
              v-model="legalType"
              class="form-select"
              :class="{
                'is-invalid': v$.legalType.$error,
                'select-disabled': isDetail,
              }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(legalTypes)"
                :key="key"
                :value="key"
              >
                {{ legalTypes[key] }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.legalType.required.$invalid">
                Você deve informar um tipo
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="cpf" class="form-label"> CPF </label>
            <input
              id="cpf"
              v-model="cpf"
              v-mask="'###.###.###-##'"
              type="tel"
              :disabled="isDetail"
              class="form-control"
              :class="{ 'is-invalid': v$.cpf.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cpf.required.$invalid">
                Você deve informar um CPF
              </span>
              <span v-if="v$.cpf.valid.$invalid">
                O CPF informado é inválido
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="rg" class="form-label"> RG </label>
            <input
              id="rg"
              v-model="rg"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.rg.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.rg.required.$invalid">
                Você deve informar um RG
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="rg" class="form-label"> Data de expedição do RG </label>
            <input
              id="rg"
              v-model="rgExpeditionDate"
              v-mask="'##/##/####'"
              type="tel"
              :disabled="isDetail"
              class="form-control"
              :class="{ 'is-invalid': v$.rgExpeditionDate.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.rgExpeditionDate.minLength.$invalid ||
                  v$.rgExpeditionDate.isValidDate.$invalid
                "
              >
                A data informada é inválida
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-3 col-12">
            <label for="maritalStatus" class="form-label"> Estado civil </label>
            <select
              id="maritalStatus"
              v-model="maritalStatus"
              class="form-select"
              :class="{
                'is-invalid': v$.maritalStatus.$error,
                'select-disabled': isDetail,
              }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(maritalStatuses)"
                :key="key"
                :value="key"
              >
                {{ maritalStatuses[key] }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.maritalStatus.required.$invalid">
                Você deve informar um estado civil
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="countrySelect" class="form-label"> País </label>
            <select
              id="countrySelect"
              v-model="country"
              :disabled="isDetail"
              class="form-select"
              :class="{
                'is-invalid': v$.country.$error,
                'select-disabled': isDetail,
              }"
              @blur="preSave()"
            >
              <option
                v-for="countrySelected in countriesList"
                :key="countrySelected.sigla2"
                :value="countrySelected.sigla2"
              >
                {{ countrySelected.nome }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.country.required.$invalid">
                Você deve informar um país
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="cellPhone" class="form-label"> Celular </label>
            <vue-tel-input
              id="cellPhone"
              v-model="cellPhone"
              class="form-select"
              :class="{ 'is-invalid': v$.cellPhone.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cellPhone.required.$invalid">
                Você deve informar um telefone celular
              </span>
              <span v-if="v$.cellPhone.minLength.$invalid">
                O telefone celular informado é inválido
              </span>
              <span v-if="v$.cellPhone.maxLength.$invalid">
                O telefone celular informado é inválido
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="phone" class="form-label"> Telefone residencial </label>
            <input
              id="phone"
              v-model="phone"
              v-mask="'+## ## #### ####'"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.phone.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.phone.minLength.$invalid">
                O telefone informado é inválido
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-3 col-12">
            <label for="birthDate" class="form-label">
              Data de nascimento
            </label>
            <input
              id="birthDate"
              v-model="birthDate"
              v-mask="'##/##/####'"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': v$.birthDate.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.birthDate.required.$invalid">
                Você deve informar uma data de nascimento
              </span>
              <span v-if="v$.birthDate.minLength.$invalid">
                A data informada é inválida
              </span>
              <span v-if="v$.birthDate.isLegalAge.$invalid">
                O cliente precisa ser maior de idade
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="gender" class="form-label"> Gênero </label>
            <select
              id="gender"
              v-model="gender"
              :disabled="isDetail"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(genders)"
                :key="key"
                :value="key"
              >
                {{ genders[key] }}
              </option>
            </select>
          </div>
          <div class="col-md-6 col-12">
            <label for="occupation" class="form-label"> Profissão </label>
            <input
              id="occupation"
              v-model="occupation"
              type="text"
              class="form-control"
              :disabled="isDetail"
              @blur="preSave()"
            />
          </div>
        </div>
        <div v-if="country == 'BR'" class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label for="addressCep" class="form-label"> CEP </label>
            <input
              id="addressCep"
              v-model="addressCep"
              v-mask="'##.###-###'"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': v$.addressCep.$error }"
              :disabled="isDetail"
              @blur="preSave()"
              @change="searchCep"
            />
            <div class="invalid-feedback">
              <span v-if="v$.addressCep.required.$invalid">
                Você deve informar um CEP
              </span>
              <span v-if="v$.addressCep.minLength.$invalid">
                O CEP informado é inválido
              </span>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <label for="addressState" class="form-label"> Estado </label>
            <select
              id="addressState"
              v-model="addressState"
              :disabled="isDetail"
              class="form-select"
              :class="{
                'is-invalid': v$.addressState.$error,
                'select-disabled': isDetail,
              }"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(addressStates)"
                :key="key"
                :value="key"
              >
                {{ addressStates[key] }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.addressState.required.$invalid">
                Você deve informar um estado
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label for="addressCity" class="form-label"> Cidade </label>
            <input
              id="addressCity"
              v-model="addressCity"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{ 'is-invalid': v$.addressCity.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.addressCity.required.$invalid">
                Você deve informar uma cidade
              </span>
            </div>
          </div>
        </div>
        <div v-if="country == 'BR'" class="row mb-3 gy-3">
          <div class="col-md-10 col-12">
            <label for="addressStreet" class="form-label"> Logradouro </label>
            <input
              id="addressStreet"
              v-model="addressStreet"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.addressStreet.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.addressStreet.required.$invalid">
                Você deve informar um logradouro
              </span>
            </div>
          </div>
          <div class="col-md-2 col-12">
            <label for="addressNumber" class="form-label"> Número </label>
            <input
              id="addressNumber"
              v-model="addressNumber"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{ 'is-invalid': v$.addressNumber.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.addressNumber.required.$invalid">
                Você deve informar um número
              </span>
            </div>
          </div>
        </div>
        <div v-if="country == 'BR'" class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label for="addressDistrict" class="form-label"> Bairro </label>
            <input
              id="addressDistrict"
              v-model="addressDistrict"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.addressDistrict.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.addressDistrict.required.$invalid">
                Você deve informar um bairro
              </span>
            </div>
          </div>
          <div class="col-md-8 col-12">
            <label for="addressComplement" class="form-label">
              Complemento
            </label>
            <input
              id="addressComplement"
              v-model="addressComplement"
              :disabled="isDetail"
              type="text"
              class="form-control"
              @blur="preSave()"
            />
          </div>
        </div>
        <div v-if="country != 'BR'" class="row mb-3 gy-3">
          <div class="col-md-12 col-12">
            <label for="singleLineAddress" class="form-label"> Endereço </label>
            <input
              id="singleLineAddress"
              v-model="singleLineAddress"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.singleLineAddress.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.singleLineAddress.required.$invalid">
                Você deve informar um endereço
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!isInterview">
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label for="name" class="form-label"> Nome </label>
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.name.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.name.required.$invalid">
                Você deve informar um nome
              </span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <label for="email" class="form-label"> E-mail </label>
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              :class="{ 'is-invalid': v$.email.$error || duplicateEmail || emailTakenByConsultant }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.email.required.$invalid">
                Você deve informar um e-mail
              </span>
              <span v-if="v$.email.email.$invalid">
                O e-mail informado é inválido
              </span>
              <span v-if="duplicateEmail">
                O e-mail informado já está sendo utilizado
              </span>
              <span v-if="emailTakenByConsultant">
                O e-mail utilizado já está sendo utilizado por um consultor
              </span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label for="legalType" class="form-label"> Pessoa </label>
            <select
              id="legalType"
              v-model="legalType"
              :disabled="Object.keys(legalTypes).length === 0"
              class="form-select"
              :class="{ 'is-invalid': v$.legalType.$error }"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(legalTypes)"
                :key="key"
                :value="key"
              >
                {{ legalTypes[key] }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.legalType.required.$invalid">
                Você deve informar um tipo
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label for="maritalStatus" class="form-label"> Estado civil </label>
            <select
              id="maritalStatus"
              v-model="maritalStatus"
              :disabled="Object.keys(maritalStatuses).length === 0"
              class="form-select"
              :class="{ 'is-invalid': v$.maritalStatus.$error }"
              @blur="preSave()"
            >
              <option
                v-for="key in Object.keys(maritalStatuses)"
                :key="key"
                :value="key"
              >
                {{ maritalStatuses[key] }}
              </option>
            </select>
            <div class="invalid-feedback">
              <span v-if="v$.maritalStatus.required.$invalid">
                Você deve informar um estado civil
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="birthDate" class="form-label">
              Data de nascimento
            </label>
            <input
              id="birthDate"
              v-model="birthDate"
              v-mask="'##/##/####'"
              type="tel"
              class="form-control"
              :class="{ 'is-invalid': v$.birthDate.$error }"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.birthDate.required.$invalid">
                Você deve informar uma data de nascimento
              </span>
              <span v-if="v$.birthDate.minLength.$invalid">
                A data informada é inválida
              </span>
              <span v-if="v$.birthDate.isLegalAge.$invalid">
                O cliente precisa ser maior de idade
              </span>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <label for="cellPhone" class="form-label"> Celular </label>
            <vue-tel-input
              id="cellPhone"
              v-model="cellPhone"
              class="form-control"
              :class="{ 'is-invalid': v$.cellPhone.$error || cellPhoneDuplicated}"
              :disabled="isDetail"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.cellPhone.required.$invalid">
                Você deve informar um telefone celular
              </span>
              <span v-if="v$.cellPhone.minLength.$invalid">
                O telefone celular informado é inválido
              </span>
              <span v-if="v$.cellPhone.maxLength.$invalid">
                O telefone celular informado é inválido
              </span>
              <span v-if="cellPhoneDuplicated">
                O telefone celular informado já pertence a um cliente futuro
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!isDetail">
        <div class="row align-items-center mb-3 px-3">
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="hasPartner = !hasPartner"
          >
            {{ hasPartner ? "Remover" : "Add" }} companheiro
          </button>
          <template v-if="isInterview">
            <button
              class="btn btn-orange col-auto mx-1"
              @click.prevent="addChild"
            >
              Add filho
            </button>
            <button
              class="btn btn-orange col-auto mx-1"
              @click.prevent="addPet"
            >
              Add pet
            </button>
          </template>
        </div>
      </template>
      <!-------------------- COMPANHEIRO -------------------->
      <template v-if="isInterview">
        <!-------------------- Entrevista -------------------->
        <template v-if="hasPartner">
          <div class="card-title mt-4">
            <h2>Companheiro(a)</h2>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-6 col-12">
              <label for="partnerName" class="form-label"> Nome </label>
              <input
                id="partnerName"
                v-model="partnerName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerName.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerName.required.$invalid">
                  Você deve informar um nome
                </span>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <label for="partnerEmail" class="form-label"> E-mail </label>
              <input
                id="partnerEmail"
                v-model="partnerEmail"
                type="partnerEmail"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerEmail.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerEmail.email.$invalid">
                  O e-mail informado é inválido
                </span>
              </div>
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-3 col-12">
              <label for="partnerCpf" class="form-label"> CPF </label>
              <input
                id="partnerCpf"
                v-model="partnerCpf"
                v-mask="'###.###.###-##'"
                type="tel"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerCpf.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerCpf.valid.$invalid">
                  O CPF informado é inválido
                </span>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerRg" class="form-label"> RG </label>
              <input
                id="partnerRg"
                v-model="partnerRg"
                type="text"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerBirthDate" class="form-label">
                Data de nascimento
              </label>
              <input
                id="partnerBirthDate"
                v-model="partnerBirthDate"
                v-mask="'##/##/####'"
                type="tel"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerBirthDate.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerBirthDate.minLength.$invalid">
                  A data informada é inválida
                </span>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerGender" class="form-label"> Gênero </label>
              <select
                id="partnerGender"
                v-model="partnerGender"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option
                  v-for="key in Object.keys(genders)"
                  :key="key"
                  :value="key"
                >
                  {{ genders[key] }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-3 col-12">
              <label for="partnerCountrySelect" class="form-label">
                País
              </label>
              <select
                id="partnerCountrySelect"
                v-model="partnerCountry"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option
                  v-for="partnerCountrySelected in countriesList"
                  :key="partnerCountrySelected.sigla2"
                  :value="partnerCountrySelected.sigla2"
                >
                  {{ partnerCountrySelected.nome }}
                </option>
              </select>
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerCellPhone" class="form-label"> Celular </label>
              <vue-tel-input
                id="partnerCellPhone"
                v-model="partnerCellPhone"
                :class="{ 'is-invalid': v$.partnerCellPhone.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerCellPhone.minLength.$invalid">
                  O telefone celular informado é inválido
                </span>
                <span v-if="v$.partnerCellPhone.maxLength.$invalid">
                  O telefone celular informado é inválido
                </span>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerPhone" class="form-label">
                Telefone residencial
              </label>
              <input
                id="partnerPhone"
                v-model="partnerPhone"
                v-mask="'+## ## #### ####'"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerPhone.$error }"
                :disabled="isDetail"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerPhone.minLength.$invalid">
                  O telefone informado é inválido
                </span>
              </div>
            </div>
            <div class="col-md-3 col-12">
              <label for="partnerOccupation" class="form-label">
                Profissão
              </label>
              <input
                id="partnerOccupation"
                v-model="partnerOccupation"
                :disabled="isDetail"
                type="text"
                class="form-control"
                @blur="preSave()"
              />
            </div>
          </div>
          <div v-if="partnerCountry == 'BR'" class="row mb-3 gy-3">
            <div class="col-md-4 col-12">
              <label for="partnerAddressCep" class="form-label"> CEP </label>
              <input
                id="partnerAddressCep"
                v-model="partnerAddressCep"
                v-mask="'##.###-###'"
                type="tel"
                class="form-control"
                :disabled="isDetail"
                :class="{ 'is-invalid': v$.partnerAddressCep.$error }"
                @blur="preSave()"
                @change="searchPartnerCep"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerAddressCep.minLength.$invalid">
                  O CEP informado é inválido
                </span>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <label for="partnerAddressState" class="form-label">
                Estado
              </label>
              <select
                id="partnerAddressState"
                v-model="partnerAddressState"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option
                  v-for="key in Object.keys(addressStates)"
                  :key="key"
                  :value="key"
                >
                  {{ addressStates[key] }}
                </option>
              </select>
            </div>
            <div class="col-md-6 col-12">
              <label for="partnerAddressCity" class="form-label">
                Cidade
              </label>
              <input
                id="partnerAddressCity"
                v-model="partnerAddressCity"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
          </div>
          <div v-if="partnerCountry == 'BR'" class="row mb-3 gy-3">
            <div class="col-md-10 col-12">
              <label for="partnerAddressStreet" class="form-label">
                Logradouro
              </label>
              <input
                id="partnerAddressStreet"
                v-model="partnerAddressStreet"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12">
              <label for="partnerAddressNumber" class="form-label">
                Número
              </label>
              <input
                id="partnerAddressNumber"
                v-model="partnerAddressNumber"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
          </div>
          <div v-if="partnerCountry == 'BR'" class="row mb-3 gy-3">
            <div class="col-md-4 col-12">
              <label for="partnerAddressDistrict" class="form-label">
                Bairro
              </label>
              <input
                id="partnerAddressDistrict"
                v-model="partnerAddressDistrict"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-8 col-12">
              <label for="partnerAddressComplement" class="form-label">
                Complemento
              </label>
              <input
                id="partnerAddressComplement"
                v-model="partnerAddressComplement"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
          </div>
          <div v-if="partnerCountry != 'BR'" class="row mb-3 gy-3">
            <div class="col-md-12 col-12">
              <label for="partnerSingleLineAddress" class="form-label">
                Endereço
              </label>
              <input
                id="partnerSingleLineAddress"
                v-model="partnerSingleLineAddress"
                type="text"
                class="form-control"
                :disabled="isDetail"
                @blur="preSave()"
              />
            </div>
          </div>
        </template>
      </template>
      <!--------------------Pré Entrevista -------------------->
      <template v-if="!isInterview">
        <template v-if="hasPartner">
          <div class="card-title mt-4">
            <h2>Companheiro(a)</h2>
          </div>
          <div class="row mb-3 gy-3">
            <div class="col-md-12 col-12">
              <label for="partnerName" class="form-label"> Nome </label>
              <input
                id="partnerName"
                v-model="partnerName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.partnerName.$error }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span v-if="v$.partnerName.required.$invalid">
                  Você deve informar um nome
                </span>
              </div>
            </div>
          </div>
        </template>
      </template>

      <!-------------------- FILHO -------------------->
      <template v-if="isInterview">
        <div v-if="children.length > 0" class="card-title mt-4 mb-3">
          <h2>Filhos</h2>
        </div>
        <div
          v-for="(child, index) in children"
          :key="`child-${index}`"
          class="inner-card mb-3"
        >
          <div class="row align-items-end">
            <div class="col-md-4 col-12">
              <label :for="`childName-${index}`" class="form-label">
                Nome
              </label>
              <input
                :id="`childName-${index}`"
                v-model="child.name"
                type="text"
                class="form-control"
                :disabled="isDetail"
                :class="{
                  'is-invalid':
                    v$.children.$dirty &&
                    v$.children.$each.$response.$errors[index].name.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.children.$dirty &&
                    v$.children.$each.$response.$errors[index].name.length
                  "
                >
                  Você deve informar um nome
                </span>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <label :for="`childAge-${index}`" class="form-label">
                Idade
              </label>
              <input
                :id="`childAge-${index}`"
                v-model="child.age"
                type="number"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12">
              <label :for="`childBirthdate-${index}`" class="form-label">
                Data de nascimento
              </label>
              <input
                :id="`childBirthdate-${index}`"
                v-model="child.birth_date"
                v-mask="'##/##/####'"
                type="tel"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12" :class="{ 'col-md-5': isDetail }">
              <label :for="`childGender-${index}`" class="form-label">
                Gênero
              </label>
              <select
                :id="`childGender-${index}`"
                v-model="child.gender"
                :disabled="isDetail"
                class="form-select"
                :class="{ 'select-disabled': isDetail }"
                @blur="preSave()"
              >
                <option
                  v-for="key in Object.keys(genders)"
                  :key="key"
                  :value="key"
                >
                  {{ genders[key] }}
                </option>
              </select>
            </div>

            <div class="col-md-2 col-12">
              <template v-if="!isDetail">
                <button
                  class="btn btn-red mb-1"
                  @click.prevent="removeChild(index)"
                >
                  Excluir
                </button>
              </template>
            </div>
          </div>
        </div>
      </template>

      <!-------------------- PET -------------------->
      <template v-if="isInterview">
        <div v-if="pets.length > 0" class="card-title mt-4 mb-3">
          <h2>Pets</h2>
        </div>
        <div
          v-for="(pet, index) in pets"
          :key="`pet-${index}`"
          class="inner-card mb-3"
        >
          <div class="row align-items-end">
            <div class="col-md-4 col-12">
              <label :for="`petName-${index}`" class="form-label"> Nome </label>
              <input
                :id="`petName-${index}`"
                v-model="pet.name"
                type="text"
                :disabled="isDetail"
                class="form-control"
                :class="{
                  'is-invalid':
                    v$.pets.$dirty &&
                    v$.pets.$each.$response.$errors[index].name.length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.pets.$dirty &&
                    v$.pets.$each.$response.$errors[index].name.length
                  "
                >
                  Você deve informar um nome
                </span>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <label :for="`petAge-${index}`" class="form-label"> Idade </label>
              <input
                :id="`petAge-${index}`"
                v-model="pet.age"
                :disabled="isDetail"
                type="number"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12">
              <label :for="`petBirthdate-${index}`" class="form-label">
                Data de nascimento
              </label>
              <input
                :id="`petBirthdate-${index}`"
                v-model="pet.birth_date"
                v-mask="'##/##/####'"
                type="tel"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12" :class="{ 'col-md-6': isDetail }">
              <label :for="`petBreed-${index}`" class="form-label">
                Raça
              </label>
              <input
                :id="`petBreed-${index}`"
                v-model="pet.breed"
                type="text"
                :disabled="isDetail"
                class="form-control"
                @blur="preSave()"
              />
            </div>
            <div class="col-md-2 col-12">
              <template v-if="!isDetail">
                <button
                  class="btn btn-red mb-1"
                  @click.prevent="removePet(index)"
                >
                  Excluir
                </button>
              </template>
            </div>
          </div>
        </div>
      </template>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  minLength,
  helpers,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import cpfIsValid from "../../../validators/cpf";
import { isLegalAge, isValidDate } from "../../../validators/date";
import {
  dateToString,
  stringToInteger,
  onlyDigits,
} from "../../../helpers/converters";
import { getCustomerInfoPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "../../shared/store/types";
import CommentsSection from "./CommentsSection";
import Spinner from "../../shared/components/Spinner";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      name: "",
      gender: "",
      email: "",
      legalType: "",
      cpf: "",
      rg: "",
      rgExpeditionDate: "",
      maritalStatus: "",
      cellPhone: "+55 ",
      cellPhoneMask: "+## ## # #### ####",
      phone: "",
      birthDate: "",
      occupation: "",
      singleLineAddress: "",
      addressCep: "",
      addressState: "",
      country: "BR",
      partnerCountry: "",
      addressCity: "",
      addressDistrict: "",
      addressStreet: "",
      addressNumber: "",
      addressComplement: "",
      consultantId: "",
      hasPartner: false,
      partnerSingleLineAddress: "",
      partnerName: "",
      partnerGender: "",
      partnerEmail: "",
      partnerCpf: "",
      partnerRg: "",
      partnerCellPhone: "",
      partnerCellPhoneMask: "+## ## # #### ####",
      partnerPhone: "",
      partnerBirthDate: "",
      partnerOccupation: "",
      partnerAddressCep: "",
      partnerAddressState: "",
      partnerAddressCity: "",
      partnerAddressDistrict: "",
      partnerAddressStreet: "",
      partnerAddressNumber: "",
      partnerAddressComplement: "",
      children: [],
      pets: [],
      token: undefined,
      tempPayload: "",
      loading: false,
      cellPhoneDuplicated: false,
      duplicateEmail: false,
      emailTakenByConsultant: false
    };
  },
  validations() {
    if (this.country != "BR") {
      return {
        name: { required },
        gender: {},
        email: { required, email },
        legalType: { required },
        cpf: {
          required,
          valid(value) {
            return cpfIsValid(value);
          },
        },
        rg: { required },
        rgExpeditionDate: {
          minLength: minLength(10),
          isValidDate(value) {
            return isValidDate(value);
          },
        },
        maritalStatus: { required },
        cellPhone: {
          required,
          minLength(value) {
            return value.length >= 9;
          },
          maxLength(value) {
            return value.length <= 20;
          },
        },
        phone: {
          minLength: minLength(9),
        },
        birthDate: {
          required,
          minLength: minLength(10),
          isLegalAge(value) {
            return isLegalAge(value);
          },
        },
        occupation: {},
        country: { required },
        addressCep: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
          minLength: minLength(0),
        },
        addressState: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
        },
        addressCity: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
        },
        addressStreet: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
        },
        addressDistrict: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
        },
        singleLineAddress: {
          required: requiredIf(function () {
            return this.country != "BR";
          }),
        },
        addressNumber: {
          required: requiredIf(function () {
            return this.country == "BR";
          }),
        },
        addressComplement: {},
        consultantId: {},
        partnerName: {
          required: requiredIf(function () {
            return this.hasPartner;
          }),
        },
        partnerEmail: { email },
        partnerCpf: {
          valid(value) {
            return !this.hasPartner || !value || cpfIsValid(value);
          },
        },
        partnerRg: {},
        partnerCellPhone: {
          minLength(value) {
            return !this.hasPartner || !value || value.length >= 9;
          },
          maxLength(value) {
            return !this.hasPartner || !value || value.length <= 20;
          },
        },
        partnerPhone: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 16;
          },
        },
        partnerBirthDate: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 10;
          },
        },
        partnerOccupation: {},
        partnerAddressCep: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 10;
          },
        },
        partnerAddressState: {},
        partnerAddressCity: {},
        partnerAddressStreet: {},
        partnerCountry: {},
        partnerSingleLineAddress: {},
        partnerAddressDistrict: {},
        partnerAddressNumber: {},
        partnerAddressComplement: {},
        partnerGender: {},
        children: {
          $each: helpers.forEach({
            name: {
              required,
            },
            gender: {},
            age: {},
            birth_date: {},
          }),
        },
        pets: {
          $each: helpers.forEach({
            name: {
              required,
            },
            breed: {},
            age: {},
            birth_date: {},
          }),
        },
      };
    } else {
      return {
        name: { required },
        gender: {},
        email: { required, email },
        legalType: { required },
        cpf: {
          required,
          valid(value) {
            return cpfIsValid(value);
          },
        },
        rg: { required },
        rgExpeditionDate: {
          minLength: minLength(10),
          isValidDate(value) {
            return isValidDate(value);
          },
        },
        maritalStatus: { required },
        cellPhone: {
          required,
          minLength(value) {
            if (value.length > 2 && value.substr(1, 2) == "55") {
              return value.length === 17;
            } else {
              return value.length >= 9;
            }
          },
          maxLength(value) {
            return value.length <= 20;
          },
        },
        phone: {
          minLength: minLength(16),
        },
        birthDate: {
          required,
          minLength: minLength(10),
          isLegalAge(value) {
            return isLegalAge(value);
          },
        },
        occupation: {},
        addressCep: {
          required,
          minLength: minLength(10),
        },
        singleLineAddress: {
          required: requiredIf(function () {
            return this.country != "BR";
          }),
        },
        addressState: { required },
        addressCity: { required },
        addressStreet: { required },
        country: { required },
        addressDistrict: { required },
        addressNumber: { required },
        addressComplement: {},
        consultantId: {},
        partnerName: {
          required: requiredIf(function () {
            return this.hasPartner;
          }),
        },
        partnerEmail: { email },
        partnerCpf: {
          valid(value) {
            return !this.hasPartner || !value || cpfIsValid(value);
          },
        },
        partnerRg: {},
        partnerCellPhone: {
          minLength(value) {
            if (!this.hasPartner || !value) {
              return true;
            } else if (value.length > 2 && value.substr(1, 2) == "55") {
              return value.length === 17;
            } else {
              return value.length >= 9;
            }
          },
          maxLength(value) {
            return value.length <= 20;
          },
        },
        partnerPhone: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 16;
          },
        },
        partnerBirthDate: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 10;
          },
        },
        partnerOccupation: {},
        partnerAddressCep: {
          minLength(value) {
            return !this.hasPartner || !value || value.length === 10;
          },
        },
        partnerAddressState: {},
        partnerAddressCity: {},
        partnerAddressStreet: {},
        partnerAddressDistrict: {},
        partnerAddressNumber: {},
        partnerAddressComplement: {},
        partnerGender: {},
        children: {
          $each: helpers.forEach({
            name: {
              required,
            },
            gender: {},
            age: {},
            birth_date: {},
          }),
        },
        pets: {
          $each: helpers.forEach({
            name: {
              required,
            },
            breed: {},
            age: {},
            birth_date: {},
          }),
        },
      };
    }
  },
  computed: {
    ...mapGetters({
      legalTypes: sharedTypes.LEGAL_TYPES,
      maritalStatuses: sharedTypes.MARITAL_STATUSES,
      addressStates: sharedTypes.ADDRESS_STATES,
      countriesList: sharedTypes.COUNTRIES_LIST,
      genders: sharedTypes.GENDERS,
    }),
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },

  methods: {
    ...mapActions({
      getLegalTypes: sharedTypes.GET_LEGAL_TYPES,
      getMaritalStatuses: sharedTypes.GET_MARITAL_STATUSES,
      getAddressStates: sharedTypes.GET_ADDRESS_STATES,
      getCountriesList: sharedTypes.GET_COUNTRIES_LIST,
      getGenders: sharedTypes.GET_GENDERS,
      getCustomerInfo: types.GET_CUSTOMER_INFO,
      autoSaveCustomerInfo: types.AUTO_SAVE_CUSTOMER_INFO,
      searchCepAddress: sharedTypes.GET_CEP,
      checkDuplicateCustomerPhone: sharedTypes.CHECK_DUPLICATE_CUSTOMER_PHONE,
      checkDuplicateEmail: sharedTypes.CHECK_DUPLICATE_EMAIL,
    }),
    ...mapMutations({
      mutateCustomerInfo: types.MUTATE_CUSTOMER_INFO,
    }),
    async fetchCustomerInfo() {
      this.loading = true;
      try {
        const response = await this.getCustomerInfo(
          this.$route.params.customerId
        );
        const customer = response.data;
        this.name = customer.name;
        this.gender = customer.gender;
        this.email = customer.email;
        this.legalType = customer.person_type;
        this.cpf = customer.cpf;
        this.rg = customer.rg;
        this.rgExpeditionDate = dateToString(customer.rg_expedition_date);
        this.maritalStatus = customer.marital_status;
        this.cellPhone = "+" + (customer.cell_phone ?? "55");
        this.phone = customer.phone;
        this.birthDate = dateToString(customer.birth_date);
        this.occupation = customer.occupation;
        this.addressCep = customer.address_cep;
        this.addressState = customer.address_state;
        this.country = customer.country || "BR";
        this.addressCity = customer.address_city;
        this.singleLineAddress = customer.single_line_address;
        this.addressDistrict = customer.address_district;
        this.addressStreet = customer.address_street;
        this.addressNumber = customer.address_number;
        this.addressComplement = customer.address_complement;
        this.consultantId = customer.consultant_id;
        this.hasPartner = customer.has_partner;
        this.partnerName = customer.partner_name;
        this.partnerGender = customer.partner_gender;
        this.partnerEmail = customer.partner_email;
        this.partnerCpf = customer.partner_cpf;
        this.partnerRg = customer.partner_rg;
        this.partnerCellPhone = "+" + customer.partner_cell_phone || "+55 ";
        this.partnerPhone = customer.partner_phone;
        this.partnerBirthDate = dateToString(customer.partner_birth_date);
        this.partnerOccupation = customer.partner_occupation;
        this.partnerAddressCep = customer.partner_address_cep;
        this.partnerAddressState = customer.partner_address_state;
        this.partnerCountry = customer.partner_country;
        this.partnerSingleLineAddress = customer.partner_single_line_address;
        this.partnerAddressCity = customer.partner_address_city;
        this.partnerAddressDistrict = customer.partner_address_district;
        this.partnerAddressStreet = customer.partner_address_street;
        this.partnerAddressNumber = customer.partner_address_number;
        this.partnerAddressComplement = customer.partner_address_complement;
        this.children = customer.customer_children.map((child) => ({
          name: child.name || "",
          gender: child.gender || "",
          age: stringToInteger(child.age),
          birth_date: dateToString(child.birth_date) || "",
        }));
        this.pets = customer.customer_pets.map((pet) => ({
          name: pet.name || "",
          breed: pet.breed || "",
          age: stringToInteger(pet.age),
          birth_date: dateToString(pet.birth_date) || "",
        }));

        const payload = getCustomerInfoPayload(this);
        this.mutateCustomerInfo(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter os dados pessoais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
        this.formatCellPhone();
        this.formatPartnerCellPhone();
      }
    },
    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([
          this.fetchCustomerInfo(),
          this.getLegalTypes(),
          this.getMaritalStatuses(),
          this.getAddressStates(),
          this.getCountriesList(),
          this.getGenders(),
        ]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    addChild() {
      this.children.push({
        name: "",
        gender: "",
        age: "",
        birth_date: "",
      });
    },
    removeChild(i) {
      this.children.splice(i, 1);
      this.preSave();
    },
    addPet() {
      this.pets.push({
        name: "",
        breed: "",
        age: "",
        birth_date: "",
      });
    },
    removePet(i) {
      this.pets.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload = getCustomerInfoPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateCustomerInfo(payload);
        this.checkEmail()
        this.autoSave(payload);
      }
      if(!this.isInterview){
        this.checkCellPhone()
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveCustomerInfo(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os dados pessoais do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchCep(event) {
      const cep = event.target.value;
      if (cep.length === 10) {
        this.loading = true;
        this.searchCepAddress(onlyDigits(cep))
          .then((response) => {
            this.addressStreet = response.data.logradouro;
            this.addressDistrict = response.data.bairro;
            this.addressCity = response.data.localidade;
            this.addressState = response.data.uf;
            this.preSave();
          })
          .catch((error) => {
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "Não foi possível encontrar o endereço com o CEP informado.",
                "Entre em contato com o time de TI antes de prosseguir com a operação.",
              ],
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    searchPartnerCep(event) {
      const cep = event.target.value;
      if (cep.length === 10) {
        this.loading = true;
        this.searchCepAddress(onlyDigits(cep))

          .then((response) => {
            this.partnerAddressStreet = response.data.logradouro;
            this.partnerAddressDistrict = response.data.bairro;
            this.partnerAddressCity = response.data.localidade;
            this.partnerAddressState = response.data.uf;
            this.preSave();
          })
          .catch((error) => {
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "Não foi possível encontrar o endereço com o CEP informado.",
                "Entre em contato com o time de TI antes de prosseguir com a operação.",
              ],
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    formatCellPhone() {
      if (this.cellPhone.length > 2 && this.cellPhone.substr(1, 2) != "55") {
        this.cellPhoneMask = "+##################";
      } else {
        this.cellPhoneMask = "+## ## # #### ####";
      }
    },
    formatPartnerCellPhone() {
      if (
        this.partnerCellPhone &&
        this.partnerCellPhone.length > 2 &&
        this.partnerCellPhone.substr(1, 2) != "55"
      ) {
        this.partnerCellPhoneMask = "+##################";
      } else {
        this.partnerCellPhoneMask = "+## ## # #### ####";
      }
    },
    async checkCellPhone() {
      var formatedPhoneNumber = this.cellPhone
        .replace("+", "")
        .replaceAll(" ", "");
      const lengthCheck = formatedPhoneNumber.length >= 13;
      if (lengthCheck) {
        this.checkDuplicateCustomerPhone({
          phone: formatedPhoneNumber,
          customerId: this.$route.params.customerId
        })
          .catch((error) => {
            this.cellPhoneDuplicated = true
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "O telefone celular informado já pertence a um cliente futuro.",
              ],
            });
          });
      }
    },
    async checkEmail() {
      this.duplicateEmail = false;
      this.emailTakenByConsultant = false;

      const email = this.email;
      const emailStringReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailStringCheck = emailStringReg.test(email);

      if (emailStringCheck) {
        this.checkDuplicateEmail({
          email: email,
          customerId: this.$route.params.customerId
        })
        .catch((error) => {
          if (error.response.status == 400) {
            this.duplicateEmail = true;
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "O e-mail informado já pertence a um cliente futuro.",
              ],
            });
          } else {
            this.emailTakenByConsultant = true;
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "O e-mail informado já pertence a um consultor futuro.",
              ],
            });
          }
        })
      }
    },
  },
};
</script>
