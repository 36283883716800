<template>
  <form
    v-if="isInterview"
    id="futuro-customer-patrimony"
    class="my-5"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-home.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Patrimônio</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row align-items-center mb-3 mt-2 px-3 g-3">
        <template v-if="!isDetail">
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="addVehicle"
          >
            Add veículo
          </button>
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="addProperty"
          >
            Add imóvel
          </button>
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="addAsset"
          >
            Add bem
          </button>
        </template>
      </div>

      <!-- veículo -->
      <div v-if="vehicles.length > 0" class="card-title mt-3 mb-3">
        <h2>Veículos</h2>
      </div>
      <div
        v-for="(vehicle, index) in vehicles"
        :key="`vehicle-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`vehicleBrand-${index}`" class="form-label">
              Marca
            </label>
            <input
              :id="`vehicleBrand-${index}`"
              v-model="vehicle.brand"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-6 col-12">
            <label :for="`vehicleModel-${index}`" class="form-label">
              Modelo
            </label>
            <input
              :id="`vehicleModel-${index}`"
              v-model="vehicle.model"
              :disabled="isDetail"
              type="text"
              class="form-control"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`vehicleYear-${index}`" class="form-label">
              Ano
            </label>
            <input
              :id="`vehicleYear-${index}`"
              v-model="vehicle.year"
              type="number"
              class="form-control"
              :disabled="isDetail"
              :class="{
                'is-invalid':
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].year.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].year.length
                "
              >
                Digite um ano válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`vehicleValueFipe-${index}`" class="form-label">
              Valor FIPE
            </label>
            <input
              :id="`vehicleValueFipe-${index}`"
              v-model.lazy="vehicle.valueFipe"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].valueFipe.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].valueFipe.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`vehiclePaidOff-${index}`" class="form-label">
              O veículo está quitado?
            </label>
            <select
              :id="`vehiclePaidOff-${index}`"
              v-model="vehicle.paidOff"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`vehicleHaveInsurance-${index}`" class="form-label">
              Possui seguro?
            </label>
            <select
              :id="`vehicleHaveInsurance-${index}`"
              v-model="vehicle.haveInsurance"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
          <div v-if="vehicle.haveInsurance === 'yes'" class="col-md-4 col-12">
            <label :for="`vehicleAnnualAward-${index}`" class="form-label">
              Prêmio anual
            </label>
            <input
              :id="`vehicleAnnualAward-${index}`"
              v-model.lazy="vehicle.annualAward"
              v-money="moneyFormat"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{
                'is-invalid':
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].annualAward.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].annualAward.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div v-if="vehicle.haveInsurance === 'yes'" class="col-md-4 col-12">
            <label :for="`vehicleDueDate-${index}`" class="form-label">
              Vencimento
            </label>
            <input
              :id="`vehicleDueDate-${index}`"
              v-model="vehicle.dueDate"
              v-mask="'##/##/####'"
              type="tel"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].dueDate.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.vehicles.$dirty &&
                  v$.vehicles.$each.$response.$errors[index].dueDate.length
                "
              >
                Você deve informar uma data válida
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button
                class="btn btn-red"
                @click.prevent="removeItem(vehicles, index)"
              >
                Excluir veículo
              </button>
            </template>
          </div>
        </div>
      </div>

      <!-- IMÓVEL -->
      <div v-if="properties.length > 0" class="card-title mt-3 mb-3">
        <h2>Imóveis</h2>
      </div>
      <div
        v-for="(property, index) in properties"
        :key="`property-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`propertyLocal-${index}`" class="form-label">
              Local
            </label>
            <input
              :id="`propertyLocal-${index}`"
              v-model="property.local"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-6 col-12">
            <label :for="`propertyType-${index}`" class="form-label">
              Tipo
            </label>
            <input
              :id="`propertyType-${index}`"
              v-model="property.type"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`propertyNDorms-${index}`" class="form-label">
              Número de dormitórios
            </label>
            <input
              :id="`propertyNDorms-${index}`"
              v-model="property.nDorms"
              type="number"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-4 col-12">
            <label :for="`propertyPrice-${index}`" class="form-label">
              Valor do bem
            </label>
            <input
              :id="`propertyPrice-${index}`"
              v-model.lazy="property.price"
              v-money="moneyFormat"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].price.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].price.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`propertyPaidOff-${index}`" class="form-label">
              O imóvel está quitado?
            </label>
            <select
              :id="`propertyPaidOff-${index}`"
              v-model="property.paidOff"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              :disabled="isDetail"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`propertyHaveInsurance-${index}`" class="form-label">
              Possui seguro?
            </label>
            <select
              :id="`propertyHaveInsurance-${index}`"
              v-model="property.haveInsurance"
              :disabled="isDetail"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
          <div v-if="property.haveInsurance === 'yes'" class="col-md-4 col-12">
            <label :for="`propertyAnnualAward-${index}`" class="form-label">
              Prêmio anual
            </label>
            <input
              :id="`propertyAnnualAward-${index}`"
              v-model.lazy="property.annualAward"
              v-money="moneyFormat"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].annualAward
                    .length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].annualAward
                    .length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div v-if="property.haveInsurance === 'yes'" class="col-md-4 col-12">
            <label :for="`propertyDueDate-${index}`" class="form-label">
              Vencimento
            </label>
            <input
              :id="`propertyDueDate-${index}`"
              v-model="property.dueDate"
              v-mask="'##/##/####'"
              type="tel"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].dueDate.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.properties.$dirty &&
                  v$.properties.$each.$response.$errors[index].dueDate.length
                "
              >
                Você deve informar uma data válida
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button
                class="btn btn-red"
                @click.prevent="removeItem(properties, index)"
              >
                Excluir imóvel
              </button>
            </template>
          </div>
        </div>
      </div>

      <!-- Bem -->
      <div v-if="assets.length > 0" class="card-title mt-3 mb-3">
        <h2>Bens</h2>
      </div>
      <div
        v-for="(asset, index) in assets"
        :key="`asset-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label :for="`assetName-${index}`" class="form-label"> Nome </label>
            <input
              :id="`assetName-${index}`"
              v-model="asset.name"
              type="text"
              :disabled="isDetail"
              class="form-control"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-4 col-12">
            <label :for="`assetPrice-${index}`" class="form-label">
              Valor do bem
            </label>
            <input
              :id="`assetPrice-${index}`"
              v-model.lazy="asset.price"
              v-money="moneyFormat"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{
                'is-invalid':
                  v$.assets.$dirty &&
                  v$.assets.$each.$response.$errors[index].price.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.assets.$dirty &&
                  v$.assets.$each.$response.$errors[index].price.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`assetPaidOff-${index}`" class="form-label">
              O bem está quitado?
            </label>
            <select
              :id="`assetPaidOff-${index}`"
              v-model="asset.paidOff"
              :disabled="isDetail"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-12">
            <label :for="`assetDescription-${index}`" class="form-label">
              Descrição
            </label>
            <textarea
              :id="`assetDescription-${index}`"
              v-model="asset.description"
              type="text"
              class="form-control"
              :disabled="isDetail"
              rows="4"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button
                class="btn btn-red"
                @click.prevent="removeItem(assets, index)"
              >
                Excluir bem
              </button>
            </template>
          </div>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getBRLFormat } from "../../../helpers/formatting";
import { isYear } from "../../../validators/string";
import {
  dateToString,
  floatToMoney,
  booleanToString,
  integerToString,
} from "../../../helpers/converters";
import { getPatrimonyPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      assets: [],
      properties: [],
      vehicles: [],
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      vehicles: {
        $each: helpers.forEach({
          brand: {},
          model: {},
          year: {
            isYear(value) {
              return isYear(value);
            },
          },
          valueFipe: {
            minLength: minLength(7),
          },
          haveInsurance: {},
          annualAward: {
            minLength: minLength(7),
          },
          dueDate: {
            minLength: minLength(10),
          },
          paidOff: {},
        }),
      },
      properties: {
        $each: helpers.forEach({
          local: {},
          price: {
            minLength: minLength(7),
          },
          type: {},
          nDorms: {},
          haveInsurance: {},
          annualAward: {
            minLength: minLength(7),
          },
          dueDate: {
            minLength: minLength(10),
          },
          paidOff: {},
        }),
      },
      assets: {
        $each: helpers.forEach({
          name: {},
          description: {},
          price: {
            minLength: minLength(7),
          },
          paidOff: {},
        }),
      },
    };
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getPatrimony: types.GET_PATRIMONY,
      autoSavePatrimony: types.AUTO_SAVE_PATRIMONY,
    }),
    ...mapMutations({
      mutatePatrimony: types.MUTATE_PATRIMONY,
    }),
    async fetchPatrimony() {
      this.loading = true;
      try {
        const response = await this.getPatrimony(this.$route.params.customerId);
        const { vehicles, properties, assets } = response.data;

        this.vehicles = vehicles.map((vehicle) => {
          const {
            brand,
            model,
            year,
            value_fipe,
            have_insurance,
            annual_award,
            due_date,
            paid_off,
          } = vehicle;
          return {
            brand,
            model,
            year: integerToString(year),
            valueFipe: floatToMoney(value_fipe),
            haveInsurance: booleanToString(have_insurance),
            annualAward: floatToMoney(annual_award),
            dueDate: dateToString(due_date),
            paidOff: booleanToString(paid_off),
          };
        });

        this.properties = properties.map((property) => {
          const {
            local,
            price,
            type,
            n_dorms,
            have_insurance,
            annual_award,
            due_date,
            paid_off,
          } = property;
          return {
            local,
            price: floatToMoney(price),
            type,
            nDorms: n_dorms,
            haveInsurance: booleanToString(have_insurance),
            annualAward: floatToMoney(annual_award),
            dueDate: dateToString(due_date),
            paidOff: booleanToString(paid_off),
          };
        });

        this.assets = assets.map((asset) => {
          const { name, description, price, paid_off } = asset;
          return {
            name,
            description,
            price: floatToMoney(price),
            paidOff: booleanToString(paid_off),
          };
        });

        const payload = getPatrimonyPayload(this);
        this.mutatePatrimony(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter o patrimônio do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchPatrimony()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    addVehicle() {
      this.vehicles.push({
        brand: "",
        model: "",
        year: "",
        valueFipe: "",
        haveInsurance: "",
        annualAward: "",
        dueDate: "",
        paidOff: "",
      });
    },
    addProperty() {
      this.properties.push({
        local: "",
        price: "",
        type: "",
        nDorms: "",
        haveInsurance: "",
        annualAward: "",
        dueDate: "",
        paidOff: "",
      });
    },
    addAsset() {
      this.assets.push({
        name: "",
        description: "",
        price: "",
        paidOff: "",
      });
    },
    removeItem(array, i) {
      array.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload = getPatrimonyPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutatePatrimony(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSavePatrimony(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as aquisições do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
