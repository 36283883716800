<template>
  <div>
    <div
      class="btn-group-fab"
      role="group"
      aria-label="FAB Menu"
      :class="{ active: isActive }"
    >
      <div>
        <button
          type="button"
          class="btn btn-main btn-primary has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Menu"
          @click="toggleActive"
        >
          <img
            src="~@/assets/img/menu.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-value')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Proposta"
        >
          <img
            src="~@/assets/img/ic-smile.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-cash')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Fluxo de caixa"
        >
          <img
            src="~@/assets/img/ic-cash-flow.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-additional')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Informações adicionais"
        >
          <img
            src="~@/assets/img/plus-circle.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-investiment')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Investimento"
        >
          <img
            src="~@/assets/img/ic-chart.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-retirement')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Aposentadoria"
        >
          <img
            src="~@/assets/img/ic-retirement.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-debts')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Dívidas"
        >
          <img
            src="~@/assets/img/ic-debt.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-health')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Plano de saúde"
        >
          <img
            src="~@/assets/img/ic-health.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-insurance')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Seguro de vida"
        >
          <img
            src="~@/assets/img/ic-globe.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-patrimony')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Patrimônio"
        >
          <img
            src="~@/assets/img/ic-home.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-acquisition')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Aquisição de bens"
        >
          <img
            src="~@/assets/img/car.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-mounthly')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Investimento mensal"
        >
          <img
            src="~@/assets/img/calendar.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-goals')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Quais seus principais objetivos?"
        >
          <img
            src="~@/assets/img/ic-goals.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>

        <button
          @click="smoothScroll('futuro-customer-info')"
          type="button"
          class="btn btn-sub has-tooltip d-flex align-items-center justify-content-center"
          data-placement="left"
          title="Dados pessoais"
        >
          <img
            src="~@/assets/img/ic-user.svg"
            alt="Futuro | Consultoria Financeira"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    initializeTooltips() {
      const tooltips = document.querySelectorAll(".has-tooltip");
      tooltips.forEach((tooltip) => {
        tooltip.addEventListener("mouseenter", () => {
          const tooltipText = tooltip.getAttribute("title");
          const tooltipElement = document.createElement("div");
          tooltipElement.classList.add("tooltip");
          tooltipElement.textContent = tooltipText;
          document.body.appendChild(tooltipElement);
        });
        tooltip.addEventListener("mouseleave", () => {
          const tooltipElement = document.querySelector(".tooltip");
          if (tooltipElement) {
            tooltipElement.remove();
          }
        });
      });
    },
    smoothScroll(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        const topOffset = section.offsetTop - 90;
        window.scrollTo({
          top: topOffset,
          behavior: "smooth",
        });
      }
    },
  },
  mounted() {
    this.initializeTooltips();
  },
};
</script>

<style lang="scss" scoped>
body {
  width: 100%;
  height: 100%;
}

.btn-group-fab {
  position: fixed;
  width: 50px;
  height: auto;
  right: 20px;
  bottom: 20px;
  z-index: 3;

  div {
    position: relative;
    width: 100%;
    height: auto;
  }

  @media (max-height: 595.98px) {
    display: none;
  }

  .btn {
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    display: block;
    margin-bottom: 4px;
    width: 40px;
    height: 40px;
    margin: 4px auto;
  }

  .btn-main {
    width: 50px;
    height: 50px;
    right: 50%;
    margin-right: -25px;
    z-index: 9;
  }

  .btn-sub {
    bottom: 5px;
    z-index: 8;
    right: 50%;
    margin-right: -20px;
    transition: all 0.5s;

    background-color: #fff;

    img {
      filter: invert(32%) sepia(87%) saturate(3896%) hue-rotate(194deg)
        brightness(101%) contrast(99%);
    }

    @media (max-height: 824px) {
      width: 30px;
      height: 30px;
      padding: 0;
      right: 61.5%;

      img {
        width: 18px;
      }
    }
  }

  &.active {
    @for $i from 2 through 15 {
      .btn-sub:nth-child(#{$i}) {
        bottom: 60px + 50px * ($i - 2);
      }
    }

    @media (max-height: 824px) {
      @for $i from 2 through 15 {
        .btn-sub:nth-child(#{$i}) {
          bottom: 60px + 40px * ($i - 2);
        }
      }
    }
  }
}
</style>
