<template>
 <div class="card my-5">
    <div class="row align-items-center justify-content-between mb-3">
      <div class="card-title col-auto">
        <img
          src="~@/assets/img/ic-contract.svg"
          alt="Futuro | Consultoria Financeira"
        />
        <h2>Proposta comercial</h2>
      </div>
    </div>
    <div class="row justify-content-start mb-3 g-3">
      <div class="col-md-3 col-12">
        <label for="proposalDays" class="form-label">
          Tempo de vigência (dias)
        </label>
        <input
          id="proposalDays"
          v-model="proposalDays"
          :disabled="isDetail"
          type="number"
          max="7"
          min="1"
          class="form-control"
          :class="{
            'is-invalid':
              v$.proposalDays.$dirty &&
              v$.proposalDays.$invalid,
          }"
        />
        <div class="invalid-feedback">
          <span
            v-if="
              v$.proposalDays.$dirty &&
              v$.proposalDays.$invalid
            "
          >
            Você deve informar um tempo válido de até 7 dias
          </span>
        </div>
      </div>
      <div class="col-md-9 col-12 mt-5">
        <button
          :disabled="isLoading || loading"
          class="btn btn-outline-light mx-2 mt-1"
          @click.prevent="saveAndPerformNextStep(confirmSendProposalEmail)"
        >
          {{ (isLoading || loading) ? 
            "Por favor, aguarde um momento..." 
            : "Enviar proposta por e-mail"
          }}
        </button>
        <button
          :disabled="isLoading || loading"
          class="btn btn-outline-light mx-2 mt-1"
          @click.prevent="saveAndPerformNextStep(copyProposalLink)"
        >
          {{ (isLoading || loading) ? 
            "Por favor, aguarde um momento..." 
            : "Copiar link da proposta comercial" 
          }} 
        </button>
        <button
          :disabled="isLoading || loading"
          class="btn btn-whatsapp mx-2 mt-1"
          @click.prevent="saveAndPerformNextStep(sendWhatsappMessage)"
        >
          <img
            src="~@/assets/img/ic-whatsapp-green.svg"
            alt="Compartilhar link por whatsapp"
          />
          {{ (isLoading || loading) ? 
            "Por favor, aguarde um momento..." 
            : "Enviar proposta por whatsapp" 
          }} 
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, minValue, maxValue } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as types from "../store/types";
import Spinner from "../../shared/components/Spinner";
import ConfirmModal from "../../shared/components/ConfirmModal";
import { processFormErrors } from "../../../helpers/interview_payloads";
import { processErrorMessages } from "../../../helpers/errors";
import { commecialProposalWppMessage } from "../../../helpers/messages";

export default {
  components: {
    Spinner,
    ConfirmModal
  },
  data() {
    return {
      loading: false,
      proposalDays: 7
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const proposalUrl = process.env.VUE_APP_COMMERCIAL_PROPOSAL_URL + "/autenticacao/"
    return { proposalUrl, v$: useVuelidate() }
  },
  validations() {
    return {
      proposalDays: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(7),
      }
    }
  },
  computed: {
    ...mapGetters({
      customerInfo: types.CUSTOMER_INFO,
      form: types.INTERVIEW_FORM
    })
  },
  methods: {
    ...mapActions({
      sendCommercialProposalEmail: types.SEND_COMMERCIAL_PROPOSAL_EMAIL,
      getCommercialProposalLink: types.GET_COMMERCIAL_PROPOSAL_LINK,
      saveInterview: types.SAVE_INTERVIEW,
    }),
    ...mapMutations({
      savingInterviewState: types.MUTATE_SAVING_INTERVIEW,
    }),
    async saveAndPerformNextStep(nextStep) {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      const errors = await processFormErrors(this.form);
      if (errors.length > 0) {
        const messages = [
          "Os dados da entrevista não foram atualizados.",
          "Você deve solucionar as inconsistências da entrevista apontadas nas seguintes seções:",
          ...errors,
        ];
        this.$vfm.show("message", {
          type: "error",
          messages,
        });
      } else {
        this.savingInterviewState(true);
        this.saveInterview()
          .then((response) => {
            nextStep()
          })
          .catch((error) => {
            const errorsFromPayload = processErrorMessages(
              error?.response?.data
            );
            const messages = [
              "Não foi possível salvar os dados da entrevista.",
              ...errorsFromPayload,
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ];
            this.$vfm.show("message", {
              type: "error",
              messages,
            });
          })
          .finally(() => {
            this.savingInterviewState(false);
          });
      }
    },
    confirmSendProposalEmail(){
      this.$vfm.show(
        {
          component: ConfirmModal,
        },
        {
          messages: ["Deseja realmente enviar o e-mail ao cliente?"],
          handleConfirm: () => { 
            this.loading = true
            this.sendCommercialProposalEmail({
              customer_token: this.customerInfo.token,
              days: this.proposalDays
            }).then((response) => {
              if(response.data?.authentication_token){
                const link = this.proposalUrl +
                  response.data.authentication_token + "?back-url=proposta/" + this.customerInfo.token
                setTimeout(() => {
                  window.open(
                    link,
                    "_blank"
                  );
                });
                this.$vfm.show("message", {
                  type: "success",
                  messages: [
                    "O e-mail da proposta foi enviado para o cliente!",
                  ],
                });
              }
            }).catch((error) => {
              this.$vfm.show("message", {
                type: "error",
                messages: [
                  "Ocorreu um erro ao gerar a proposta comercial. Entre em contato com o setor de TI.",
                ],
              });
            }).finally(() => {
              this.loading = false
            })
          },
        }
      );
    },
    copyProposalLink(){
      this.loading = true
      this.getCommercialProposalLink({
        customer_token: this.customerInfo.token,
        days: this.proposalDays
      }).then((response) => {
        if(response.data?.authentication_token){
          const link = this.proposalUrl + 
            response.data.authentication_token + "?back-url=proposta/" + this.customerInfo.token
          const clipboardData =
            event.clipboardData ||
            window.clipboardData ||
            event.originalEvent?.clipboardData ||
            navigator.clipboard;
          clipboardData.writeText(link);
          this.$vfm.show("message", {
            type: "success",
            messages: [
              "O link da proposta foi copiado para a área de transferência!",
            ],
          });
        }
      }).catch((error) => {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Ocorreu um erro ao gerar a proposta comercial. Entre em contato com o setor de TI.",
          ],
        });
      })
      .finally(() => {
        this.loading = false
      })
    },
    sendWhatsappMessage(){
      this.loading = true
      this.getCommercialProposalLink({
        customer_token: this.customerInfo.token,
        days: this.proposalDays
      }).then((response) => {
        if(response.data?.authentication_token){
          const link = this.proposalUrl + 
            response.data.authentication_token + "?back-url=proposta/" + this.customerInfo.token
          const message = commecialProposalWppMessage(link, this.proposalDays)
          setTimeout(() => {
            window.open(
              `https://api.whatsapp.com/send?phone=${this.customerInfo.cell_phone}&text=${message}`,
              "_blank"
            );
          })
        }
      }).catch((error) => {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Ocorreu um erro ao gerar a proposta comercial. Entre em contato com o setor de TI.",
          ],
        });
      })
      .finally(() => {
        this.loading = false
      })
      
    }
  },
};
</script>
