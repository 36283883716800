<template>
  <form id="futuro-customer-cash" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-cash-flow.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Fluxo de caixa</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3 align-items-end">
        <div class="col-md-4 col-12">
          <label for="doesAnnualPlanning" class="form-label">
            Você faz planejamento anual dos seus ganhos e gastos?
          </label>
          <select
            id="doesAnnualPlanning"
            v-model="doesAnnualPlanning"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
        <div class="col-md-4 col-12">
          <label for="doesSpendingManagement" class="form-label">
            Você faz controle dos seus gastos?
          </label>
          <select
            id="doesSpendingManagement"
            v-model="doesSpendingManagement"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            :disabled="isDetail"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
        <div class="col-md-4 col-12">
          <label for="hasYearEndBonus" class="form-label">
            Você recebe 13<sup>o</sup> salário?
          </label>
          <select
            id="hasYearEndBonus"
            v-model="hasYearEndBonus"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </select>
        </div>
      </div>

      <div
        v-for="(category, categoryIndex) in categories"
        :key="category.key"
        class="row mb-4 pb-2 mx-2 bottom-line"
      >
        <div class="col-12">
          <div class="row align-items-center mb-3">
            <div class="col-md-6 col-12">
              <span class="category">{{ category.title }}</span>
            </div>
            <div class="col-md-4 col-12">
              <input
                v-model.lazy="category.value"
                v-money="moneyFormat"
                type="text"
                class="form-control"
                :disabled="isDetail"
                :class="{
                  'is-invalid':
                    v$.categories.$dirty &&
                    v$.categories.$each.$response.$errors[categoryIndex].value
                      .length,
                }"
                @blur="preSave()"
              />
              <div class="invalid-feedback">
                <span
                  v-if="
                    v$.categories.$dirty &&
                    v$.categories.$each.$response.$errors[categoryIndex].value
                      .length
                  "
                >
                  Digite um valor válido
                </span>
              </div>
            </div>
          </div>
          <template v-if="isInterview">
            <div
              v-for="(subCategory, subCategoryIndex) in category.subCategories"
              :key="subCategory.key"
              class="row align-items-center mb-3"
            >
              <div
                v-if="isCustomSubCategory(subCategory)"
                class="col-md-6 col-12"
              >
                <input
                  v-model.lazy="subCategory.title"
                  type="text"
                  :disabled="isDetail"
                  class="form-control"
                  @blur="preSave()"
                  @keydown.enter.prevent=""
                />
              </div>
              <div v-else class="col-md-6 col-12">
                <span>{{ subCategory.title }}</span>
              </div>
              <div class="col-md-4 col-6 campos-texto">
                <input
                  v-model.lazy="subCategory.value"
                  v-money="moneyFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.categories.$dirty &&
                      v$.categories.$each.$response.$data[categoryIndex]
                        .subCategories.$each.$errors[subCategoryIndex].value
                        .length,
                  }"
                  @blur="
                    sumSubCategoryValues(categoryIndex);
                    preSave();
                  "
                  @keydown.enter.prevent=""
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.categories.$dirty &&
                      v$.categories.$each.$response.$data[categoryIndex]
                        .subCategories.$each.$errors[subCategoryIndex].value
                        .length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <template v-if="!isDetail">
                  <button
                    v-if="isCustomSubCategory(subCategory)"
                    class="btn btn-red"
                    @click.prevent="
                      removeSubCategory(categoryIndex, subCategoryIndex)
                    "
                  >
                    Excluir
                  </button>
                </template>
              </div>
            </div>

            <div class="row align-items-center mb-3 mt-2 px-3 g-3">
              <template v-if="!isDetail">
                <button
                  class="btn btn-orange col-auto mx-1"
                  @click.prevent="addSubCategory(categoryIndex)"
                >
                  Add subcategoria
                </button>
              </template>
            </div>
          </template>
        </div>
      </div>
      <template
        v-if="
          cashFlowCalculation &&
          cashFlowCalculation.resume_month &&
          cashFlowCalculation.resume_year &&
          cashFlowCalculation.current_chart
        "
      >
        <div class="row align-items-center justify-content-around mb-3">
          <span class="category">Resumo</span>
          <spinner v-if="calculating" class="mr-2" />
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador" />
                <span><b>Mensal</b></span>
              </div>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador renda-liquida" />
                <span>Renda líquida:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.total_income || 0
                )
              }}</span>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador despesas-totais" />
                <span>Despesas totais:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.total_expenses || 0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador gastos-fixos" />
                <span>Gastos fixos:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.total_fixed_expenses || 0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador gastos-variaveis" />
                <span>Gastos variáveis:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.total_variable_expenses ||
                    0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador investimentos" />
                <span>Investimentos:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.total_investments || 0
                )
              }}</span>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <span>Saldo:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_month?.balance || 0
                )
              }}</span>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador" />
                <span><b>Anual</b></span>
              </div>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador renda-liquida" />
                <span>Renda líquida:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.total_income || 0
                )
              }}</span>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <div class="indicador despesas-totais" />
                <span>Despesas totais:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.total_expenses || 0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador gastos-fixos" />
                <span>Gastos fixos:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.total_fixed_expenses || 0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador gastos-variaveis" />
                <span>Gastos variáveis:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.total_variable_expenses || 0
                )
              }}</span>
            </div>
            <div class="indicador-row ms-4">
              <div class="indicador-label">
                <div class="indicador investimentos" />
                <span>Investimentos:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.total_investments || 0
                )
              }}</span>
            </div>
            <div class="indicador-row">
              <div class="indicador-label">
                <span>Saldo:</span>
              </div>
              <span>{{
                $filters.toCurrency(
                  cashFlowCalculation?.resume_year?.balance || 0
                )
              }}</span>
            </div>
          </div>
        </div>
        <div class="row mb-3 gy-3 container-chart">
          <div class="col-md-6 col-12 px-md-2">
            <div class="container-chart--main">
              <div class="row mb-3 gx-0 gy-3 container-chart--box">
                <div class="col-12 mb-3 container-chart--title">
                  <span class="category">Gráfico de gastos atual</span>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between flex-wrap"
                >
                  <div class="col-md-5 col-12">
                    <cash-flow-chart
                      chartId="my-chart-1"
                      :gasto-fixo="actualOfFixed"
                      :gasto-variavel="actualOfVariable"
                      :investimentos="actualOfInvestiments"
                      :totais="totalIncome"
                    />
                  </div>
                  <div class="col-md-6 col-12 container-chart--data">
                    <div class="container-chart--data_box">
                      <div class="container-chart--data_title d-flex">
                        <div class="line-fixo"></div>
                        <div class="w-100">
                          <small>Gasto Fixo</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{ actualOfFixed }}%
                            </h5>
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{
                                $filters.toCurrency(
                                  cashFlowCalculation?.resume_month
                                    ?.total_fixed_expenses || 0
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-chart--data_box my-2">
                      <div class="container-chart--data_title d-flex">
                        <div class="line-variavel"></div>
                        <div class="w-100">
                          <small>Gasto Variável</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{ actualOfVariable }}%
                            </h5>
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{
                                $filters.toCurrency(
                                  cashFlowCalculation?.resume_month
                                    ?.total_variable_expenses || 0
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-chart--data_box my-2">
                      <div class="container-chart--data_title d-flex no-border">
                        <div class="line-investimentos"></div>
                        <div class="w-100">
                          <small>Investimentos</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{ actualOfInvestiments }}%
                            </h5>
                            <h5 :class="balanceValue < 0 ? 'danger' : ''">
                              {{
                                $filters.toCurrency(
                                  cashFlowCalculation?.resume_month
                                    ?.total_investments || 0
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12 px-md-2">
            <div class="container-chart--main">
              <div class="row mb-3 gx-0 gy-3 container-chart--box">
                <div class="col-12 mb-3 container-chart--title">
                  <span class="category">Gráfico de gastos ideal</span>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between flex-wrap"
                >
                  <div class="col-md-5 col-12">
                    <cash-flow-chart
                      chartId="my-chart-2"
                      :gasto-fixo="idealOfFixed"
                      :gasto-variavel="idealOfVariables"
                      :investimentos="idealOfInvestiments"
                      :totais="totalIncome"
                    />
                  </div>
                  <div class="col-md-6 col-12 container-chart--data">
                    <div class="container-chart--data_box">
                      <div class="container-chart--data_title d-flex">
                        <div class="line-fixo"></div>
                        <div class="w-100">
                          <small>Gasto Fixo</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5>{{ idealOfFixed }}%</h5>
                            <h5>
                              {{ $filters.toCurrency(calculateIdealOfFixed) }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-chart--data_box my-2">
                      <div class="container-chart--data_title d-flex">
                        <div class="line-variavel"></div>
                        <div class="w-100">
                          <small>Gasto Variável</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5>{{ idealOfVariables }}%</h5>
                            <h5>
                              {{
                                $filters.toCurrency(calculateIdealOfVariable)
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container-chart--data_box my-2">
                      <div class="container-chart--data_title d-flex no-border">
                        <div class="line-investimentos"></div>
                        <div class="w-100">
                          <small>Investimentos</small>
                          <div
                            class="container-chart--data_numbers d-flex justify-content-between"
                          >
                            <h5>{{ idealOfInvestiments }}%</h5>
                            <h5>
                              {{
                                $filters.toCurrency(
                                  calculateIdealOfInvestiments
                                )
                              }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import {
  floatToMoney,
  moneyToFloat,
  booleanToString,
} from "../../../helpers/converters";
import { getCashFlowPayload } from "../../../helpers/interview_payloads";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getBRLFormat } from "../../../helpers/formatting";
import * as types from "../store/types";
import Spinner from "../../shared/components/Spinner";
import CashFlowChart from "./CashFlowChart";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CashFlowChart,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      doesAnnualPlanning: "",
      doesSpendingManagement: "",
      hasYearEndBonus: "",
      categories: [],
      token: "",
      loading: false,
      calculating: false,
    };
  },
  validations() {
    return {
      doesAnnualPlanning: {},
      doesSpendingManagement: {},
      hasYearEndBonus: {},
      categories: {
        $each: helpers.forEach({
          title: {},
          category: {},
          value: {
            minLength: minLength(7),
          },
          subCategories: {
            $each: helpers.forEach({
              title: {},
              category: {},
              categorySub: {},
              value: {
                minLength: minLength(7),
              },
            }),
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      cashFlowCategories: types.CASH_FLOW_CATEGORIES,
      cashFlow: types.CASH_FLOW,
      cashFlowCalculation: types.CASH_FLOW_CALCULATION,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },

    actualOfFixed: function () {
      return this.cashFlowCalculation?.current_chart?.fixed_expenses || 0;
    },
    actualOfVariable: function () {
      return this.cashFlowCalculation?.current_chart?.variable_expenses || 0;
    },
    actualOfInvestiments: function () {
      return this.cashFlowCalculation?.current_chart?.investments || 0;
    },
    idealOfFixed: function () {
      return this.cashFlowCalculation?.ideal_chart?.fixed_expenses || 0;
    },
    idealOfVariables: function () {
      return this.cashFlowCalculation?.ideal_chart?.variable_expenses || 0;
    },
    idealOfInvestiments: function () {
      return this.cashFlowCalculation?.ideal_chart?.investments || 0;
    },
    balanceValue: function () {
      return this.cashFlowCalculation?.resume_month?.balance || 0;
    },
    totalIncome: function () {
      return this.cashFlowCalculation?.resume_month?.total_income || 0;
    },
    calculateIdealOfFixed: function () {
      const ideal = this.idealOfFixed / 100;
      const total = this.totalIncome;
      const fixed = total * ideal;
      return fixed || 0;
    },
    calculateIdealOfVariable: function () {
      const ideal = this.idealOfVariables / 100;
      const total = this.totalIncome;
      const fixed = total * ideal;
      return fixed || 0;
    },
    calculateIdealOfInvestiments: function () {
      const ideal = this.idealOfInvestiments / 100;
      const total = this.totalIncome;
      const fixed = total * ideal;
      return fixed || 0;
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
    // await this.calculate();
  },

  methods: {
    ...mapActions({
      getCategories: types.GET_CASH_FLOW_CATEGORIES,
      getCashFlow: types.GET_CASH_FLOW,
      autoSaveCashFlow: types.AUTO_SAVE_CASH_FLOW,
      calculateCashFlow: types.CALCULATE_CASH_FLOW,
      recalculateProposal: types.CALCULATE_PROPOSAL,
    }),
    ...mapMutations({
      mutateCashFlow: types.MUTATE_CASH_FLOW,
      changeLoadingState: types.MUTATE_INTERVIEW_CASH_FLOW_LOADING,
    }),
    async fetchCategories() {
      this.loading = true;
      this.changeLoadingState(true);
      try {
        await this.getCategories();
        try {
          const response = await this.getCashFlow(
            this.$route.params.customerId
          );
          const {
            does_anual_planning,
            does_spending_management,
            has_year_end_bonus,
            categories,
          } = response.data;
          this.doesAnnualPlanning = booleanToString(does_anual_planning);
          this.doesSpendingManagement = booleanToString(
            does_spending_management
          );
          this.hasYearEndBonus = booleanToString(has_year_end_bonus);
          this.fillCategories(categories);
          const payload = getCashFlowPayload(this);
          this.mutateCashFlow(payload);
        } catch (error) {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter os objetivos do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        } finally {
          this.loading = false;
          this.changeLoadingState(false);
        }
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter o fluxo de caixa do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
        this.changeLoadingState(false);
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchCategories(), this.calculate()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    addSubCategory(index) {
      const category = this.categories[index];
      category.subCategories.push({
        title: "",
        category: category.category,
        categorySub: `sub_category_${category.subCategories.length + 1}`,
        value: "",
      });
      this.preSave();
    },
    removeSubCategory(categoryIndex, subCategoryIndex) {
      this.categories[categoryIndex].subCategories.splice(subCategoryIndex, 1);
      this.preSave();
    },
    preSave() {
      const payload = getCashFlowPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateCashFlow(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      if (!this.loading) {
        this.loading = true;
        this.autoSaveCashFlow(payload)
          .then((response) => {
            this.calculate();
          })
          .catch((error) => {
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "Não foi possível salvar automaticamente o fluxo de caixa do cliente.",
                "Entre em contato com o time de TI antes de prosseguir com a operação.",
              ],
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async calculate() {
      this.calculating = true;
      if (this.isInterview) {
        await this.recalculateProposal(this.token);
      }
      await this.calculateCashFlow(this.token)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter os gráficos do fluxo de caixa do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.calculating = false;
        });
    },
    fillCategories(categories) {
      if (!categories?.length) {
        if (this.cashFlowCategories) {
          this.cashFlowCategories.forEach((cashFlowCategory) => {
            const newCategory = {
              title: cashFlowCategory.title,
              category: cashFlowCategory.key,
              value: "",
              subCategories: [],
            };
            if (cashFlowCategory.sub_categories) {
              cashFlowCategory.sub_categories.forEach((cashFlowSubCategory) => {
                newCategory.subCategories.push({
                  title: cashFlowSubCategory.title,
                  category: cashFlowCategory.key,
                  categorySub: cashFlowSubCategory.key,
                  value: "",
                });
              });
            }
            this.categories.push(newCategory);
          });
        }
      } else {
        categories.forEach((cashFlowCategory) => {
          const newCategory = {
            title: cashFlowCategory.title,
            category: cashFlowCategory.category,
            value: floatToMoney(cashFlowCategory.value),
            subCategories: _.map(
              cashFlowCategory.sub_categories,
              (cashFlowSubCategory) => {
                return {
                  title: cashFlowSubCategory.title,
                  category: cashFlowSubCategory.category,
                  categorySub: cashFlowSubCategory.category_sub,
                  value: floatToMoney(cashFlowSubCategory.value),
                };
              }
            ),
          };
          this.categories.push(newCategory);
        });
      }
    },
    isCustomSubCategory(subCategory) {
      return subCategory.categorySub?.startsWith("sub_category_");
    },
    sumSubCategoryValues(index) {
      const category = this.categories[index];
      if (category.category === "renda") {
        let value_salario_bruto = 0;
        let value_salario_liquido = 0;
        let sum = _.sumBy(category.subCategories, (subCategory) => {
          const value = moneyToFloat(subCategory.value);
          if (subCategory.categorySub === "salario_bruto") {
            value_salario_bruto = value;
          } else if (subCategory.categorySub === "salario_liquido") {
            value_salario_liquido = value;
          }
          return value;
        });
        if (value_salario_bruto && value_salario_liquido) {
          sum -= value_salario_bruto;
        }
        category.value = floatToMoney(sum);
      } else {
        category.value = floatToMoney(
          _.sumBy(category.subCategories, (subCategory) =>
            moneyToFloat(subCategory.value)
          )
        );
      }
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  .campos-texto {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.category {
  font-size: 22px;
  font-weight: 600;
}

.statistics-section {
  border-top: 1px solid #4c6ed5;
}

.bottom-line {
  border-bottom: 1px solid #4c6ed5;
}

.indicador-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.indicador-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.indicador {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 10px;

  &.renda-liquida {
    background-color: #03c276;
  }

  &.gastos-fixos {
    background-color: #b10cf9;
  }

  &.gastos-variaveis {
    background-color: #ff64be;
  }

  &.investimentos {
    background-color: #43c5ff;
  }

  &.despesas-totais {
    background-color: #ff4d4f;
    // background-color: #e6654c;
  }
}

.container-chart {
  // width: 100%;

  &:first-child {
    padding-right: 1rem;
  }

  &--main {
    // width: 100%;
    padding: 20px;
    background-color: #274693;
    border-radius: 10px;
  }

  &--title {
    // width: 100%;
    border-bottom: 1px solid #335099;
    padding: 0 0 15px 0;

    span {
      margin-bottom: 15px;
    }
  }

  &--data_title {
    border-bottom: 1px solid #335099;
    padding: 0 0 10px 0;

    small {
      color: #5971ac;
    }
  }
}

.no-border {
  border-bottom: none !important ;
}

.line-fixo,
.line-variavel,
.line-investimentos {
  width: 8px;
  height: 53px;
  margin-right: 0.75rem;
  border-radius: 10px;
}

.line-fixo {
  background-color: #b10cf9;
}

.line-variavel {
  background-color: #ff64be;
}

.line-investimentos {
  background-color: #43c5ff;
}

.danger {
  color: red;
}
</style>
