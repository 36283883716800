import * as types from './types'

export default {
  [types.MUTATE_CHECKOUT]: (state, payload) => {
    let checkoutData = {}
    checkoutData.contractSigned = payload.contractSigned
    checkoutData.publicKey = payload.publicKey
    if (payload.interview) {
      checkoutData.interview = payload.interview
    }
    if (payload.subscription) {
      checkoutData.subscription = payload.subscription
    }
    state.checkoutData = checkoutData
  },
  [types.MUTATE_INTERVIEW_CHECKOUT_STATUS]: (state, payload) => {
    state.checkoutData.interview.status = payload.status
  }
}
