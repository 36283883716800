export const isYear = (value) => {
  if (value) {
    const str = value.toString()
    return str.length === 4
  }
  if (value === null || value === '') {
    return true
  }
  return false
}
