<template>
  <delete-comment-confirmation-modal />
  <div class="row justify-content-end pt-3 mx-2 comments-section">
    <template v-if="!isDetail">
      <button
        class="btn btn-white-full col-auto"
        @click.prevent="addComment"
      >
        Adicionar comentário
      </button>
    </template>
  </div>
  <div
    v-for="comment in sectionComments"
    :key="comment.id"
    class="row mt-3 mx-1"
  >
    <div class="col-12 comment-card">
      <div class="d-flex align-items-start justify-content-between flex-nowrap">
        <span>
          {{ comment.comment }}
        </span>
        <template v-if="!isDetail">
          <button @click.prevent="deleteComment(comment.id)">
            x
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as types from '../store/types'
import DeleteCommentConfirmationModal from './DeleteCommentConfirmationModal'
export default {
  components: {
    DeleteCommentConfirmationModal
  },
  props: {
    interviewSection: {
      type: String,
      default: ''
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: ''
    }
  },
   data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      comments: types.COMMENTS,
    }),
    sectionComments: function () {
      return this.comments[this.interviewSection] || []
    }
  },
  mounted() {
    this.getSectionComments({
      token: this.token,
      interview_session: this.interviewSection
    })
  },

  methods: {
    ...mapActions({
      getSectionComments: types.GET_SECTION_COMMENTS,
    }),
    addComment() {
      this.$vfm.show('interview-comment', {
        section: this.interviewSection,
        token: this.token
      })
    },
    deleteComment(id) {
      this.$vfm.show(
        {
          component: DeleteCommentConfirmationModal
        },
        {
          messages: [
            'Deseja realmente remover o comentário?',
            'Esta ação não poderá ser desfeita'
          ],
          token: this.token,
          interviewSection: this.interviewSection,
          id
        }
      )
    }
  }
}
</script>

<style lang="scss">
.comments-section {
  border-top: 1px solid #4c6ed5;
}
.error-bg {
  .comments-section {
    border-top: 1px solid white;
  }
}
.comment-card {
  background-color: #FFFEE2;
  color: var(--black-txt);
  border-radius: 10px;
  padding: 5px 15px;
  span, button {
    color: var(--black-txt);
    font-size: 16px;
    font-weight: 300;
  }
  button {
    background-color: #FFFEE2;
  }
}
</style>
