import { createStore } from 'vuex'
import account from '../modules/account/store'
import interview from '../modules/interview/store'
import main from '../modules/main/store'
import shared from '../modules/shared/store'
import checkout from '../modules/checkout/store'
import landing_page from '../modules/landing_page/store'
import nps_survey from '../modules/nps_survey/store'

export const store = createStore({
  modules: {
    account,
    interview,
    checkout,
    landing_page,
    main,
    shared,
    nps_survey
  }
})
