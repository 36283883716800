<template>
  <vue-final-modal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="modal__container"
    content-class="modal__content"
    :resize="true"
    :max-width="400"
  >
    <button class="close" @click="close">
      <img src="~@/assets/img/close.svg" height="30" />
    </button>
    <img src="~@/assets/img/question.png" />
    <span class="title mt-3"> Confirmar operação </span>
    <div class="content mt-3 px-3">
      <div v-for="(message, index) in params.messages" :key="`message${index}`">
        <p v-html="message"></p>
      </div>
    </div>
    <div class="col-12 d-flex align-items-center justify-content-around mt-3">
      <button class="btn btn-red" @click="close" :disabled="isLoading">
        Não
      </button>
      <button
        class="btn btn-blue p-0 d-flex align-items-center justify-content-center"
        @click="confirm(params, close)"
      >
        <div v-if="isLoading" style="margin-right: 0.75rem">
          <spinner />
        </div>
        Sim
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
import Spinner from "./Spinner";

export default {
  name: "ConfirmModal",
  inheritAttrs: false,
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async confirm(params, close) {
      try {
        this.isLoading = true;
        await params.handleConfirm();
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
      close();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.modal__content) {
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  border-radius: 20px;
  background-color: var(--grey);
  color: var(--black-txt);
}
.title {
  font-size: 32px;
  font-weight: 600;
  color: var(--black-txt);
}
.content {
  flex-grow: 1;
  overflow-y: auto;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: normal;
  text-align: center;
}
.close {
  position: absolute;
  background: transparent;
  top: 0.7rem;
  right: 0.7rem;
}
.btn {
  width: 130px;
  height: 40px;
}
</style>
