import * as types from './types'

export default {
  [types.MUTATE_HEADER_TYPE]: (state, payload) => {
    state.headerType = payload
  },
  [types.MUTATE_LEGAL_TYPES]: (state, payload) => {
    state.legalTypes = payload
  },
  [types.MUTATE_MARITAL_STATUSES]: (state, payload) => {
    state.maritalStatuses = payload
  },
  [types.MUTATE_ADDRESS_STATES]: (state, payload) => {
    state.addressStates = payload
  },
  [types.MUTATE_COUNTRIES_LIST]: (state, payload) => {
    state.countriesList = payload
  },
  [types.MUTATE_GENDERS]: (state, payload) => {
    state.genders = payload
  },
  [types.MUTATE_PRIORITIES]: (state, payload) => {
    state.priorities = payload
  },
  [types.MUTATE_INCOME_TAX_CATEGORY_TYPES]: (state, payload) => {
    state.incomeTaxCategoryTypes = payload
  },
  [types.MUTATE_INVESTOR_PROFILE]: (state, payload) => {
    state.investorProfile = payload
  },
}
