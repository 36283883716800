<template>
  <div v-if="plans.length > 0" class="card subscription-plans p-0">
    <div class="card-body subscription-plans-body p-5">
      <div class="row subscription-plans-head-row text-center">
        <div class="col subscription-plans-col m-2">
          <img src="~@/assets/img/logo-futuro.png" alt="" style="width: 70%;">
        </div>
        <template v-for="plan in plans" :key="plan.id">
          <div
            class="col subscription-plans-head-col m-2 row justify-content-center"
            :style="[ plan.most_chosen? 'background-color: rgb(242, 123, 52);':'']"
          >
            <div v-if="plan.most_chosen">
              <img class="w-100" src="~@/assets/img/thumbs-most-chosen.png" alt="" style="margin: -10px 0 5px 0">
            </div>
            <span>{{ plan.table_header }}</span>
          </div>
        </template>
      </div>
      <div class="row subscription-plans-row">
        <div class="col subscription-plans-col subscription-plans-col-1 m-2">
          Suporte ilimitado pelo whatsapp
        </div>
        <template v-for="(plan, index) in plans " :key="plan.id">
          <div :class="`col subscription-plans-col subscription-plans-col-${index+2} m-2`">
            <img v-if="JSON.parse(plan.financial_solution.metadata).whatsapp_support" src="~@/assets/img/check-solid.svg" alt="">
            <img v-else src="~@/assets/img/x-solid.svg" alt="">
          </div>
        </template>
      </div>
      <div class="row subscription-plans-row">
        <div class="col subscription-plans-col subscription-plans-col-1 m-2">
          Escola de negócio
        </div>
        <template v-for="(plan, index) in plans" :key="plan.id">
          <div :class="`col subscription-plans-col subscription-plans-col-${index+2} m-2`">
            <img v-if="JSON.parse(plan.financial_solution.metadata).ead_access" src="~@/assets/img/check-solid.svg" alt="">
            <img v-else src="~@/assets/img/x-solid.svg" alt="">
          </div>
        </template>
      </div>
      <div class="row subscription-plans-row">
        <div class="col subscription-plans-col subscription-plans-col-1 m-2">
          Workshop ao vivo
        </div>
        <template v-for="(plan, index) in plans" :key="plan.id">
          <div :class="`col subscription-plans-col subscription-plans-col-${index+2} m-2`">
            <img v-if="JSON.parse(plan.financial_solution.metadata).workshops" src="~@/assets/img/check-solid.svg" alt="">
            <img v-else src="~@/assets/img/x-solid.svg" alt="">
          </div>
        </template>
      </div>
      <div class="row subscription-plans-row">
        <div class="col subscription-plans-col subscription-plans-col-1 m-2">
          Clube de descontos
        </div>
        <template v-for="(plan, index) in plans" :key="plan.id">
          <div :class="`col subscription-plans-col subscription-plans-col-${index+2} m-2`">
            <img v-if="JSON.parse(plan.financial_solution.metadata).benefits_club" src="~@/assets/img/check-solid.svg" alt="">
            <img v-else src="~@/assets/img/x-solid.svg" alt="">
          </div>
        </template>
      </div>
      <div class="row subscription-plans-row">
        <div class="col subscription-plans-col subscription-plans-col-1 m-2">
          Reunião de acompanhamento
        </div>
        <template v-for="(plan, index) in plans" :key="plan.id">
          <div :class="`col subscription-plans-col subscription-plans-col-${index+2} m-2`">
            <span v-if="JSON.parse(plan.financial_solution.metadata).meetings" >
              {{JSON.parse(plan.financial_solution.metadata).meetings}}
            </span>
            <img v-else src="~@/assets/img/x-solid.svg" alt="">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as types from '../store/types'
export default {
  props: {
    customerPlans: {
      type: Object,
      default: []
    },
  },
  computed: {
    ...mapGetters({
      subscriptionPlans: types.SUBSCRIPTION_PLANS,
    }),
    plans: function(){
      return _.filter(this.subscriptionPlans, val => this.customerPlans.includes(val.id)) || []
    },
  },
  mounted() {
    this.getCustomerSubscriptionPlans(this.$route.params.customerId)
  },
  methods: {
    ...mapActions({
      getCustomerSubscriptionPlans: types.GET_CUSTOMER_SUBSCRIPTION_PLANS
    })
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 800px)  {
  .subscription-plans {
    display: none;
  }
}
.subscription-plans {
  background: transparent;
  border: none;
  height: 750px;
}
.subscription-plans-body{
  color: rgb(255, 255, 255);
  font-size: min(1vw, 15px);
}
.subscription-plans-head-col{
  background-color: rgb(25, 37, 63);
  font-weight: bold;
  color: rgb(255, 255, 255);
  font-size: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
}
.subscription-plans-head-row{
  height: 32%;
}
.subscription-plans-row{
  height: 15%;
}
.subscription-plans-col{
  font-size: 130%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription-plans-col span{
  color: rgb(25, 37, 63);
  font-weight: bold;
  font-size: 90%;
  padding: 10px;
}
.subscription-plans-col-1{
  background-color: rgb(25, 37, 63);
}
.subscription-plans-col-2{
  background-color: rgb(44, 146, 213, 0.5);
}
.subscription-plans-col-3{
  background-color: rgb(44, 146, 213, 0.65);
}
.subscription-plans-col-4{
  background-color: rgb(44, 146, 213, 0.8);
}
.subscription-plans-col-5{
  background-color: rgb(44, 146, 213);
}
.subscription-plans-col-6{
  background-color: rgb(44, 146, 213);
}
.subscription-plans-most-chosen{
  width: 15vw;
  height: 23vh;
  overflow: hidden;
  position: absolute;
}
.subscription-plans-most-chosen-icon{
  width: 79%;
  right: 20%;
  position: absolute;
}
</style>
