import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  user: undefined,
  token: undefined,
  consultant_level: undefined
}

export default {
  state,
  actions,
  getters,
  mutations
}
