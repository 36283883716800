<template>
  <form id="futuro-customer-investiment" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-chart.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Investimento</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-3 col-12">
          <label for="investorProfile" class="form-label">
            Perfil de investidor
          </label>
          <select
            id="investorProfile"
            v-model="investorProfile"
            :disabled="isDetail"
            class="form-select"
            :class="{ 'select-disabled': isDetail }"
            @blur="preSave()"
          >
            <option
              v-for="key in Object.keys(investorProfiles)"
              :key="key"
              :value="key"
            >
              {{ investorProfiles[key] }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3 mb-3 px-3">
        <div class="col-12 totalizer investments-value-container">
          <span>Total prêmio mensal:</span>
          <span>{{ $filters.toCurrency(investmentTotal) }}</span>
        </div>
        <div class="col-12 totalizer current-balance-container">
          <span>Saldo acumulado:</span>
          <span>{{ $filters.toCurrency(accumulatedBalanceTotal) }}</span>
        </div>
      </div>
      <div class="row align-items-center px-3 mb-4">
        <template v-if="!isDetail">
          <button
            class="btn btn-orange col-auto mx-1"
            @click.prevent="addInvestment"
          >
            Add investimento
          </button>
        </template>
      </div>
      <div
        v-for="(investment, index) in investments"
        :key="`investment-${index}`"
        class="inner-card mb-3"
      >
        <div class="row mb-3 gy-3">
          <div class="col-md-6 col-12">
            <label :for="`investmentActive-${index}`" class="form-label">
              Ativo
            </label>
            <textarea
              :id="`investmentActive-${index}`"
              v-model="investment.active"
              type="text"
              class="form-control"
              :disabled="isDetail"
              rows="1"
              @blur="preSave()"
            />
          </div>
          <div class="col-md-6 col-12">
            <label
              :for="`investmentbankOrBrokerage-${index}`"
              class="form-label"
            >
              Corretora/Banco
            </label>
            <textarea
              :id="`investmentbankOrBrokerage-${index}`"
              v-model="investment.bankOrBrokerage"
              type="text"
              class="form-control"
              :disabled="isDetail"
              rows="1"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-md-4 col-12">
            <label
              :for="`investmentMonthlyPremium-${index}`"
              class="form-label"
            >
              Prêmio mensal
            </label>
            <input
              :id="`investmentMonthlyPremium-${index}`"
              v-model.lazy="investment.monthlyPremium"
              v-money="moneyFormat"
              type="text"
              :disabled="isDetail"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index].monthlyPremium
                    .length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index].monthlyPremium
                    .length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label
              :for="`investmentAccumulatedBalance-${index}`"
              class="form-label"
            >
              Saldo acumulado
            </label>
            <input
              :id="`investmentAccumulatedBalance-${index}`"
              v-model.lazy="investment.accumulatedBalance"
              v-money="moneyFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index]
                    .accumulatedBalance.length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index]
                    .accumulatedBalance.length
                "
              >
                Digite um valor válido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label :for="`investmentProfitability-${index}`" class="form-label">
              Rentabilidade
            </label>
            <input
              :id="`investmentProfitability-${index}`"
              v-model.lazy="investment.profitability"
              v-money="percentageFormat"
              :disabled="isDetail"
              type="text"
              class="form-control"
              :class="{
                'is-invalid':
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index].profitability
                    .length,
              }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span
                v-if="
                  v$.investments.$dirty &&
                  v$.investments.$each.$response.$errors[index].profitability
                    .length
                "
              >
                O valor é inválido
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-3 justify-content-end">
          <div class="col-auto">
            <template v-if="!isDetail">
              <button
                class="btn btn-red"
                @click.prevent="removeInvestment(index)"
              >
                Excluir investimento
              </button>
            </template>
          </div>
        </div>
      </div>
      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getBRLFormat, getPercentageFormat } from "../../../helpers/formatting";
import {
  floatToMoney,
  floatToPercentage,
  moneyToFloat,
  dateToString,
  integerToString,
} from "../../../helpers/converters";
import { getInvestmentsPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "../../shared/store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      investments: [],
      investorProfile: "",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      investments: {
        $each: helpers.forEach({
          active: {},
          bankOrBrokerage: {},
          monthlyPremium: {
            minLength: minLength(7),
          },
          accumulatedBalance: {
            minLength: minLength(7),
          },
          profitability: {},
        }),
        investorProfile: {},
      },
    };
  },
  computed: {
    ...mapGetters({
      investorProfiles: sharedTypes.INVESTOR_PROFILE,
    }),
    investmentTotal: function () {
      return _.sumBy(this.investments, (investment) => {
        return moneyToFloat(investment.monthlyPremium);
      });
    },
    accumulatedBalanceTotal: function () {
      return _.sumBy(this.investments, (investment) => {
        return moneyToFloat(investment.accumulatedBalance);
      });
    },
    moneyFormat: function () {
      return getBRLFormat();
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getInvestments: types.GET_INVESTMENTS,
      autoSaveInvestments: types.AUTO_SAVE_INVESTMENTS,
      getInvestorProfile: sharedTypes.GET_INVESTOR_PROFILE,
    }),
    ...mapMutations({
      mutateInvestments: types.MUTATE_INVESTMENTS,
    }),
    async fetchInvestments() {
      this.loading = true;
      try {
        const response = await this.getInvestments(
          this.$route.params.customerId
        );
        this.investorProfile = response.data.investor_profile;

        this.investments = response.data?.investments.map((investment) => {
          const {
            active,
            bank_or_brokerage,
            monthly_premium,
            accumulated_balance,
            profitability,
          } = investment;

          return {
            active: active,
            bankOrBrokerage: bank_or_brokerage,
            monthlyPremium: floatToMoney(monthly_premium),
            accumulatedBalance: floatToMoney(accumulated_balance),
            profitability: floatToPercentage(profitability),
          };
        });
        const payload = getInvestmentsPayload(this);
        this.mutateInvestments(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter os investimentos do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchInvestments(), this.getInvestorProfile()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    addInvestment() {
      this.investments.push({
        active: "",
        bankOrBrokerage: "",
        monthlyPremium: "",
        accumulatedBalance: "",
        profitability: "",
        investorProfile: "",
      });
      this.preSave();
    },
    removeInvestment(i) {
      this.investments.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload = getInvestmentsPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateInvestments(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveInvestments(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente os investimentos do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  &.investments-value-container {
    background-image: var(--light-blue-gradient);
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  &.current-balance-container {
    background-image: var(--light-blue-gradient);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
</style>
