import axios from "axios";
import * as types from "./types";

export default {
    [types.GET_NPS_SURVEY]: ({ commit }, payload) => {
        return axios
            .get(`/api/nps_surveys/${payload}`)
            .then((resp) => {
                commit(types.MUTATE_NPS_SURVEY, resp.data);
                return Promise.resolve(resp);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
    [types.SET_NPS_SURVEY]: ({ commit }, payload) => {
        return axios
            .post("/api/nps_surveys", payload)
            .then((resp) => {
                commit(types.MUTATE_SET_NPS_SURVEY, resp.data);
                return Promise.resolve(resp);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    },
};
