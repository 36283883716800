import Vue from 'vue'
import _ from 'lodash'
import axios from 'axios'
import * as types from './types'
import { getCredentials, setCredentials, deleteCredentials } from '@/helpers/credentials'
import { CREDENTIALS_STORAGE_KEY } from '@/constants/auth'

export default {
  [types.MUTATE_TOKEN]: (state, payload) => {
    const {
      token,
      user,
      consultant_level
    } = payload
    if (token) {
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
      setCredentials(CREDENTIALS_STORAGE_KEY, { token, user, consultant_level })
    } else {
      const cookie = getCredentials(CREDENTIALS_STORAGE_KEY)
      setCredentials(CREDENTIALS_STORAGE_KEY, { ...cookie, user, consultant_level })
    }
    state.user = user
    state.consultant_level = consultant_level
    state.token = payload
  },
  [types.MUTATE_USER]: (state, payload) => {
    state.user = payload
  },
  [types.MUTATE_TOKEN_LOGOUT]: (state) => {
    deleteCredentials(CREDENTIALS_STORAGE_KEY)
    state.user = undefined
    state.consultant_level = undefined
    state.token = undefined
  }
}
