import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store";
import { initApi } from "@/helpers/api";
import NotFound from "../modules/main/views/NotFound";
import Unauthorized from "../modules/main/views/Unauthorized";
import Index from "../modules/main/views/Index";
import Interview from "../modules/interview/views/Form";
import TokenAuth from "../modules/account/views/TokenAuth";
import TokenAuthCustomer from "../modules/account/views/TokenAuthCustomer";
import CheckoutMain from "../modules/checkout/views/CheckoutMain";
import CustomerTokenExpired from "../modules/main/views/CustomerTokenExpired";
import PreInterviewFinished from "../modules/interview/views/PreInterviewFinished";
import NpsSurvey from "../modules/nps_survey/views/NpsSurvey";
import LeadForm from "../modules/landing_page/views/LeadForm";

initApi();

const router = createRouter({
  routes: [
    {
      path: "/autenticacao/:token",
      name: "auth-token",
      component: TokenAuth,
    },
    {
      path: "/autenticacao_cliente/:token",
      name: "customer-auth-token",
      component: TokenAuthCustomer,
    },
    {
      path: "/investimento/:token",
      name: "payment",
      component: CheckoutMain,
    },
    {
      path: "/seja-futuro/:userName/:alias",
      name: "lead-form",
      component: LeadForm,
    },
    {
        path: "/autenticacao_nps/:token",
        name: "nps-survey",
        component: NpsSurvey,
      },
    {
      path: "/pre-entrevista/finalizada",
      name: "pre-interview-finished",
      component: PreInterviewFinished,
    },
    {
      path: "/nao-autorizado",
      name: "unauthorized",
      component: Unauthorized,
    },
    {
      path: "/nao-encontrada",
      name: "not-found",
      component: NotFound,
    },
    {
      path: "/sessao-invalida",
      name: "expired-token",
      component: CustomerTokenExpired,
    },
    {
      path: "/nao-autorizado",
      name: "unauthorized",
      component: Unauthorized,
    },
    {
      path: "/",
      name: "index",
      component: Index,
      children: [
        {
          path: "/entrevista/:customerId",
          name: "private-interview",
          props: { isInterview: true, isDetail: false },
          component: Interview,
        },
        {
          path: "/pre_entrevista/:customerId",
          name: "private-pre-interview",
          props: { isInterview: false, isDetail: false },
          component: Interview,
        },
        {
          path: "/detalhe_entrevista/:customerId",
          name: "private-detail-interview",
          props: { isInterview: true, isDetail: true },
          component: Interview,
        },
        {
          path: "/minha_entrevista/:customerId",
          name: "private-customer-interview",
          props: { isInterview: true, isDetail: true, isCustomer: true },
          component: Interview,
        },
      ],
    },
    // plan on directly navigating to the not-found route using its name
    { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  ],

  history: createWebHistory(process.env.BASE_URL),

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const account = store.state.account;
router.beforeEach((to, from, next) => {
    if (to.name === "payment" || to.name === "lead-form") {
      next()
    } else if (
      to.name !== "auth-token" &&
      to.name !== "customer-auth-token" &&
      to.name !== "nps-survey" &&
      to.name !== "unauthorized" &&
      !account.token
    ) {
      next({ name: "unauthorized" });
    } else {
      next();
    }
  });

export default router;
