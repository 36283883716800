// Getters
export const USER = 'account/USER'
export const TOKEN = 'account/TOKEN'
export const IS_HUNTER = 'account/IS_HUNTER'

// Mutations
export const MUTATE_TOKEN = 'account/MUTATE_TOKEN'
export const MUTATE_USER = 'account/MUTATE_USER'
export const MUTATE_TOKEN_LOGOUT = 'account/MUTATE_TOKEN_LOGOUT'

// Actions
export const GET_USER_DATA_CREDENTIALS = 'account/GET_USER_DATA_CREDENTIALS'
export const LOGIN = 'account/LOGIN'
export const GET_USER_DATA = 'account/GET_USER_DATA'
export const LOGOUT = 'account/LOGOUT'

export const GET_CUSTOMER_CREDENTIALS = 'account/GET_CUSTOMER_CREDENTIALS'
