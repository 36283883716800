<template>
  <div class="container-fluid h-100 dark">
    <div
      class="d-flex flex-column h-100 align-items-center justify-content-center"
    >
      <img
        src="~@/assets/img/logo-white.svg"
        height="150"
        alt="Futuro | Consultoria Financeira"
      />
      <span class="mt-4">Estamos autenticando você</span>
      <span>Aguarde um momento...</span>
      <spinner class="mt-3" size="large" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "../store/types";
import Spinner from "../../shared/components/Spinner";
export default {
  components: {
    Spinner,
  },
  mounted() {
    this.getToken();
  },
  methods: {
    ...mapActions({
      loginByToken: types.GET_CUSTOMER_CREDENTIALS,
    }),
    getToken() {
      this.loginByToken({
        token: this.$route.params.token,
      })
        .then((response) => {
          this.$router.push(`/${this.$route.query?.back_url}`);
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível realizar sua autenticação.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
            confirmCallback: () =>
              (window.location.href = process.env.VUE_APP_URL),
          });
        });
    },
  },
};
</script>

<style scoped>
span {
  font-size: 20px;
}
</style>
