<template>
  <div>
    <!-- First modal -->
    <vue-final-modal
      v-model="showModal"
      classes="pix__modal__container"
      content-class="pix__modal__content"
      name="pix-cnpj"
      :resize="false"
      :max-width="400"
    >
      <template #default="{ params }">
        <div class="container">
          <div class="row mt-3">
            <h2>Pagamento via chave Pix</h2>
          </div>
          <div class="row">
            <div class="col-12 p-3">
              <p>
                Você também pode realizar uma transferência Pix utilizando como
                chave o nosso CNPJ:
              </p>
              <div class="p-3">
                <p>Nome: <b>Futuro Gestao Financeira Pessoal II Ltda</b></p>
                <p>Tipo de chave: <b>CNPJ</b></p>
                <p>Chave Pix: <b>48.312.560/0001-08</b></p>
                <p>
                  Valor a ser transferido:
                  <b>{{ $filters.toCurrency(params.amount) }}</b>
                </p>
              </div>
              <p>
                Após realizar a transferência, envie o comprovante para o seu
                consultor financeiro.
              </p>
            </div>
          </div>
          <div class="row justify-content-around gx-2">
            <button class="btn btn-red col-auto mb-3" @click.prevent="cancel">
              Cancelar
            </button>
            <button
              class="btn btn-orange col-auto mb-3"
              :disabled="copied"
              @click.prevent="copyPixKeyToClipboard"
            >
              {{ copied ? "Chave Pix copiada!" : "Copiar chave Pix" }}
            </button>
          </div>
        </div>
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    showModal: false,
    copied: false,
  }),
  methods: {
    cancel() {
      this.showModal = false;
    },
    copyPixKeyToClipboard() {
      const codePix = "48312560000108";
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(codePix);
      this.copied = true;
      setTimeout(() => (this.copied = false), 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.pix__modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.pix__modal__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border-radius: 20px;
  width: 500px;
  background-color: var(--blue-bg);
  color: white;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
h2 {
  text-align: center;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: 300;
}
.btn {
  width: 200px;
}
</style>
