<template>
    <div class="main-container dark bg-with-image">
        <app-header />
        <div class="container h-100">
            <div v-if="getStatus !== npsStatusTypes.EXPIRED && getStatus !== npsStatusTypes.ANSWERED && getStatus !== npsStatusTypes.PENDING">
                <div class="card mx-md-5 h-100 d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mb-2 text-center">Pesquisa de satisfação</h1>
                    <h5 class="text-center">Pesquisa não encontrada!</h5>
                </div>
            </div>

            <div v-if="getStatus === npsStatusTypes.EXPIRED">
                <div class="card mx-5 h-100 d-flex flex-column align-items-center justify-content-center">
                <h1 class="mb-2 text-center">Pesquisa de satisfação</h1>
                <h5 class="text-center">
                    Seu prazo para responder a pesquisa expirou!
                </h5>
                </div>
            </div>

            <div v-if="getStatus === npsStatusTypes.ANSWERED">
                <div class="card mx-md-5 h-100 d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mb-2 text-center">Pesquisa de satisfação</h1>
                    <h5 class="text-center">Essa pesquisa já foi respondida!</h5>
                </div>
            </div>

            <div v-if="getStatus === npsStatusTypes.PENDING">
                <div class="card mx-md-5 h-100 d-flex flex-column align-items-center justify-content-center">
                    <h1 class="mb-5 text-center">Pesquisa de satisfação</h1>
                    <div class="d-flex flex-column">
                        <label class="text-question form-label" for="likelyToRecommendFuturo">
                            1) Qual a probabilidade de você recomendar a Futuro para amigos
                            e familiares?
                        </label>
                        <div class="text-center mb-4">
                            <input
                                v-for="num in 10"
                                :key="num"
                                id="likelyToRecommendFuturo"
                                :class="[
                                    'value-recom mb-1',
                                    { active: likely_to_recommend_futuro === num },
                                    { 'is-invalid': v$.likely_to_recommend_futuro.$error },
                                ]"
                                type="button"
                                :value="num"
                                @click="likelyToRecommendFuturo(num)"
                            />
                            <div class="invalid-feedback text-start">
                                <span v-if="v$.likely_to_recommend_futuro.required.$invalid">
                                    Você deve informar uma pontuação
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <label
                            class="text-center text-question form-label"
                            for="justificationAnswer"
                        >
                            2) O que te levou a dar esta nota?
                        </label>
                        <textarea
                            id="justificationAnswer"
                            v-model="justification_answer"
                            type="text"
                            :class="[
                                'form-control',
                                { 'is-invalid': v$.justification_answer.$error }
                            ]"
                            rows="4"
                        />
                        <div class="invalid-feedback text-start">
                            <span v-if="v$.justification_answer.required.$invalid">
                                Campo obrigatório
                            </span>
                        </div>
                    </div>
                    <button
                        class="btn btn-orange"
                        @click="submit"
                    >
                    Finalizar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "../store/types";
import * as npsStatus from "@/constants/npsStatusTypes";
import AppHeader from "../../main/components/AppHeader";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
    components: {
        AppHeader,
    },
    data() {
        return {
            loading: false,
            likely_to_recommend_futuro: null,
            justification_answer: null,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        const validations = {};

        Object.assign(validations, {
            likely_to_recommend_futuro: { required },
            justification_answer: { required }
        });

        return validations;
    },

    computed: {
        ...mapGetters({
            npsSurvey: types.NPS_SURVEY,
        }),

        getStatus() {
            return this.npsSurvey.nps_survey_status_id
        },

        npsStatusTypes: function () {
            return npsStatus;
        },
    },
    async mounted() {
        await this.getNpsSurvey(this.$route.params.token);
    },
    methods: {
        ...mapActions({
            getNpsSurvey: types.GET_NPS_SURVEY,
            setNpsSurvey: types.SET_NPS_SURVEY,
        }),

        likelyToRecommendFuturo(num) {
            this.likely_to_recommend_futuro = num;
        },

        async submit() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;
            this.loading = true;
            this.setNpsSurvey({
                justification_answer: this.justification_answer,
                likely_to_recommend_futuro: this.likely_to_recommend_futuro,
                token: this.$route.params.token,
            })
            .catch((error) => {
                this.$vfm.show("message", {
                    type: "error",
                    messages: [
                        "Não foi possível finalizar a pesquisa.",
                        "Entre em contato com o time de TI.",
                    ],
                });
            })
            .finally(() => {
                this.loading = false;
                window.location.reload();
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.text-question {
    font-style: italic;
}

.value-recom {
    border-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    background: #fff !important;
    color: #3253a8 !important;
    font-weight: 700;
    margin-right: 0.5rem;

    &:nth-child(10) {
        margin: 0;
    }
}

.active {
    background: linear-gradient(
        225deg,
        rgba(253, 75, 75, 1) 50%,
        rgba(232, 122, 36, 1) 100%
    ) !important;
    color: #fff !important;
}

.btn-orange {
    font-weight: 700 !important;
    font-size: 1rem;
}

input[type="tel"]::placeholder {
    color: #fff;
}

@media (max-width: 575.98px) {
    .h-100 {
        height: auto !important;
    }

    #header {
        position: absolute;
    }
}
</style>
