import axios from 'axios'
import * as types from './types'
import router from '@/router'

export default {
  [types.GET_USER_DATA_CREDENTIALS]: ({ commit, dispatch }, payload) => {
    return axios.post('/api/users/login/by_token', { token: payload.token })
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        dispatch(types.GET_USER_DATA, { hasRedirect: true, backUrl: payload.backUrl })
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.LOGIN]: ({ commit, dispatch }, payload) => {
    return axios.post('/api/users/login', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        dispatch(types.GET_USER_DATA)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_USER_DATA]: ({ commit }, payload) => {
    return axios.get('/api/users/profile', payload)
      .then(resp => {
        commit(types.MUTATE_USER, resp.data)
        if (payload?.hasRedirect) {
          router.push(`/${payload.backUrl}`)
        }
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.LOGOUT]: ({ state, commit }) => {
    commit(types.MUTATE_TOKEN_LOGOUT)
    return axios.post('/api/users/logout')
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },

  [types.GET_CUSTOMER_CREDENTIALS]: ({ commit }, payload) => {
    return axios.post('/api/customers/login/by_token', payload)
      .then(resp => {
        commit(types.MUTATE_TOKEN, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}
