import * as types from "./types";

export default {
    [types.MUTATE_NPS_SURVEY]: (state, payload) => {
        state.npsSurvey = payload;
    },
    [types.MUTATE_SET_NPS_SURVEY]: (state, payload) => {
        state.setNpsSurvey = payload;
    },
};
