<template>
  <flower-spinner
    :animation-duration="1500"
    :size="spinnerSize"
    :color="theme === 'light' ? '#2b2962' : '#ffff'"
  />
</template>

<script>
import { ref } from 'vue'
import { FlowerSpinner } from 'epic-spinners'
export default {
  components: {
    FlowerSpinner
  },
  props: {
    size: {
      type: String,
      default: 'regular'
    },
    theme: {
      type: String,
      default: 'dark'
    }
  },
  setup (props) {
    const spSize = ref(props.size)
    return { spSize }
  },
  computed: {
    spinnerSize: function () {
      if (this.spSize === 'large') {
        return 60
      }
      return 30
    }
  }
}
</script>
