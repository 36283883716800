import axios from 'axios'
import * as types from './types'
import * as headerTypes from '../../../constants/headerTypes'

export default {
  [types.SET_INTERVIEW_HEADER_TYPE]: ({ commit }) => {
    commit(types.MUTATE_HEADER_TYPE, headerTypes.INTERVIEW_HEADER_TYPE)
  },
  [types.SET_NO_OPTIONS_HEADER_TYPE]: ({ commit }) => {
    commit(types.MUTATE_HEADER_TYPE, headerTypes.NO_OPTIONS_HEADER_TYPE)
  },
  [types.GET_LEGAL_TYPES]: ({ commit }, payload) => {
    return axios.get('/api/common/legal_types', payload)
      .then(resp => {
        commit(types.MUTATE_LEGAL_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_MARITAL_STATUSES]: ({ commit }, payload) => {
    return axios.get('/api/common/marital_statuses', payload)
      .then(resp => {
        commit(types.MUTATE_MARITAL_STATUSES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_ADDRESS_STATES]: ({ commit }, payload) => {
    return axios.get('/api/common/address_states', payload)
      .then(resp => {
        commit(types.MUTATE_ADDRESS_STATES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_COUNTRIES_LIST]: ({ commit }, payload) => {
    return axios.get('/api/common/countries_list', payload)
      .then(resp => {
        commit(types.MUTATE_COUNTRIES_LIST, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_GENDERS]: ({ commit }, payload) => {
    return axios.get('/api/common/genders', payload)
      .then(resp => {
        commit(types.MUTATE_GENDERS, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_PRIORITIES]: ({ commit }, payload) => {
    return axios.get('/api/common/priorities', payload)
      .then(resp => {
        commit(types.MUTATE_PRIORITIES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_INCOME_TAX_CATEGORY_TYPES]: ({ commit }, payload) => {
    return axios.get('/api/common/income_tax_categories', payload)
      .then(resp => {
        commit(types.MUTATE_INCOME_TAX_CATEGORY_TYPES, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }, [types.GET_INVESTOR_PROFILE]: ({ commit }, payload) => {
    return axios.get('/api/common/investor_profile', payload)
      .then(resp => {
        commit(types.MUTATE_INVESTOR_PROFILE, resp.data)
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CEP]: ({}, payload) => {
    return axios
      .get(`/api/addresses/by_cep/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_DUPLICATE_CUSTOMER_PHONE]: ({}, payload) => {
    return axios
      .get(`/api/customers/check_duplicate_cell_phones/${payload.phone + (payload.customerId ? '/' + payload.customerId : '')}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_DUPLICATE_CONSULTANT_PHONE]: ({}, payload) => {
    return axios
      .get(
        `/api/leads/check_duplicate_cell_phones/${payload.cell}/${payload.id}`,
        payload
      )
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CHECK_DUPLICATE_EMAIL]: ({}, payload) => {
    return axios
      .get(`/api/customers/check_duplicate_emails/${payload.email + (payload.customerId ? '/' + payload.customerId : '')}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err);
      });
  },
}
