// Getters
export const CHECKOUT = 'checkout/CHECKOUT'

// Mutations
export const MUTATE_CHECKOUT = 'checkout/MUTATE_CHECKOUT'
export const MUTATE_INTERVIEW_CHECKOUT_STATUS = 'checkout/MUTATE_INTERVIEW_CHECKOUT_STATUS'

// Actions
export const GET_CHECKOUT = 'checkout/GET_CHECKOUT'
export const RESEND_SIGN_EMAIL = 'checkout/RESEND_SIGN_EMAIL'
export const PAY_SUBSCRIPTION = 'checkout/PAY_SUBSCRIPTION'
export const PAY_INTERVIEW = 'checkout/PAY_INTERVIEW'
export const GET_CHECKOUT_STATUS = 'checkout/GET_CHECKOUT_STATUS'
