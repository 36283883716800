<template>
  <div id="header">
    <div id="header-content">
      <div class="container">
        <div class="row">
          <div class="col-4 blocoLogo">
            <router-link to="/app/interview">
              <img
                src="~@/assets/img/logo-white.svg"
                alt="Futuro | Consultoria Financeira"
                class="logo"
              />
            </router-link>
          </div>
          <div class="col-8 row justify-content-end align-items-center">
            <interview-header-options v-if="type === interviewType" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import * as sharedTypes from "../../shared/store/types";
import * as headerTypes from "../../../constants/headerTypes";
import InterviewHeaderOptions from "../../interview/components/InterviewHeaderOptions";
export default {
  components: {
    InterviewHeaderOptions,
  },
  setup() {
    const interviewType = headerTypes.INTERVIEW_HEADER_TYPE;
    return { interviewType };
  },
  computed: {
    ...mapGetters({
      type: sharedTypes.HEADER_TYPE,
    }),
  },
  mounted() {
    document.addEventListener(
      "scroll",
      (event) => {
        const titleElement = document.getElementById("interview-title");
        const headerElement = document.getElementById("header");
        if (titleElement && headerElement) {
          const offset = titleElement.getBoundingClientRect().top;
          if (offset < 65) {
            headerElement.classList.add("blue-bg");
          } else {
            headerElement.classList.remove("blue-bg");
          }
        }
      },
      true
    );
  },
};
</script>

<style lang="scss" scoped>
#header {
  /*Opacity start*/
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  background-color: transparent;
  /*Opacity end*/
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 2;
}
#header #header-content {
  margin: 10px;
}
</style>
