import _ from 'lodash'

export const processErrorMessages = (payload) => {
  if (payload) {
    const errors = _.values(payload)
    if (errors) {
      const errorsMatrix = _.concat(errors)
      let processedErrors = []
      errorsMatrix.forEach(line => {
        processedErrors = processedErrors.concat(line)
      })
      return processedErrors
    }
  }
  return []
}
