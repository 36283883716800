export const declareFilters = (app) => {

  app.config.globalProperties.$filters = {

    toCurrency(value) {
      if (typeof value === 'number') {
        var formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2
        });
        return formatter.format(value);
      }
      return value;
    },

    toDecimal(value) {
      let number = value;
      if (typeof value === 'string') {
        number = parseFloat(value);
      }
      var formatter = new Intl.NumberFormat('pt-BR', {
        maximumFractionDigits: 1
      });
      return formatter.format(number);
    },

    toHourMinute(value) {
      if (!value) {
        return '';
      }

      let number = value;
      if (typeof value === 'string') {
        number = parseInt(value, 10);
      }
      let hours = parseInt(Math.floor(number / 3600), 10);
      let minutes = parseInt(Math.floor((number - (hours * 3600)) / 60), 10);
      let dHours = (hours > 9 ? hours : '0' + hours);
      let dMins = (minutes > 9 ? minutes : '0' + minutes);
      return dHours + 'h ' + dMins + 'm';
    },

    toPercentage(value) {
      if (typeof value !== 'number' || isNaN(value)) {
        return '0%';
      }
      return `${Math.round(value)}%`;
    },

    onlyDate(value) {
      if (value && typeof value === 'string' && value !== '0000-00-00') {
        const dateStr = value.split('T')[0].split('-');
        return dateStr[2] + '/' + dateStr[1] + '/' + dateStr[0];
      }
      return 'A definir';
    },

    onlyTime(value) {
      if (value && typeof value === 'string' && value !== '0000-00-00') {
        const dateStr = value.split('T')[1].split('.');
        return dateStr[0].substring(0, 5);
      }
      return '00:00';
    },

    dateTime(value) {
      if (value && typeof value === 'string') {
        const dateAndTime = value.split('T');
        const dateStr = dateAndTime[0].split('-');
        const timeStr = dateAndTime[1].split(':');
        return dateStr[2] + '/' + dateStr[1] + '/' + dateStr[0] + ' ' + timeStr[0] + ':' + timeStr[1];
      }
      return 'A definir';
    },

    dateToStr(value) {
      if (value && typeof value === 'string') {
        const dateStr = value.split(' ')[0].split('-');
        return dateStr[2] + '/' + dateStr[1] + '/' + dateStr[0];
      }
      return 'A definir';
    },

    paymentType(value) {
      if (value && typeof value === 'string') {
        if (value === 'credit') {
          return 'Cartão de crédito';
        }
        if (value === 'pix') {
          return 'Pix';
        }
      }
      return 'cartão de crédito';
    },

    leadType(value){
      if(value && typeof value === 'string'){
        if (value === 'PCL') {
          return 'Rec. cliente';
        }
        if (value === 'PPN') {
          return 'Rec. PN';
        }
      }
    }

  }

}
