<template>
  <div>
    <!-- First modal -->
    <vue-final-modal
      v-model="showModal"
      classes="interview__modal__container"
      content-class="interview__modal__content"
      name="interview-comment"
      :resize="false"
      :max-width="400"
      @beforeOpen="event => clearMessage(event)"
    >
      <template #default="{ params }">
        <div class="container">
          <div class="row justify-content-center">
            <span class="title col-auto">
              Adicionando comentário
            </span>
          </div>
          <div class="row">
            <div class="col-12 mt-3 p-0">
              <textarea
                v-model="message"
                type="text"
                class="form-control"
                rows="4"
              />
            </div>
          </div>
          <div class="row justify-content-between mt-3">
            <button
              class="btn btn-red"
              :disabled="loading"
              @click.prevent="cancel"
            >
              Cancelar
            </button>
            <button
              class="btn btn-orange"
              :disabled="loading"
              @click.prevent="save(params.section, params.token)"
            >
              {{ loading ? 'Salvando...' : 'Salvar' }}
            </button>
          </div>
        </div>
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as types from '../store/types'
export default {
  data: () => ({
    showModal: false,
    sectionName: undefined,
    message: '',
    loading: false,
    error: false
  }),
  methods: {
    ...mapActions({
      saveSectionComment: types.SAVE_SECTION_COMMENT
    }),
    save(sectionName, token) {
      this.showModal = false
      this.loading = true
      this.error = false
      this.saveSectionComment({
        interview_session: sectionName,
        token,
        comment: this.message
      })
        .catch(error => {
            this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      this.showModal = false
    },
    clearMessage(event) {
      // this.sectionName = event.ref.params._value.section
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.interview__modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep(.interview__modal__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border-radius: 20px;
  width: 500px;
  background-color: var(--blue-bg);
  color: white;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: 300;
  text-align: center;
}
.btn {
  width: 130px;
}
</style>
