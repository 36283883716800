import axios from "axios";
import * as types from "./types";

export default {
  [types.SAVE_SECTION_COMMENT]: ({ commit }, payload) => {
    return axios
      .post(`/api/interviews/${payload.token}/comments`, payload)
      .then((resp) => {
        commit(types.MUTATE_COMMENTS, {
          interview_session: payload.interview_session,
          data: resp.data,
        });
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_SECTION_COMMENTS]: ({ commit }, payload) => {
    return axios
      .get(
        `/api/interviews/${payload.token}/comments/${payload.interview_session}`
      )
      .then((resp) => {
        commit(types.MUTATE_COMMENTS, {
          interview_session: payload.interview_session,
          data: resp.data,
        });
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_SECTION_COMMENT]: ({ commit }, payload) => {
    return axios
      .delete(
        `/api/interviews/${payload.token}/comments/${payload.interview_session}/${payload.id}`
      )
      .then((resp) => {
        commit(types.MUTATE_COMMENTS, {
          interview_session: payload.interview_session,
          data: resp.data,
        });
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_INFO]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/customers/${payload}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_CUSTOMER_INFO]: ({}, payload) => {
    return axios
      .put(`/api/interviews/customers/${payload.token}/auto_save`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_CUSTOMER_FINISH_INTERVIEW]: ({ commit, state }, payload) => {
    if (!state.customerAlteredInterview) {
      commit(types.MUTATE_CUSTOMER_ALTERED_INTERVIEW);
      return axios
        .get(
          `/api/interviews/${payload.token}/interview/set_customer_finish_pre_interview`,
          payload
        )
        .then((resp) => {
          return Promise.resolve(resp);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  },
  [types.DELETE_CUSTOMER_CHILD]: ({}, payload) => {
    return axios
      .delete(
        `/api/interviews/customers/${payload.token}/children/${payload.id}`
      )
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_CUSTOMER_PET]: ({}, payload) => {
    return axios
      .delete(`/api/interviews/customers/${payload.token}/pets/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_GOALS]: ({ commit }) => {
    return axios
      .get("/api/interviews/goals/categories")
      .then((resp) => {
        commit(types.MUTATE_GOALS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_SELECTED_GOALS]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/goals`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_SELECTED_GOALS]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/goals`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_SELECTED_GOAL]: ({}, payload) => {
    return axios
      .delete(`/api/interviews/${payload.token}/goals/${payload.id}`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_LIFE_INSURANCE]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/life_insurances`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_LIFE_INSURANCE]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/life_insurances`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ACQUISITIONS]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/acquisitions`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_ACQUISITIONS]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/acquisitions`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PATRIMONY]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/patrimony`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_PATRIMONY]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/patrimony`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_HEALTH_PLAN]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/health_plan`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_HEALTH_PLAN]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/health_plan`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_RETIREMENT]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/retirement`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_RETIREMENT]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/retirement`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_MONTHLY_INVESTMENT]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/monthly_investments`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_MONTHLY_INVESTMENT]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/monthly_investments`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_MONTHLY_INVESTMENT_TAXES]: ({}) => {
    return axios
      .get("/api/interviews/monthly_investments/taxes")
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CALCULATE_MONTHLY_INVESTMENT]: ({ commit }, payload) => {
    return axios
      .post("/api/interviews/monthly_investments/calculate", payload)
      .then((resp) => {
        commit(types.MUTATE_MONTHLY_INVESTMENT_CALCULATION, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_DEBTS]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/debts`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_DEBTS]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/debts`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CONTRACT_SERVICE_TYPES]: ({ commit }) => {
    return axios
      .get("/api/common/contract_services")
      .then((resp) => {
        commit(types.MUTATE_CONTRACT_SERVICE_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PAYMENT_METHOD_TYPES]: ({ commit }, payload) => {
    return axios
      .get("/api/common/payment_methods")
      .then((resp) => {
        commit(types.MUTATE_PAYMENT_METHOD_TYPES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CLOSER_CONSULTANTS]: ({ commit }) => {
    return axios
      .get("/api/interviews/proposal/consultants")
      .then((resp) => {
        commit(types.MUTATE_CLOSER_CONSULTANTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_PROPOSAL]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/proposal`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_PROPOSAL]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/proposal`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_RECOMMENDATION]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/recommendations`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.DELETE_RECOMMENDATION]: ({}, payload) => {
    return axios
      .delete(`/api/interviews/recommendation/${payload.id}`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CALCULATE_PROPOSAL]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/${payload}/proposal/calculate`)
      .then((resp) => {
        commit(types.MUTATE_PROPOSAL_CALCULATION, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CASH_FLOW_CATEGORIES]: ({ commit }) => {
    return axios
      .get(`/api/interviews/cash_flow/categories`)
      .then((resp) => {
        commit(types.MUTATE_CASH_FLOW_CATEGORIES, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CASH_FLOW]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/cash_flow`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.CALCULATE_CASH_FLOW]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/${payload}/cash_flow/calculate`)
      .then((resp) => {
        commit(types.MUTATE_CASH_FLOW_CALCULATION, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_CASH_FLOW]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/cash_flow`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_ADDITIONAL_INFORMATION]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/additional_information`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_ADDITIONAL_INFORMATION]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/additional_information`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_INVESTMENTS]: ({}, payload) => {
    return axios
      .get(`/api/interviews/${payload}/investments`)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.AUTO_SAVE_INVESTMENTS]: ({}, payload) => {
    return axios
      .put(`/api/interviews/${payload.token}/investments`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_CONTRACTS]: ({ commit }, payload) => {
    return axios
      .get(`/api/contracts/by_token/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_CONTRACTS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GENERATE_CONTRACT]: async ({ state }) => {
    await save(state);
    return axios
      .post("/api/contracts/generate", { token: state.customerInfo.token })
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SAVE_INTERVIEW]: ({ state }) => {
    return save(state);
  },
  [types.GET_SUBSCRIPTION_PLANS]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/proposal/subscription_plans`)
      .then((resp) => {
        commit(types.MUTATE_SUBSCRIPTION_PLANS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_CUSTOMER_SUBSCRIPTION_PLANS]: ({ commit }, payload) => {
    return axios
      .get(`/api/interviews/customers/${payload}/subscription_plans`)
      .then((resp) => {
        commit(types.MUTATE_CUSTOMER_SUBSCRIPTION_PLANS, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_SEARCH_INTERVIEW]: ({ commit }, payload) => {
    return axios
      .get(`/api/customers/customer_satisfaction/${payload}`)
      .then((resp) => {
        commit(types.MUTATE_SEARCH_INTERVIEW, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SET_SEARCH_INTERVIEW]: ({ commit }, payload) => {
    return axios
      .post("/api/customers/customer_satisfaction", payload)
      .then((resp) => {
        commit(types.MUTATE_SET_SEARCH_INTERVIEW, resp.data);
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.GET_COMMERCIAL_PROPOSAL_LINK]: ({ commit }, payload) => {
    return axios
      .post("/api/commercial_proposals/generate_token", payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  [types.SEND_COMMERCIAL_PROPOSAL_EMAIL]: ({ commit }, payload) => {
    return axios
      .post(`/api/commercial_proposals/send_email`, payload)
      .then((resp) => {
        return Promise.resolve(resp);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};

const save = (state) => {
  const {
    customerInfo,
    selectedGoals,
    lifeInsurance,
    acquisitions,
    patrimony,
    healthPlan,
    retirement,
    monthlyInvestment,
    debts,
    cashFlow,
    additionalInformation,
    investments,
    proposal,
  } = state;
  return Promise.all([
    axios.put(`/api/interviews/customers/${customerInfo.token}`, customerInfo),
    axios.put(`/api/interviews/${customerInfo.token}/goals`, selectedGoals),
    axios.put(
      `/api/interviews/${customerInfo.token}/life_insurances`,
      lifeInsurance
    ),
    axios.put(
      `/api/interviews/${customerInfo.token}/acquisitions`,
      acquisitions
    ),
    axios.put(`/api/interviews/${customerInfo.token}/patrimony`, patrimony),
    axios.put(`/api/interviews/${customerInfo.token}/health_plan`, healthPlan),
    axios.put(`/api/interviews/${customerInfo.token}/retirement`, retirement),
    axios.put(
      `/api/interviews/${customerInfo.token}/monthly_investments`,
      monthlyInvestment
    ),
    axios.put(`/api/interviews/${customerInfo.token}/debts`, debts),
    axios.put(`/api/interviews/${customerInfo.token}/cash_flow`, cashFlow),
    axios.put(
      `/api/interviews/${customerInfo.token}/additional_information`,
      additionalInformation
    ),
    axios.put(`/api/interviews/${customerInfo.token}/investments`, investments),
    axios.put(`/api/interviews/${customerInfo.token}/proposal`, proposal),
  ]);
};
