import axios from 'axios'
import * as types from './types'

export default {
  [types.SAVE_LEAD]: ({ }, payload) => {
    return axios.post('/api/leads', payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  [types.GET_CAMPAIGN_FROM_ALIAS]: ({ }, payload) => {
    return axios.post(`/api/campaigns/by_alias`, payload)
      .then(resp => {
        return Promise.resolve(resp)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}
