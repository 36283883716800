<template>
  <modals-container />
  <router-view />
</template>

<script>
import { ModalsContainer } from 'vue-final-modal'
export default {
  components: {
    ModalsContainer
  }
}
</script>

<style lang="scss">
// color definitions
$custom-red: #f54b79;

/* default values */
html,
body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-x: hidden;
  overflow:initial;
  color: var(--black-txt);
  font-family: "Poppins", sans-serif;
}
#app {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--black-txt);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
}
a,
a:hover {
  text-decoration: none;
}
:root {
  --black-txt: #222;
  --orange: #f26237;
  --orange-light: #ff933a;
  --blue: #2b2962;
  --grey: #f7f7f7;
  --orange-gradient: linear-gradient(
    225deg,
    rgba(253, 75, 75, 1) 50%,
    rgba(232, 122, 36, 1) 100%
  );
  --blue-gradient: linear-gradient(
    42deg,
    rgba(48, 45, 147, 1) 50%,
    rgba(55, 117, 190, 1) 100%
  );
  --red-gradient: linear-gradient(
    42deg,
    rgba(232,	36,	104, 1) 50%,
    rgba(245,	75,	121, 1) 100%
  );
  --green-gradient: linear-gradient(
    42deg,
    rgba(53,	192,	115, 1) 50%,
    rgba(96,	211,	148, 1) 100%
  );
  --dark-blue-gradient: linear-gradient(252deg, #6b6bfd, #2486e8);
  --light-blue-gradient: linear-gradient(252deg, #65a0ff, #24b3e8);
  --blue-bg: #2e3d9a;
  --banner-big: url("~@/assets/img/banner-high.jpg");
  --banner-small: url("~@/assets/img/banner-small.jpg");
}

.blue-bg {
  background-image: var(--blue-gradient);
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

p {
  color: var(--black-txt);
  font-size: 0.813rem;
  line-height: 1rem;
  font-weight: 300;
}

/* WYSIWYG Editor */

.entry-content img {
  margin: 0 0 1.5em 0;
}
.alignleft,
img.alignleft {
  margin-right: 1.5em;
  display: inline;
  float: left;
}
.alignright,
img.alignright {
  margin-left: 1.5em;
  display: inline;
  float: right;
}
.aligncenter,
img.aligncenter {
  margin-right: auto;
  margin-left: auto;
  display: block;
  clear: both;
}
.wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;
}
.wp-caption img {
  border: 0 none;
  padding: 0;
  margin: 0;
}
.wp-caption p.wp-caption-text {
  line-height: 1.5;
  font-size: 10px;
  margin: 0;
}
.wp-smiley {
  margin: 0 !important;
  max-height: 1em;
}
blockquote.left {
  margin-right: 20px;
  text-align: right;
  margin-left: 0;
  width: 33%;
  float: left;
}
blockquote.right {
  margin-left: 20px;
  text-align: left;
  margin-right: 0;
  width: 33%;
  float: right;
}

/* adminbar */

#wpadminbar {
  display: none !important;
}

/* responsive iframe */
iframe,
object,
embed {
  max-width: 100%;
}

/* overlay menu */

.button_container {
  position: absolute;
  top: 10px;
  right: 0px;
  height: 35px;
  width: 45px;
  cursor: pointer;
  z-index: 100;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  z-index: 1001;
  border: 2.2px solid var(--orange);
  padding: 5px;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}
.button_container:hover {
  opacity: 0.8;
}
.button_container.active .top {
  -webkit-transform: translateY(3px) translateX(0) rotate(45deg);
  transform: translateY(3px) translateX(0) rotate(45deg);
  background: #fff;
}
.button_container.active .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active .bottom {
  -webkit-transform: translateY(-5px) translateX(0) rotate(-45deg);
  transform: translateY(-5px) translateX(0) rotate(-45deg);
  background: #fff;
}
.button_container span {
  background: #707070;
  border: none;
  height: 2px;
  width: 55%;
  position: absolute;
  top: 10px;
  left: 9px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 14px;
  width: 35%;
}
.button_container span:nth-of-type(3) {
  top: 18px;
}
.overlay {
  position: fixed;
  background: var(--blue);
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  z-index: 1000;
}
.overlay.open {
  opacity: 0.98;
  visibility: visible;
  height: 100%;
}
.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(n) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.overlay nav {
  position: relative;
  height: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: 15%;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}
.overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 2px;
  background: #fff;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
/* end overlay menu */

/* header - index */
.header-hero {
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-color: var(--blue-cliny);
}
.hero-wrapper {
  padding: 0px 0 32% 0;
}
.header-hero .hero-bg {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: var(--banner-big);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  z-index: -1;
}
/* end header - index */
/* nav default */
#menu-menu .menu-item {
  padding: 0 10px;
  color: white;
}
#menu-menu .menu-item a {
  color: white;
  font-weight: 200;
  font-size: 14px;
}
#menu-menu .current_page_item a {
  font-weight: 500;
}
#menu-menu .menu-item a:hover {
  font-weight: 500;
}
/* end nav default */

/* buttons */

.btn {
  color: #fff;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  padding: 10px 20px 10px 20px;
  font-size: 11.5px;
}
.btn:hover {
  animation: shadow-drop-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.54) both;
  -webkit-animation: shadow-drop-center 0.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.54) both;
}
.btn img {
  width: 16px;
}
.btn-orange {
  color: white;
  background: var(--orange-gradient);
}
.btn-orange:hover {
  color: white;
  background: var(--orange-gradient);
}
.btn-blue {
  color: white;
  background: var(--blue-gradient);
}
.btn-blue:hover {
  color: white;
  background: var(--blue-gradient);
}
.btn-red {
  color: white;
  background: var(--red-gradient);
}
.btn-red:hover {
  color: white;
  background: var(--red-gradient);
}
.btn-white {
  border: 2px solid white;
  background: transparent;
}
.btn-white:hover {
  color: var(--blue);
  background: white;
}
.btn-white-full {
  color: #2e4ba5;
  background: white;
}
.btn-white-full:hover {
  color: #2e4ba5;
  background: white;
}
.btn-whatsapp {
  color: white;
  background: #25D366;
}
.btn-whatsapp:hover {
  color: white;
  background: #25D366;
}
/* animation */

@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(253, 234, 234, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

/* owl dots custom */

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--blue-gradient);
}
.videos .owl-theme .owl-dots .owl-dot.active span,
.videos .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--orange-gradient);
}
.owl-theme .owl-dots .owl-dot span {
  background: #eaeaea;
}
.owl-theme .owl-dots .owl-dot.active span {
  width: 20px;
  height: 10px;
  margin: 5px 5px;
}
.owl-theme .owl-dots .owl-dot span {
  height: 10px;
  margin: 5px 2px;
}

/* video */

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.video-container::after {
  padding-top: 55%;
  display: block;
  content: "";
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* btn whatsapp floating */

.floating {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1;
}

.floating img {
  width: 45px;
  height: 45px;
}

.animated {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-8px);
  }
}

@keyframes bounce {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

/* select custom */

.select-custom {
  padding: 10px 20px;
  border: 2px solid var(--orange);
  max-width: 98%;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  margin: auto;
  background-color: #fff;
}

.select-custom select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent url("~@/assets/img/ic-down.png") no-repeat 98% 5px;
  color: var(--orange);
  font-weight: 600;
  border: none;
  font-size: 0.8rem;
}
.select-custom .form-control:focus {
  background: transparent url("~@/assets/img/ic-down.png") no-repeat 98% 5px;
  color: var(--orange);
}

/* pagination WP */

.pagination ul {
  list-style: none;
  display: flex;
}
.pagination .page-numbers {
  border: 1px solid #c3c3c3;
  color: #c3c3c3;
  background: white;
  margin: 0px 5px;
  font-size: 13px;
  font-weight: 500;
  padding: 8px 18px !important;
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}
.pagination .page-numbers.current {
  border: 1px solid var(--orange);
  color: var(--orange);
  background: transparent;
}

/* css custom */

.topTile {
  background-color: var(--blue);
  color: white;
  font-size: 0.6rem;
  letter-spacing: 0.2px;
  font-weight: 300;
}
.topTile .socialIc {
  height: 12px;
  margin-left: 5px;
}
.topTile b {
  font-weight: 600;
}
.greyCard {
  background: url("~@/assets/img/bg-grey-card.jpg") repeat-y left center;
  padding: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.about .round {
  border-radius: 80px 20px 20px 20px;
  -webkit-border-radius: 80px 20px 20px 20px;
  -moz-border-radius: 80px 20px 20px 20px;
  -ms-border-radius: 80px 20px 20px 20px;
  -o-border-radius: 80px 20px 20px 20px;
}
.planning .container {
  background: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/img-planejamento-financeiro.svg") no-repeat
    left center;
  min-height: 518px;
}
.future span {
  color: var(--orange);
  font-weight: 600;
}
.cardBlue {
  background: var(--blue-gradient);
  padding: 50px 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.cardBlue h3,
.cardBlue p {
  color: white;
}
.cardJourney {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid #c9c9c9;
}
.cardJourney .content {
  padding: 20px;
}
.cardJourney img.image {
  border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -ms-border-radius: 20px 20px 0px 0px;
  -o-border-radius: 20px 20px 0px 0px;
}
.cardJourney .ic {
  width: 30px !important;
}
.cardOrange {
  background-color: var(--orange);
  padding: 50px 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardOrange h3,
.cardOrange p {
  color: white;
}
.news .card-new {
  position: relative;
  min-height: 244px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 20px 30px;
}
.news .card-new::before {
  border-radius: 20px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/img/dark-blue.png"); /* using this image in overlay */
  filter: opacity(80%);
  -webkit-filter: opacity(80%);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.news .card-new h3 {
  color: white;
}
.news .card-new .over {
  z-index: 999;
}
.planner {
  background: url("~@/assets/img/bg-planejador-financeiro-futuro.jpg") no-repeat
    center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.planner h3,
.planner p {
  color: white;
}
.mission-carousel {
  min-height: 120px;
}
.logo_ {
  max-width: 120px;
}
.bgGrey {
  background-color: var(--grey);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.bgGrey h3 {
  color: var(--orange);
}
.bgGrey .image {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.testimonials img.imgTest {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}
.testimonials b {
  font-weight: 600;
}
.result h6 {
  font-size: 0.8rem;
}

.subfooter {
  background: var(--blue-gradient);
}
.subfooter p,
footer p {
  font-size: 0.6rem;
  line-height: 0.8rem;
  font-weight: 300;
  color: white;
}
.subfooter b,
footer b {
  font-weight: 600;
}
.subfooter .socialIc {
  height: 15px;
}
.subfooter .logo_ {
  max-width: 120px;
}
footer {
  background-color: var(--blue);
}

.dark {
  background-color: var(--blue-bg);
  &.bg-with-image {
    background-image: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/dark-bg.svg");
    background-size: auto;
    background-repeat: no-repeat;
    background-color: var(--blue-bg);
  }
  padding-top: 10px;
  padding-bottom: 30px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  p,
  ol,
  ul {
    color: white;
  }

  p {
    font-size: 16px;
    line-height: 1.8;
  }

  h2 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 0;
    font-weight: normal;
  }

  ol, ul {
    margin-left: 25px;
    font-weight: 300;
  }

  .card {
    border-radius: 30px;
    background-color: rgba(50, 101, 180, 0.52);
    padding: 35px 28px;
    .card-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-right: 13px;
        height: 20px;
      }
      h2 {
        padding-top: 2px;
      }
    }
  }

  .form-label {
    color: #fff;
  }

  input,
  .form-select {
    border-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    background-color: transparent;
  }

  input:focus, input:disabled {
    color: #000;
  }

  textarea {
    border-color: #fff;
    border-radius: 10px;
    background-color: transparent;
    color: #fff !important;
  }

  textarea:focus, textarea:disabled {
    color: #000 !important;
  }

  .form-select {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    option {
      margin: 40px;
      background: #fff;
      color:#000 !important;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
  }

  .invalid-feedback {
    span {
      color: $custom-red;
    }
  }

  .form-control.is-invalid {
    border-color: $custom-red;
  }

  .form-select.is-invalid {
    border-color: $custom-red;
  }

  // Carousel
  .carousel {
    margin: 0 -25px;
  }

  .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: var(--vc-clr-primary);
    color:  var(--vc-clr-white);
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__slide {
    padding: 10px;
  }

  .carousel__prev,
  .carousel__next {
    background-color: white;
    color: #2E3D9A;
  }

  .carousel__pagination-button--active {
    background-color: white;
  }

  .carousel__track {
    width: 100%;
    margin: 0 !important;
  }

  .inner-card {
    padding: 25px 20px;
    border-radius: 20px;
    background-color: #284596;
  }

  .blue-card {
    background-image: var(--dark-blue-gradient);
    border-radius: 14px;
    color: white;
    text-align: center;
    p {
      font-size: 20px;
      line-height: 1.5;
      margin: 0;
      b {
        font-weight: 600;
      }
    }
    h1 {
      font-size: 28px;
      font-weight: 600;
    }
    .inner-section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    &.selected {
      border: 4px solid white;
    }
  }

  .only-icon-container {
    background-color: white;
  }

  .page-title {
    font-size: 45px;
    font-weight: 600;
  }

}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.open > .dropdown-toggle.btn-outline-light {
  &.no-hover {
    background-color: transparent;
  }
}

.clickable {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.main-container {
  padding: 100px 0;
}

.logo {
  height: 60px;
}

.select-disabled {
  background-color: #e9ecef !important;
  opacity: 1 !important;
  color: #000 !important;
}

/* media queries */

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .hero-wrapper {
    padding: 0px 0 75% 0;
  }
  .header-hero .hero-bg {
    background-image: var(--banner-small);
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .planning .container {
    background: white;
    min-height: auto;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 991.98px) {
  .main-container {
    min-height: 100% !important;
  }
}
.vti__dropdown.open {
  background-color: transparent;
}
.vti__dropdown:hover {
  background-color: transparent !important;
}
.vue-tel-input {
  border-color: #fff !important;
  border-radius: 10px !important;
  padding: 0 !important;
  color: #fff;
  background-color: transparent;
}
.vti__input:focus {
  background-color: white;
  border-color: white;
}
.vue-tel-input:focus-within {
    box-shadow: none !important;
    border-color: white !important;
    background-color: white !important;
    color: black !important;
}
.vti__dropdown-list {
    z-index: 1;
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 390px;
    color: black !important;
}
.vti__input {
  padding-left: 10px !important;
}
</style>
