<template>
  <time-series-chart
    :data="data"
    :options="options"
    title="Diferença"
  />
</template>

<script>
  import TimeSeriesChart from '../../shared/components/TimeSeriesChart'
  export default {
    components: {
      TimeSeriesChart
    },
    props: {
      labels: {
        type: Array,
        default: () => []
      },
      seriesFirst: {
        type: Array,
        default: () => []
      },
      seriesSecond: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      data: function () {
        return {
          labels: this.labels,
          datasets: [
            {
              label: 'Sozinho',
              borderWidth: 1,
              borderColor: '#FB672C',
              backgroundColor: '#FB672C',
              data: this.seriesFirst
            }, {
              label: 'Profissional',
              borderWidth: 1,
              borderColor: '#18D12A',
              backgroundColor: '#18D12A',
              data: this.seriesSecond
            }
          ]
        }
      },
      options: function () {
        return {
          plugins: {
            legend: {
              labels: {
                color: '#fff'
              }
            }
          },
          scales: {
            y: {
              ticks: {
                color: '#ffffff'
              },
              grid: {
                color: '#4c6ed5'
              }
            },
            x: {
              ticks: {
                color: '#ffffff'
              },
              grid: {
                color: '#4c6ed5'
              }
            }
          }
        }
      }
    }
  }
</script>
