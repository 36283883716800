<template>
  <div>
    <!-- First modal -->
    <vue-final-modal
      v-model="showModal"
      classes="interview__modal__container"
      content-class="interview__modal__content"
      name="interview-info"
      :resize="false"
      :max-width="400"
      @beforeOpen="(event) => clearMessage(event)"
    >
      <template #default="{ params }">
        <div class="container">
          <button class="close" @click.prevent="cancel">
            <img src="~@/assets/img/close.svg" height="30" />
          </button>
          <div class="row justify-content-center mt-3">
            <h1 class="col-auto title text-white text-center">{{ title }}</h1>
          </div>
          <div class="row">
            <div class="col-12 my-2 p-0">
              <p class="text-center text-white">
                {{ description }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <button class="btn btn-finish" @click.prevent="cancel">
              Entendi!
            </button>
          </div>
        </div>
      </template>
    </vue-final-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "../store/types";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    showModal: false,
    sectionName: undefined,
    message: "",
    loading: false,
    error: false,
  }),
  methods: {
    cancel() {
      this.showModal = false;
    },
    clearMessage(event) {
      // this.sectionName = event.ref.params._value.section
      this.message = "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.interview__modal__container) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
}
::v-deep(.interview__modal__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border-radius: 10px;
  width: 500px;
  background-color: #0131a0;
  color: white;
}
.title {
  font-size: 1.5rem;
}
p {
  font-size: var(--bs-body-font-size);
  font-weight: 300;
  text-align: center;
  line-height: 1.6;
}
.btn {
  width: 130px;
}

.btn-finish {
  background-color: #43c908;
  min-width: 221px;
  padding: 0.5rem 0.75rem;
  color: #fff;
  font-weight: bold !important;
  font-size: 1rem;
  border-radius: 7px;
  letter-spacing: auto;

  @media (max-width: 767.98px) {
    min-width: 150px;
  }
}

.close {
  position: absolute;
  background: transparent;
  top: 0.7rem;
  right: 0.7rem;

  img {
    filter: invert(91%) sepia(99%) saturate(27%) hue-rotate(196deg)
      brightness(108%) contrast(99%);
  }
}
</style>
