import { createApp } from 'vue'
import VueTheMask from 'vue-the-mask'
import VueMoney from 'v-money'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'
import { vfmPlugin } from 'vue-final-modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'vue3-carousel/dist/carousel.css';
import App from './App.vue'
import router from './router'
import { store } from './store'
import { declareFilters } from './helpers/filters'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { Chart, registerables } from 'chart.js'
import LoadScript from 'vue-plugin-load-script'
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App)

declareFilters(app)
Chart.register(...registerables);
app.use(VueTheMask)
app.use(vfmPlugin)
app.use(LoadScript)
app.use(VueTelInput,{defaultCountry:'BR', autoDefaultCountry:false, mode:'international', validCharactersOnly: true})
app.use(VueMoney, { precision: 2 })
app.component('MultiSelect', MultiSelect)
app.use(router)
app.use(store)
app.use(VueApexCharts);

app.mount('#app')
