import * as types from './types'

export default {
  [types.HEADER_TYPE]: state => {
    return state.headerType
  },
  [types.LEGAL_TYPES]: state => {
    return state.legalTypes
  },
  [types.MARITAL_STATUSES]: state => {
    return state.maritalStatuses
  },
  [types.ADDRESS_STATES]: state => {
    return state.addressStates
  },
  [types.COUNTRIES_LIST]: state => {
    return state.countriesList
  },
  [types.GENDERS]: state => {
    return state.genders
  },
  [types.PRIORITIES]: state => {
    return state.priorities
  },
  [types.INCOME_TAX_CATEGORY_TYPES]: state => {
    return state.incomeTaxCategoryTypes
  },
  [types.INVESTOR_PROFILE]: state => {
    return state.investorProfile
  }
}
