import _ from 'lodash';
import {
  onlyDigits,
  integerToString,
  moneyToFloat,
  percentageToFloat,
  stringToDate,
  stringToBoolean,
  stringToInteger
} from './converters'

export const getCustomerInfoPayload = (obj) => {
  let payload = {
    name: obj.name || '',
    gender: obj.gender || '',
    email: obj.email || '',
    person_type: obj.legalType || '',
    cpf: onlyDigits(obj.cpf),
    rg: obj.rg ? obj.rg.toString() : '',
    rg_expedition_date: stringToDate(obj.rgExpeditionDate),
    marital_status: obj.maritalStatus || '',
    cell_phone: onlyDigits(obj.cellPhone),
    phone: onlyDigits(obj.phone),
    birth_date: stringToDate(obj.birthDate),
    occupation: obj.occupation || '',
    country: obj.country || '',
    single_line_address: obj.singleLineAddress || '',
    address_cep: onlyDigits(obj.addressCep),
    address_state: obj.addressState || '',
    address_city: obj.addressCity || '',
    address_district: obj.addressDistrict || '',
    address_street: obj.addressStreet || '',
    address_number: obj.addressNumber || '',
    address_complement: obj.addressComplement || '',
    consultant_id: obj.consultantId,
    has_partner: obj.hasPartner,
    single_line_address: obj.singleLineAddress || '',
    children: _.map(
      obj.children,
      child => {
        return {
          name: child.name || '',
          gender: child.gender || '',
          age: integerToString(child.age),
          birth_date: stringToDate(child.birth_date)
        }
      }
    ),
    pets: _.map(
      obj.pets,
      pet => {
        return {
          name: pet.name || '',
          breed: pet.breed || '',
          age: integerToString(pet.age),
          birth_date: stringToDate(pet.birth_date)
        }
      }
    ),
    token: obj.token
  }
  if (obj.hasPartner) {
    payload = {
      ...payload,
      partner_name: obj.partnerName || '',
      partner_gender: obj.partnerGender || '',
      partner_email: obj.partnerEmail || '',
      partner_cpf: onlyDigits(obj.partnerCpf),
      partner_rg: obj.partnerRg ? obj.partnerRg.toString() : '',
      partner_cell_phone: onlyDigits(obj.partnerCellPhone),
      partner_phone: onlyDigits(obj.partnerPhone),
      partner_country: obj.partnerCountry || '',
      partner_single_line_address: obj.partnerSingleLineAddress || '',
      partner_birth_date: stringToDate(obj.partnerBirthDate),
      partner_occupation: obj.partnerOccupation || '',
      partner_address_cep: onlyDigits(obj.partnerAddressCep),
      partner_address_state: obj.partnerAddressState || '',
      partner_address_city: obj.partnerAddressCity || '',
      partner_address_district: obj.partnerAddressDistrict || '',
      partner_address_street: obj.partnerAddressStreet || '',
      partner_address_number: obj.partnerAddressNumber || '',
      partner_address_complement: obj.partnerAddressComplement || ''
    }
  }
  return payload
}

export const getSelectedGoalsPayload = ({ token, selectedGoals }) => {
  return {
    token,
    goals: _.map(
      selectedGoals,
      (goal) => {
        const {
          category,
          title,
          priority,
          why,
          when,
          howMuchToInvest,
          resume
        } = goal
        return {
          category: category || '',
          title: title || '',
          priority: priority || '',
          why: why || '',
          when: stringToDate(when),
          how_much_to_invest: moneyToFloat(howMuchToInvest),
          resume: resume || ''
        }
      }
    )
  }
}

export const getLifeInsurancePayload = (payload) => {
  const {
    token,
    haveLifeInsurance,
    becauseLifeInsurance,
    haveInsurance,
    insurances,
    award,
    roof,
    betterInsurance,
    capitalGuarantee,
    financialReserve
  } = payload
  return {
    token,
    have_life_insurance: stringToBoolean(haveLifeInsurance),
    because_life_insurance: becauseLifeInsurance || '',
    have_insurance: stringToBoolean(haveInsurance),
    insurances: insurances || '',
    award: moneyToFloat(award),
    roof: moneyToFloat(roof),
    better_insurance: stringToBoolean(betterInsurance),
    capital_guarantee: stringToBoolean(capitalGuarantee),
    financial_reserve: stringToBoolean(financialReserve)
  }
}

export const getAcquisitionsPayload = ({ token, vehicles, properties, assets }) => {
  return {
    token,
    vehicles: _.map(
      vehicles,
      (vehicle) => {
        const {
          brand,
          model,
          year,
          valueFipe,
          accumulatedValue,
          when
        } = vehicle
        return {
          brand,
          model,
          year: stringToInteger(year),
          value_fipe: moneyToFloat(valueFipe),
          accumulated_value: moneyToFloat(accumulatedValue),
          when: stringToDate(when)
        }
      }
    ),
    properties: _.map(
      properties,
      (property) => {
        const {
          local,
          price,
          type,
          nDorms,
          accumulatedValue,
          accumulatedValueFgts,
          when
        } = property
        return {
          local,
          price: moneyToFloat(price),
          type,
          n_dorms: nDorms,
          accumulated_value: moneyToFloat(accumulatedValue),
          accumulated_value_fgts: moneyToFloat(accumulatedValueFgts),
          when: stringToDate(when)
        }
      }
    ),
    assets: _.map(
      assets,
      (asset) => {
        const {
          name,
          description,
          price,
          accumulatedValue,
          when
        } = asset
        return {
          name,
          description,
          price: moneyToFloat(price),
          accumulated_value: moneyToFloat(accumulatedValue),
          when: stringToDate(when)
        }
      }
    )
  }
}

export const getPatrimonyPayload = ({ token, vehicles, properties, assets }) => {
  return {
    token,
    vehicles: _.map(
      vehicles,
      (vehicle) => {
        const {
          brand,
          model,
          year,
          valueFipe,
          haveInsurance,
          annualAward,
          dueDate,
          paidOff
        } = vehicle
        return {
          brand,
          model,
          year: stringToInteger(year),
          value_fipe: moneyToFloat(valueFipe),
          have_insurance: stringToBoolean(haveInsurance),
          annual_award: moneyToFloat(annualAward),
          due_date: stringToDate(dueDate),
          paid_off: stringToBoolean(paidOff)
        }
      }
    ),
    properties: _.map(
      properties,
      (property) => {
        const {
          local,
          price,
          type,
          nDorms,
          haveInsurance,
          annualAward,
          dueDate,
          paidOff
        } = property
        return {
          local,
          price: moneyToFloat(price),
          type,
          n_dorms: nDorms,
          have_insurance: stringToBoolean(haveInsurance),
          annual_award: moneyToFloat(annualAward),
          due_date: stringToDate(dueDate),
          paid_off: stringToBoolean(paidOff)
        }
      }
    ),
    assets: _.map(
      assets,
      (asset) => {
        const {
          name,
          description,
          price,
          paidOff
        } = asset
        return {
          name,
          description,
          price: moneyToFloat(price),
          paid_off: stringToBoolean(paidOff)
        }
      }
    )
  }
}

export const getHealthPlanPayload = (obj) => {
  const {
    token,
    hasPlan,
    isCompanyHealthPlan,
    company,
    product,
    initialDate,
    currentValue,
    reducedCost,
    fundamental,
    satisfaction,
    lifes,
    unionOrClass,
    preexistence,
    preference,
    hasCnpj,
    value
  } = obj
  return {
    token,
    has_plan: stringToBoolean(hasPlan),
    is_company_health_plan: stringToBoolean(isCompanyHealthPlan),
    company,
    product,
    initial_date: stringToDate(initialDate),
    current_value: moneyToFloat(currentValue),
    reduced_cost: stringToBoolean(reducedCost),
    fundamental,
    satisfaction,
    lifes: stringToInteger(lifes),
    union_or_class: stringToBoolean(unionOrClass),
    preexistence,
    preference,
    has_cnpj: stringToBoolean(hasCnpj),
    value: moneyToFloat(value)
  }
}

export const getRetirementPayload = (obj) => {
  const {
    token,
    retirementAge,
    desiredMonthlyAmount,
    wantSave,
    strategy
  } = obj
  return {
    token,
    retirement_age: stringToInteger(retirementAge),
    desired_monthly_amount: moneyToFloat(desiredMonthlyAmount),
    want_save: moneyToFloat(wantSave),
    strategy
  }
}

export const getMonthlyInvestmentPayload = (obj) => {
  const {
    token,
    valueMin,
    valueMax,
    investmentRateAmateur,
    investmentRateProfessional,
    predictedChange,
    financialMattersObserved
  } = obj
  return {
    token,
    value_min: moneyToFloat(valueMin),
    value_max: moneyToFloat(valueMax),
    investment_rate_amateur: percentageToFloat(investmentRateAmateur),
    investment_rate_professional: percentageToFloat(investmentRateProfessional),
    predicted_change: predictedChange,
    financial_matters_observed: stringToBoolean(financialMattersObserved)
  }
}

export const getDebtsPayload = ({ token, debts }) => {
  return {
    token,
    debts: _.map(
      debts,
      (debt) => {
        const {
          typeDebt,
          reasonDebt,
          startDate,
          value,
          installments,
          fees,
          amountPaid,
          paidInstallments,
          currentBalance
        } = debt
        return {
          type_debt: typeDebt,
          reason_debt: reasonDebt,
          start_date: stringToDate(startDate),
          value: moneyToFloat(value),
          installments: stringToInteger(installments),
          fees: percentageToFloat(fees),
          amount_paid: moneyToFloat(amountPaid),
          paid_installments: stringToInteger(paidInstallments),
          current_balance: moneyToFloat(currentBalance)
        }
      }
    )
  }
}

export const getProposalPayload = (obj) => {
  const {
    token,
    parcelsQuantity,
    servicesToContract,
    paymentMethod,
    supportFromAnotherConsultant,
    closerConsultantId,
    interest,
    totalAmount,
    installmentAmount,
    discount,
    recommendationDiscount,
    recommendations,
    subscriptionPlanId
  } = obj
  return {
    token,
    installment_quantity: stringToInteger(parcelsQuantity),
    services_to_contract: servicesToContract,
    payment_method: paymentMethod,
    closer_consultant_id: supportFromAnotherConsultant === 'yes' ? closerConsultantId:undefined,
    interest,
    total_amount: totalAmount ? totalAmount.toFixed(2) : 0,
    installment_amount: installmentAmount ? installmentAmount.toFixed(2) : 0,
    discount,
    recommendation_discount: recommendationDiscount,
    subscription_plan_id: subscriptionPlanId,
    recommendations: _.map(
      recommendations,
      (recommendation) => {
        const {
          id,
          name,
          cellPhone,
          email,
          occupation,
          goal,
          comment,
          type
        } = recommendation
        return {
          id,
          name,
          cell_phone: onlyDigits(cellPhone),
          email,
          occupation,
          goal,
          comment,
          type
        }
      }
    )
  }
}

export const getCashFlowPayload = (obj) => {
  const {
    token,
    doesAnnualPlanning,
    doesSpendingManagement,
    hasYearEndBonus,
    categories
  } = obj
  return {
    token,
    does_anual_planning: stringToBoolean(doesAnnualPlanning),
    does_spending_management: stringToBoolean(doesSpendingManagement),
    has_year_end_bonus: stringToBoolean(hasYearEndBonus),
    categories: _.map(
      categories,
      (cat) => {
        const {
          title,
          category,
          value,
          subCategories
        } = cat
        return {
          title,
          category,
          value: moneyToFloat(value),
          sub_categories: _.map(
            subCategories,
            subCategory => {
              const {
                categorySub,
                value
              } = subCategory
              return {
                title: subCategory.title,
                category: subCategory.category,
                category_sub: categorySub,
                value: moneyToFloat(value)
              }
            }
          )
        }
      }
    )
  }
}

export const getAdditionalInformationPayload = (obj) => {
  const {
    token,
    incomeTaxCategory,
    incomeTaxPaid,
    incomeTaxPaidAmount,
    incomeTaxRefundedAmount,
    cards,
    bankAccounts
  } = obj
  return {
    token,
    income_tax_category: incomeTaxCategory,
    income_tax_paid: stringToBoolean(incomeTaxPaid),
    income_tax_paid_amount: moneyToFloat(incomeTaxPaidAmount),
    income_tax_refunded_amount: moneyToFloat(incomeTaxRefundedAmount),
    cards: _.map(
      cards,
      (card) => {
        const {
          name,
          monthlyFee,
          annualFee,
          limit,
          averageExpense,
          points,
          cashback
        } = card
        return {
          name,
          monthly_fee: moneyToFloat(monthlyFee),
          annual_fee: moneyToFloat(annualFee),
          limit: moneyToFloat(limit),
          average_expense: moneyToFloat(averageExpense),
          points,
          cashback: percentageToFloat(cashback)
        }
      }
    ),
    bank_accounts: _.map(
      bankAccounts,
      (bankAccount) => {
        const {
          name,
          monthlyFee,
          annualFee
        } = bankAccount
        return {
          name,
          monthly_fee: moneyToFloat(monthlyFee),
          annual_fee: moneyToFloat(annualFee)
        }
      }
    )
  }
}

export const getInvestmentsPayload = ({ token, investments, investorProfile }) => {
  return {
    token,
    investor_profile: investorProfile,
    investments: _.map(
      investments,
      (investment) => {
        const {
          active,
          bankOrBrokerage,
          monthlyPremium,
          accumulatedBalance,
          profitability
        } = investment
        return {
          active: active,
          bank_or_brokerage: bankOrBrokerage,
          monthly_premium: moneyToFloat(monthlyPremium),
          accumulated_balance: moneyToFloat(accumulatedBalance),
          profitability: percentageToFloat(profitability)
        }
      }
    )
  }
}

export const processFormErrors = async (vuelidateInstance) => {
  const forms = [
    { interviewSection: 'customerInfo', sectionName: 'Dados pessoais' },
    { interviewSection: 'goals', sectionName: 'Objetivos' },
    { interviewSection: 'monthlyInvestment', sectionName: 'Investimento mensal' },
    { interviewSection: 'acquisition', sectionName: 'Aquisição de bens' },
    { interviewSection: 'patrimony', sectionName: 'Patrimônio' },
    { interviewSection: 'lifeInsurance', sectionName: 'Seguro de vida' },
    { interviewSection: 'healthInsurance', sectionName: 'Plano de saúde' },
    { interviewSection: 'debts', sectionName: 'Dívidas' },
    { interviewSection: 'retirement', sectionName: 'Aposentadoria' },
    { interviewSection: 'investment', sectionName: 'Investimentos' },
    { interviewSection: 'cashFlow', sectionName: 'Fluxo de caixa' },
    { interviewSection: 'proposal', sectionName: 'Transforme futuros e ganhe' },
    { interviewSection: 'additionalInformation', sectionName: 'Informações adicionais' }
  ]
  const errors = []
  for (const form of forms) {
    const valid = await isFormValid(vuelidateInstance, form.interviewSection)
    if (!valid) {
      errors.push(form.sectionName)
    }
  }
  return errors
}

const isFormValid = async (vuelidateInstance, interviewSection) => {
  const form = vuelidateInstance.$getResultsForChild(interviewSection)
  return form ? form.$validate() : true
}
