import axios from "axios";
import router from "@/router";
import { store } from "@/store";
import * as types from "@/modules/account/store/types";
import { CREDENTIALS_STORAGE_KEY } from "@/constants/auth";
import { getCredentials } from "@/helpers/credentials";

let apiInstance = undefined;

export const axiosNoAuth = () => {
  if (apiInstance === undefined) {
    apiInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
    });
  }
  return apiInstance;
};

export const initApi = () => {
  const token = getCredentials(CREDENTIALS_STORAGE_KEY) || undefined;
  if (token) {
    store.commit(types.MUTATE_TOKEN, token);
  }

  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  axios.defaults.withCredentials = false;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (
        401 === error?.response?.status ||
        403 === error?.response?.status ||
        419 === error?.response?.status
      ) {
        store.dispatch(types.LOGOUT);
        router.push({ name: "unauthorized" });
      }
      return Promise.reject(error);
    }
  );
};
