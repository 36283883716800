<template>
  <form id="futuro-customer-mounthly" class="my-5" @submit.prevent>
    <div class="card card-monthly-investment">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/calendar.svg"
            alt="Futuro | Consultoria Financeira"
            style="width: 20px"
          />
          <h2 class="col-12">Investimento mensal</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row">
        <p>
          Para alcançar metas, é necessário poupar com sucesso. Hoje, focado nos
          seus 3 principais objetivos,
          <b>quanto você consegue poupar com tranquilidade?</b>
        </p>
      </div>
      <div class="row mb-3 gy-3">
        <div class="col-md-4 col-12">
          <label for="valueMin" class="form-label"> Mínimo </label>
          <input
            id="valueMin"
            v-model.lazy="valueMin"
            v-money="moneyFormat"
            type="text"
            :disabled="isDetail"
            class="form-control"
            :class="{ 'is-invalid': v$.valueMin.$error }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.valueMin.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <label for="valueMax" class="form-label"> Máximo </label>
          <input
            id="valueMax"
            v-model.lazy="valueMax"
            v-money="moneyFormat"
            type="text"
            :disabled="isDetail"
            class="form-control"
            :class="{ 'is-invalid': v$.valueMax.$error }"
            @blur="preSave()"
          />
          <div class="invalid-feedback">
            <span v-if="v$.valueMax.minLength.$invalid">
              O valor é inválido
            </span>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <label for="valueAvg" class="form-label"> Média </label>
          <input
            id="valueAvg"
            v-money="moneyFormat"
            :value="valueAvg"
            type="text"
            class="form-control"
            disabled
          />
        </div>
      </div>
      <template v-if="isInterview">
        <div class="row mb-4">
          <div class="col-md-4 col-12">
            <label for="investmentRateAmateur" class="form-label">
              TR anual amador
            </label>
            <input
              id="investmentRateAmateur"
              v-model.lazy="investmentRateAmateur"
              v-money="percentageFormat"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.investmentRateAmateur.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.investmentRateAmateur.minLength.$invalid">
                O valor é inválido
              </span>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <label for="investmentRateProfessional" class="form-label">
              TR anual profissional
            </label>
            <input
              id="investmentRateProfessional"
              v-model.lazy="investmentRateProfessional"
              v-money="percentageFormat"
              type="text"
              class="form-control"
              :disabled="isDetail"
              :class="{ 'is-invalid': v$.investmentRateProfessional.$error }"
              @blur="preSave()"
            />
            <div class="invalid-feedback">
              <span v-if="v$.investmentRateProfessional.minLength.$invalid">
                O valor é inválido
              </span>
            </div>
          </div>
        </div>
        <template
          v-if="calculationValuesAreValid && calculation.s5 && calculation.p5"
        >
          <div class="row mb-3 mx-1">
            <div class="col-12">
              <div class="row investments header">
                <span class="offset-1 col-2">#</span>
                <span class="col-2">5 anos</span>
                <span class="col-2">10 anos</span>
                <span class="col-2">20 anos</span>
                <span class="col-2">30 anos</span>
              </div>
              <div class="row investments amateur">
                <span class="offset-1 col-2">Sozinho</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.s5)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.s10)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.s20)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.s30)
                }}</span>
              </div>
              <div class="row investments professional">
                <span class="offset-1 col-2">Profissional</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.p5)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.p10)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.p20)
                }}</span>
                <span class="col-2">{{
                  $filters.toCurrency(calculation.p30)
                }}</span>
              </div>
            </div>
          </div>

          <div class="row mb-3 gy-3">
            <div class="col-12">
              <differences-chart
                v-if="
                  calculation.chart_label &&
                  calculation.amateur_series &&
                  calculation.professional_series
                "
                :labels="calculation.chart_label"
                :series-first="calculation.amateur_series"
                :series-second="calculation.professional_series"
              />
            </div>
          </div>
        </template>
        <div class="row">
          <p>
            Trazendo um conceito focado nos objetivos citados e que se enquadre
            no seu perfil financeiro, você consegue começar desde já?
          </p>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-12">
            <label for="predictedChange" class="form-label">
              Alguma mudança prevista na sua vida financeira nos próximos meses?
            </label>
            <textarea
              id="predictedChange"
              v-model="predictedChange"
              type="text"
              class="form-control"
              rows="4"
              :disabled="isDetail"
              @blur="preSave()"
            />
          </div>
        </div>
        <div class="row mb-3 gy-3">
          <div class="col-12">
            <label for="financialMattersObserved" class="form-label">
              Você vem acompanhando os assuntos financeiros nos últimos meses?
            </label>
            <select
              id="financialMattersObserved"
              v-model="financialMattersObserved"
              :disabled="isDetail"
              class="form-select"
              :class="{ 'select-disabled': isDetail }"
              @blur="preSave()"
            >
              <option value="yes">Sim</option>
              <option value="no">Não</option>
            </select>
          </div>
        </div>
        <comments-section
          v-if="interviewSection && token"
          :interview-section="interviewSection"
          :token="token"
        />
      </template>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minLength } from "@vuelidate/validators";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { VMoney } from "v-money";
import { getBRLFormat, getPercentageFormat } from "../../../helpers/formatting";
import {
  floatToMoney,
  floatToPercentage,
  percentageToFloat,
  booleanToString,
  moneyToFloat,
} from "../../../helpers/converters";
import { getMonthlyInvestmentPayload } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import DifferencesChart from "./DifferencesChart";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";
export default {
  components: {
    Spinner,
    DifferencesChart,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      valueMin: "",
      valueMax: "",
      investmentRateAmateur: "",
      investmentRateProfessional: "",
      predictedChange: "",
      financialMattersObserved: "",
      token: "",
      loading: false,
    };
  },
  validations() {
    return {
      valueMin: {
        minLength: minLength(7),
      },
      valueMax: {
        minLength: minLength(7),
      },
      investmentRateAmateur: {
        minLength: minLength(7),
      },
      investmentRateProfessional: {
        minLength: minLength(7),
      },
      predictedChange: {},
      financialMattersObserved: {},
    };
  },
  computed: {
    ...mapGetters({
      calculation: types.MONTHLY_INVESTMENT_CALCULATION,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
    valueAvg: function () {
      const max = moneyToFloat(this.valueMax);
      const min = moneyToFloat(this.valueMin);
      if (max - min >= 0) {
        return floatToMoney((max + min) / 2);
      }
      return "0";
    },
    calculationValuesAreValid: function () {
      const max = moneyToFloat(this.valueMax);
      const min = moneyToFloat(this.valueMin);
      if ((max > 0 || min > 0) && max - min >= 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getMonthlyInvestment: types.GET_MONTHLY_INVESTMENT,
      autoSaveMonthlyInvestment: types.AUTO_SAVE_MONTHLY_INVESTMENT,
      getMonthlyInvestmentTaxes: types.GET_MONTHLY_INVESTMENT_TAXES,
      calculateMonthlyInvestment: types.CALCULATE_MONTHLY_INVESTMENT,
    }),
    ...mapMutations({
      mutateMonthlyInvestment: types.MUTATE_MONTHLY_INVESTMENT,
    }),
    async fetchMonthlyInvestment() {
      this.loading = true;
      try {
        const response = await this.getMonthlyInvestment(
          this.$route.params.customerId
        );
        const {
          value_min,
          value_max,
          investment_rate_amateur,
          investment_rate_professional,
          predicted_change,
          financial_matters_observed,
        } = response.data;
        this.valueMin = floatToMoney(value_min);
        this.valueMax = floatToMoney(value_max);
        this.investmentRateAmateur = floatToPercentage(investment_rate_amateur);
        this.investmentRateProfessional = floatToPercentage(
          investment_rate_professional
        );
        this.predictedChange = predicted_change;
        this.financialMattersObserved = booleanToString(
          financial_matters_observed
        );
        const payload = getMonthlyInvestmentPayload(this);
        this.mutateMonthlyInvestment(payload);
        if (
          (!investment_rate_amateur || investment_rate_amateur === 0) &&
          (!investment_rate_professional || investment_rate_professional === 0)
        ) {
          this.getTaxes();
        }
        this.calculate();
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter o investimento mensal do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchMonthlyInvestment()]);
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    preSave() {
      const payload = getMonthlyInvestmentPayload(this);
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateMonthlyInvestment(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveMonthlyInvestment(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente o investimento mensal do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
      this.calculate();
    },
    getTaxes() {
      this.getMonthlyInvestmentTaxes()
        .then((response) => {
          const {
            default_investment_rate_amateur,
            default_investment_rate_professional,
          } = response.data;
          this.investmentRateAmateur = floatToPercentage(
            default_investment_rate_amateur
          );
          this.investmentRateProfessional = floatToPercentage(
            default_investment_rate_professional
          );
        })
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível obter as taxas de investimento mensal.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        });
    },
    calculate() {
      this.calculateMonthlyInvestment({
        value_max: moneyToFloat(this.valueMax),
        value_min: moneyToFloat(this.valueMin),
        investment_rate_amateur: percentageToFloat(this.investmentRateAmateur),
        investment_rate_professional: percentageToFloat(
          this.investmentRateProfessional
        ),
      }).catch((error) => {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível calcular o investimento mensal.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  padding: 12px;
  margin-bottom: 0;
}
.investments {
  padding: 12px;
  &.header {
    border-bottom: 1px solid #4c6ed5;
    margin-bottom: 10px;
  }
  &.amateur {
    background-image: var(--dark-blue-gradient);
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  &.professional {
    background-image: var(--light-blue-gradient);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
/*Ajuste no grafico de investimentos*/
@media (max-width: 768px) {
  .row.investments.header {
    display: none;
  }
  .row.investments {
    display: grid;
    grid-template-columns: 100%;
  }
  .row.investments > span {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .row.investments > span:not(.offset-1):nth-of-type(2):before {
    content: "5 anos ";
    color: #302d93;
  }
  .row.investments > span:not(.offset-1):nth-of-type(3):before {
    content: "10 anos ";
    color: #302d93;
  }
  .row.investments > span:not(.offset-1):nth-of-type(4):before {
    content: "20 anos ";
    color: #302d93;
  }
  .row.investments > span:not(.offset-1):nth-of-type(5):before {
    content: "30 anos ";
    color: #302d93;
  }
  .row.investments > span:not(.offset-1):before {
    width: 80px;
    display: inline-block;
    font-weight: 600;
  }
  .row.investments > span.offset-1.col-2 {
    font-weight: 600;
    display: flex;
    gap: 5px;
  }
  .row.investments > span.offset-1.col-2:before {
    content: "# ";
  }

  /*Correção dos campos de texto minisculos no mobile (fluxo de caixa)*/
  .row.align-items-center.mb-3 .col-md-4.col-6 {
    min-width: 100% !important;
  }
  /*Correção de parte do scroll horizontal no mobile*/
  .h-100.inner-section > .ribbon {
    max-width: 100% !important;
    padding: 0;
  }
}
</style>
