<template>
  <form
    v-if="isInterview"
    id="futuro-customer-value"
    class="my-5"
    @submit.prevent
  >
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-smile.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Proposta</h2>
        </div>
      </div>
      <div class="row mt-3 mb-3 px-3">
        <div class="col-12 blue-card totalizer">
          <span>Renda bruta anual:</span>
          <span>{{ $filters.toCurrency(calculation.annual_gross) }}</span>
        </div>
      </div>
      <div class="row justify-content-center mt-3 mb-3">
        <div class="col-auto blue-card monthly-value">
          <h1>Mensal</h1>
          <p>
            <b>{{ $filters.toCurrency(calculation.monthly_value) }}</b>
            mensais
          </p>
        </div>
      </div>
      <div class="row justify-content-center mt-3 mb-3">
        <p class="col-auto">
          À partir do segundo ano:
          <b>{{ $filters.toCurrency(calculation.subscription_amount) }}</b> +
          projeto (dependendo da sua necessidade)
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "../store/types";
export default {
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isInterview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      calculation: types.PROPOSAL_CALCULATION,
    }),
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 16px;
  margin: 0;
  b {
    font-weight: 600;
  }
}
.blue-card {
  &.totalizer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
  }
  &.monthly-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 300px;
    padding: 35px 55px;
    h1 {
      margin-bottom: 20px;
    }
  }
}
</style>
